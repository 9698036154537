import React from 'react';
import { motion } from 'framer-motion';

const Privacy = () => {
  return (
    <div className="min-h-screen bg-background py-16 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-4xl mx-auto"
      >
        <h1 className="text-4xl font-bold text-heading-text mb-8">Privacy Policy</h1>
        
        <div className="space-y-8 text-text">
          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">1. Introduction</h2>
            <p>This Privacy Policy explains how HPKV ("we", "our", or "us"), based in the European Union, collects, uses, and protects your information when you use our high-performance key-value store service. This policy is compliant with the General Data Protection Regulation (GDPR) and other applicable EU data protection laws.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">2. Information We Collect</h2>
            <h3 className="text-xl font-semibold text-heading-text mt-4 mb-2">2.1 Account Information (Legal basis: Contract Performance)</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>Name</li>
              <li>Email address</li>
              <li>Password (encrypted)</li>
              <li>API keys</li>
            </ul>

            <h3 className="text-xl font-semibold text-heading-text mt-4 mb-2">2.2 Usage Data (Legal basis: Legitimate Interest)</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>API usage statistics</li>
              <li>Storage utilization</li>
              <li>Performance metrics</li>
              <li>Error logs</li>
            </ul>

            <h3 className="text-xl font-semibold text-heading-text mt-4 mb-2">2.3 Technical Data (Legal basis: Legitimate Interest)</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>IP addresses</li>
              <li>Browser type and version</li>
              <li>Operating system information</li>
              <li>Access timestamps</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">3. Data Controller</h2>
            <p>HPKV acts as the data controller for the personal information we collect. For any data protection concerns or requests, you can contact our Data Protection Officer at mehran@hpkv.io.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">4. Your GDPR Rights</h2>
            <p>Under GDPR, you have the following rights:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Right to access your personal data</li>
              <li>Right to rectification of inaccurate data</li>
              <li>Right to erasure ("right to be forgotten")</li>
              <li>Right to restrict processing</li>
              <li>Right to data portability</li>
              <li>Right to object to processing</li>
              <li>Right to withdraw consent</li>
              <li>Right to lodge a complaint with a supervisory authority</li>
            </ul>
            <p className="mt-4">To exercise any of these rights, please contact us at support@hpkv.io. We will respond to your request within 30 days.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">5. Data Storage Location and International Transfers</h2>
            <p>Your data is processed and stored according to your service configuration:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>For standard service plans, your data is stored and processed in the region you select when creating your API key (EU or US)</li>
              <li>For Business plan users with global service enabled, your data may be stored and processed across multiple regions to provide optimal performance</li>
              <li>Account management data (such as your account information and billing details) is processed in the EU</li>
            </ul>
            <p className="mt-4">If you are using our Business plan with global service, your data may be transferred between different regions. In such cases, we ensure appropriate safeguards are in place through Standard Contractual Clauses or adequacy decisions as required by GDPR.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">6. Data Storage and Security</h2>
            <p>We implement robust security measures to protect your data:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>All data is stored using enterprise-grade security measures in secure facilities</li>
              <li>API keys and authentication credentials are encrypted</li>
              <li>Access to data is strictly controlled through advanced isolation techniques</li>
              <li>Regular security audits and updates</li>
              <li>Data is processed in compliance with regional data protection regulations</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">7. Data Retention</h2>
            <p>We retain your data according to the following principles:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Account information is retained while your account is active</li>
              <li>Usage data is retained for monitoring and improvement purposes for up to 12 months</li>
              <li>Data is automatically deleted according to your service plan settings</li>
              <li>You can request data deletion at any time</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">8. Data Sharing</h2>
            <p>We do not share your personal information or stored data with third parties except:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>When required by law or valid legal process</li>
              <li>With your explicit consent</li>
              <li>With EU-based service providers who assist in operating our service, bound by data processing agreements</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">9. Changes to Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by email and/or a prominent notice on our website before the changes become effective.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">10. Contact Information</h2>
            <p>For any questions about this Privacy Policy or our data practices, please contact:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Data Protection Officer: mehran@hpkv.io</li>
              <li>General inquiries: support@hpkv.io</li>
            </ul>
            <p className="mt-4">You have the right to lodge a complaint with your local data protection authority.</p>
          </section>
        </div>
      </motion.div>
    </div>
  );
};

export default Privacy; 