import React from 'react';
import { BeakerIcon, LightBulbIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import CodeTabs from './CodeTabs';

const NexusSearch = () => {
  const searchSnippets = {
    JavaScript: {
      language: 'javascript',
      code: `// Semantic search for relevant records
const response = await fetch(
  "https://nexus.hpkv.io/search",
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'YOUR_API_KEY'
    },
    body: JSON.stringify({
      query: "What are the benefits of high performance databases?",
      topK: 5,  // Optional: number of results to return (default: 5)
      minScore: 0.5  // Optional: minimum similarity score (default: 0.5)
    })
  }
);

const data = await response.json();
console.log(data.results);  // Array of {key, score} objects`
    },
    Python: {
      language: 'python',
      code: `import requests

# Semantic search for relevant records
response = requests.post(
    "https://nexus.hpkv.io/search",
    headers={
        "Content-Type": "application/json",
        "X-Api-Key": "YOUR_API_KEY"
    },
    json={
        "query": "What are the benefits of high performance databases?",
        "topK": 5,  # Optional: number of results to return (default: 5)
        "minScore": 0.5  # Optional: minimum similarity score (default: 0.5)
    }
)

data = response.json()
print(data["results"])  # Array of {key, score} objects`
    },
    '.NET': {
      language: 'csharp',
      code: `using System.Text;
using System.Text.Json;
using System.Net.Http.Json;

// Semantic search for relevant records
var searchRequest = new
{
    query = "What are the benefits of high performance databases?",
    topK = 5,  // Optional: number of results to return (default: 5)
    minScore = 0.5  // Optional: minimum similarity score (default: 0.5)
};

var client = new HttpClient();
client.DefaultRequestHeaders.Add("X-Api-Key", "YOUR_API_KEY");

var response = await client.PostAsJsonAsync(
    "https://nexus.hpkv.io/search",
    searchRequest
);

var data = await response.Content.ReadFromJsonAsync<SearchResult>();
foreach (var result in data.results)
{
    Console.WriteLine($"Key: {result.key}, Score: {result.score}");
}

// Class to deserialize the response
public class SearchResult
{
    public List<KeyScorePair> results { get; set; }
}

public class KeyScorePair
{
    public string key { get; set; }
    public double score { get; set; }
}`
    },
    cURL: {
      language: 'bash',
      code: `curl -X POST "https://nexus.hpkv.io/search" \\
  -H "Content-Type: application/json" \\
  -H "X-Api-Key: YOUR_API_KEY" \\
  -d '{
    "query": "What are the benefits of high performance databases?",
    "topK": 5,
    "minScore": 0.5
  }'`
    }
  };

  const querySnippets = {
    JavaScript: {
      language: 'javascript',
      code: `// Get AI-generated answers from your data
const response = await fetch(
  "https://nexus.hpkv.io/query",
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'YOUR_API_KEY'
    },
    body: JSON.stringify({
      query: "What are the benefits of high performance databases?",
      topK: 5,  // Optional: number of relevant records to use (default: 5)
      minScore: 0.5  // Optional: minimum similarity score (default: 0.5)
    })
  }
);

const data = await response.json();
console.log(data.answer);  // AI-generated answer
console.log(data.sources);  // Array of source keys used for the answer`
    },
    Python: {
      language: 'python',
      code: `import requests

# Get AI-generated answers from your data
response = requests.post(
    "https://nexus.hpkv.io/query",
    headers={
        "Content-Type": "application/json",
        "X-Api-Key": "YOUR_API_KEY"
    },
    json={
        "query": "What are the benefits of high performance databases?",
        "topK": 5,  # Optional: number of relevant records to use (default: 5)
        "minScore": 0.5  # Optional: minimum similarity score (default: 0.5)
    }
)

data = response.json()
print(data["answer"])  # AI-generated answer
print(data["sources"])  # Array of source keys used for the answer`
    },
    '.NET': {
      language: 'csharp',
      code: `using System.Text;
using System.Text.Json;
using System.Net.Http.Json;

// Get AI-generated answers from your data
var queryRequest = new
{
    query = "What are the benefits of high performance databases?",
    topK = 5,  // Optional: number of relevant records to use (default: 5)
    minScore = 0.5  // Optional: minimum similarity score (default: 0.5)
};

var client = new HttpClient();
client.DefaultRequestHeaders.Add("X-Api-Key", "YOUR_API_KEY");

var response = await client.PostAsJsonAsync(
    "https://nexus.hpkv.io/query",
    queryRequest
);

var data = await response.Content.ReadFromJsonAsync<QueryResult>();
Console.WriteLine($"Answer: {data.answer}");
Console.WriteLine("Sources:");
foreach (var source in data.sources)
{
    Console.WriteLine($"  Key: {source.key}, Score: {source.score}");
}

// Classes to deserialize the response
public class QueryResult
{
    public string answer { get; set; }
    public List<KeyScorePair> sources { get; set; }
}

public class KeyScorePair
{
    public string key { get; set; }
    public double score { get; set; }
}`
    },
    cURL: {
      language: 'bash',
      code: `curl -X POST "https://nexus.hpkv.io/query" \\
  -H "Content-Type: application/json" \\
  -H "X-Api-Key: YOUR_API_KEY" \\
  -d '{
    "query": "What are the benefits of high performance databases?",
    "topK": 5,
    "minScore": 0.5
  }'`
    }
  };

  const fullExampleSnippets = {
    JavaScript: {
      language: 'javascript',
      code: `// Store data in HPKV first
await fetch(\`\${baseUrl}/record\`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': 'YOUR_API_KEY'
  },
  body: JSON.stringify({
    key: 'article:databases',
    value: 'High performance databases offer exceptional speed and reliability. They typically achieve sub-millisecond response times and can handle millions of operations per second. This makes them ideal for real-time applications, financial systems, gaming backends, and other use cases where latency matters.'
  })
});

// Wait for a moment to allow indexing (in production, data is indexed asynchronously)
await new Promise(resolve => setTimeout(resolve, 1000));

// Query the data using Nexus Search
const response = await fetch("https://nexus.hpkv.io/query", {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': 'YOUR_API_KEY'
  },
  body: JSON.stringify({
    query: "What applications benefit from high performance databases?"
  })
});

const data = await response.json();
console.log(data.answer);
// Output might be: "High performance databases are ideal for real-time applications, financial systems, gaming backends, and other use cases where low latency is critical."`
    },
    Python: {
      language: 'python',
      code: `import requests
import time

# Store data in HPKV first
requests.post(
    f"{base_url}/record",
    headers={
        "Content-Type": "application/json",
        "X-Api-Key": "YOUR_API_KEY"
    },
    json={
        "key": "article:databases",
        "value": "High performance databases offer exceptional speed and reliability. They typically achieve sub-millisecond response times and can handle millions of operations per second. This makes them ideal for real-time applications, financial systems, gaming backends, and other use cases where latency matters."
    }
)

# Wait for a moment to allow indexing (in production, data is indexed asynchronously)
time.sleep(1)

# Query the data using Nexus Search
response = requests.post(
    "https://nexus.hpkv.io/query",
    headers={
        "Content-Type": "application/json",
        "X-Api-Key": "YOUR_API_KEY"
    },
    json={
        "query": "What applications benefit from high performance databases?"
    }
)

data = response.json()
print(data["answer"])
# Output might be: "High performance databases are ideal for real-time applications, financial systems, gaming backends, and other use cases where low latency is critical."`
    },
    '.NET': {
      language: 'csharp',
      code: `using System.Text;
using System.Text.Json;
using System.Net.Http.Json;

// Store data in HPKV first
var recordData = new
{
    key = "article:databases",
    value = "High performance databases offer exceptional speed and reliability. They typically achieve sub-millisecond response times and can handle millions of operations per second. This makes them ideal for real-time applications, financial systems, gaming backends, and other use cases where latency matters."
};

var client = new HttpClient();
client.DefaultRequestHeaders.Add("X-Api-Key", "YOUR_API_KEY");

// Insert the data into HPKV
await client.PostAsJsonAsync(
    $"{baseUrl}/record",
    recordData
);

// Wait for a moment to allow indexing (in production, data is indexed asynchronously)
await Task.Delay(1000);

// Query the data using Nexus Search
var queryRequest = new
{
    query = "What applications benefit from high performance databases?"
};

var response = await client.PostAsJsonAsync(
    "https://nexus.hpkv.io/query",
    queryRequest
);

var data = await response.Content.ReadFromJsonAsync<QueryResult>();
Console.WriteLine($"Answer: {data.answer}");
// Output might be: "High performance databases are ideal for real-time applications, financial systems, gaming backends, and other use cases where low latency is critical."

// Class to deserialize the response
public class QueryResult
{
    public string answer { get; set; }
    public List<KeyScorePair> sources { get; set; }
}

public class KeyScorePair
{
    public string key { get; set; }
    public double score { get; set; }
}`
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="sr-only">HPKV Nexus Search</h1>
      <h2 className="text-2xl font-bold mb-4">Nexus Search: AI-Powered Search for HPKV</h2>
      
      <p className="mb-4">
        Nexus Search adds Retrieval Augmented Generation (RAG) capabilities to HPKV, enabling you to perform semantic search and
        AI-powered question answering over your key-value data. Unlike traditional key-based access, Nexus Search understands the 
        meaning of your content, allowing natural language queries and intelligent information retrieval.
      </p>
      
      <div className="mt-6 border border-blue-400/20 rounded-lg bg-blue-400/5">
        <div className="px-4 py-3 border-b border-blue-400/20 flex items-center gap-2">
          <ShieldCheckIcon className="h-5 w-5 text-blue-400" />
          <h4 className="font-bold text-blue-400">Privacy</h4>
        </div>
        <div className="p-4">
          <p className="text-blue-200/90">
            All Nexus Search processing is private and secure. We do not maintain logs of your queries or searches, and your information is never used to train AI models or shared with third parties.
          </p>
        </div>
      </div>

      <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
        <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-indigo-400" />
          <h4 className="font-bold text-indigo-400">Usage Limits</h4>
        </div>
        <div className="p-4">
          <p className="text-indigo-200/90">
            Nexus Search is available on all subscription tiers with varying limits for context size, output tokens, and request rate. 
            Please visit our <a href="/pricing" className="text-indigo-400 hover:text-indigo-300 underline">pricing page</a> for detailed information 
            about the limits for each tier.
          </p>
          <ul className="list-disc pl-6 mt-2 text-indigo-200/90">
            <li><strong>Free tier:</strong> 100 calls/month, 12 req/min, no agent mode</li>
            <li><strong>Pro tier:</strong> 500 calls/month, 24 req/min, no agent mode</li>
            <li><strong>Business tier:</strong> 5000 calls/month, 60 req/min, with agent mode</li>
            <li><strong>Enterprise tier:</strong> Unlimited calls, 120 req/min, with agent mode</li>
          </ul>
        </div>
      </div>
      
      <h3 id="how-it-works" className="text-xl font-bold mt-6 mb-2">How It Works</h3>
      
      <p className="mb-4">
        When you write data to HPKV, Nexus Search automatically processes your content:
      </p>

      <ol className="list-disc pl-6">
        <li>Your key-value data is stored in HPKV as usual</li>
        <li>The text content is converted into vector embeddings (numerical representations that capture meaning)</li>
        <li>These embeddings are stored in a specialized vector database</li>
        <li>When you search or query, your input is converted to the same vector format</li>
        <li>The system finds the most similar vectors to your query</li>
        <li>For queries, an AI model generates a natural language response based on the retrieved content</li>
      </ol>

      <p className="mt-4">
        This process happens automatically in the background whenever you add or update data through the standard HPKV API.
      </p>

      <h3 id="search-endpoint" className="text-xl font-bold mt-6 mb-2">Search Endpoint</h3>
      
      <p className="mb-4">
        The <code className="bg-component-bg text-text p-1 rounded">/search</code> endpoint returns semantically similar records to your query. Instead of exact key matching, it finds records with content related to your search terms.
      </p>

      <h4 id="search-request" className="text-lg font-bold mb-2">Request Format</h4>

      <div className="mt-2 bg-gray-800 rounded-lg overflow-hidden">
        <pre className="p-4 text-sm text-gray-300 overflow-x-auto">
{`POST /search
Content-Type: application/json
X-Api-Key: YOUR_API_KEY

{
  "query": "Your natural language search query",
  "topK": 5,         // Optional: number of results to return (default: 5)
  "minScore": 0.5    // Optional: minimum similarity score threshold (default: 0.5)
}`}
        </pre>
      </div>

      <div className="mt-4 mb-4">
        <p className="mb-2"><strong>Parameters:</strong></p>
        <ul className="list-disc pl-6">
          <li><code className="bg-component-bg text-text p-1 rounded">query</code> (required): The natural language text to search for. This can be a phrase, question, or keywords related to the content you're looking for.</li>
          <li><code className="bg-component-bg text-text p-1 rounded">topK</code> (optional): Number of results to return, ranging from 1 to 20. Default is 5.</li>
          <li><code className="bg-component-bg text-text p-1 rounded">minScore</code> (optional): Minimum similarity score threshold between 0 and 1. Results with scores below this value will be filtered out. Default is 0.5.</li>
        </ul>
      </div>

      <h4 id="search-response" className="text-lg font-bold mt-4 mb-2">Response Format</h4>

      <div className="mt-2 bg-gray-800 rounded-lg overflow-hidden">
        <pre className="p-4 text-sm text-gray-300 overflow-x-auto">
{`{
  "results": [
    {
      "key": "article:123",
      "score": 0.87
    },
    {
      "key": "product:456",
      "score": 0.76
    },
    ...
  ]
}`}
        </pre>
      </div>

      <div className="mt-4 mb-4">
        <p className="mb-2"><strong>Response Fields:</strong></p>
        <ul className="list-disc pl-6">
          <li><code className="bg-component-bg text-text p-1 rounded">results</code>: An array of objects containing the keys of semantically similar records and their similarity scores.</li>
          <li><code className="bg-component-bg text-text p-1 rounded">key</code>: The key of a matching record in your HPKV store.</li>
          <li><code className="bg-component-bg text-text p-1 rounded">score</code>: A similarity score between 0 and 1, where higher values indicate greater relevance to the query.</li>
        </ul>
      </div>

      <p className="mt-4 mb-4">
        The <code className="bg-component-bg text-text p-1 rounded">score</code> is a similarity value between 0 and 1, where higher scores indicate greater relevance to your query.
      </p>

      <h4 id="search-example" className="text-lg font-bold mb-2">Example</h4>

      <div className="mt-4">
        <CodeTabs snippets={searchSnippets} />
      </div>

      <h3 id="query-endpoint" className="text-xl font-bold mt-6 mb-2">Query Endpoint</h3>
      
      <p className="mb-4">
        The <code className="bg-component-bg text-text p-1 rounded">/query</code> endpoint provides AI-generated answers to questions based on your data. It combines semantic search with large language model capabilities to produce natural language responses grounded in your content.
      </p>

      <h4 id="query-request" className="text-lg font-bold mb-2">Request Format</h4>

      <div className="mt-2 bg-gray-800 rounded-lg overflow-hidden">
        <pre className="p-4 text-sm text-gray-300 overflow-x-auto">
{`POST /query
Content-Type: application/json
X-Api-Key: YOUR_API_KEY

{
  "query": "Your natural language question",
  "topK": 5,         // Optional: number of relevant records to use (default: 5)
  "minScore": 0.5    // Optional: minimum similarity score threshold (default: 0.5)
}`}
        </pre>
      </div>

      <div className="mt-4 mb-4">
        <p className="mb-2"><strong>Parameters:</strong></p>
        <ul className="list-disc pl-6">
          <li><code className="bg-component-bg text-text p-1 rounded">query</code> (required): The natural language question you want to ask about your data. For best results, phrase this as a complete question.</li>
          <li><code className="bg-component-bg text-text p-1 rounded">topK</code> (optional): Maximum number of relevant records to use for generating the answer, ranging from 1 to 20. Default is 5.</li>
          <li><code className="bg-component-bg text-text p-1 rounded">minScore</code> (optional): Minimum similarity score threshold between 0 and 1. Records with scores below this value won't be used for answer generation. Default is 0.5.</li>
        </ul>
      </div>

      <h4 id="query-response" className="text-lg font-bold mt-4 mb-2">Response Format</h4>

      <div className="mt-2 bg-gray-800 rounded-lg overflow-hidden">
        <pre className="p-4 text-sm text-gray-300 overflow-x-auto">
{`{
  "answer": "The AI-generated answer to your question based on your data",
  "sources": [
    {
      "key": "article:123",
      "score": 0.87
    },
    {
      "key": "product:456",
      "score": 0.76
    },
    ...
  ]
}`}
        </pre>
      </div>

      <div className="mt-4 mb-4">
        <p className="mb-2"><strong>Response Fields:</strong></p>
        <ul className="list-disc pl-6">
          <li><code className="bg-component-bg text-text p-1 rounded">answer</code>: A natural language response to your question, generated by an AI model based on the content of the most relevant records.</li>
          <li><code className="bg-component-bg text-text p-1 rounded">sources</code>: An array of objects containing the keys of records used to generate the answer and their relevance scores.</li>
          <li><code className="bg-component-bg text-text p-1 rounded">key</code>: The key of a record in your HPKV store that was used as a source.</li>
          <li><code className="bg-component-bg text-text p-1 rounded">score</code>: A similarity score between 0 and 1, indicating how relevant this record was to the query.</li>
        </ul>
      </div>

      <p className="mt-4 mb-4">
        The <code className="bg-component-bg text-text p-1 rounded">sources</code> array contains the keys of records used to generate the answer, along with their relevance scores.
      </p>

      <h4 id="query-example" className="text-lg font-bold mb-2">Example</h4>

      <div className="mt-4">
        <CodeTabs snippets={querySnippets} />
      </div>

      <h3 id="code-examples" className="text-xl font-bold mt-6 mb-2">Complete Example</h3>
      
      <p className="mb-4">
        This example demonstrates storing data in HPKV and then querying it with Nexus Search:
      </p>

      <div className="mt-4">
        <CodeTabs snippets={fullExampleSnippets} />
      </div>

      <h3 id="log-analysis" className="text-xl font-bold mt-6 mb-2">Log Analysis Example</h3>
      
      <p className="mb-4">
        One powerful use case for Nexus Search is analyzing log data. By storing logs in HPKV, you can use natural language to query and understand system events, troubleshoot issues, and gain insights from your logs.
      </p>

      <div className="mt-6 border border-blue-400/20 rounded-lg bg-blue-400/5">
        <div className="px-4 py-3 border-b border-blue-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-blue-400" />
          <h4 className="font-bold text-blue-400">Log Analysis Scenario</h4>
        </div>
        <div className="p-4">
          <p className="text-blue-200/90 mb-4">
            In this example, we've stored application logs in HPKV with timestamp-based keys. Each log entry contains information about system events, errors, and user activities.
          </p>
          
          <p className="text-blue-200/90 mb-2"><strong>Sample Log Data:</strong></p>
          <div className="bg-gray-900 rounded-lg p-4 mb-4 text-xs overflow-x-auto">
            <pre className="text-gray-300">
{`key: 20231004123456, value: "2023-10-04 12:34:56 INFO [WebServer] GET /index.html 200 123.456.789.012"
key: 20231004123510, value: "2023-10-04 12:35:10 ERROR [WebServer] GET /nonexistent.html 404 123.456.789.012"
key: 20231004123600, value: "2023-10-04 12:36:00 INFO [AuthService] User 'john_doe' authenticated successfully from IP 123.456.789.012"
key: 20231004123605, value: "2023-10-04 12:36:05 WARN [AuthService] Authentication failed for user 'john_doe' from IP 123.456.789.012"
key: 20231004123700, value: "2023-10-04 12:37:00 INFO [OrderService] User 'john_doe' placed an order with ID 12345"
key: 20231004123705, value: "2023-10-04 12:37:05 ERROR [OrderService] Failed to process order for user 'john_doe': Insufficient funds"
key: 20231004123805, value: "2023-10-04 12:38:05 ERROR [Database] Query failed: INSERT INTO orders ... - Duplicate entry"
key: 20231004123900, value: "2023-10-04 12:39:00 INFO [PaymentGateway] Initiated payment for order 12345"
key: 20231004123910, value: "2023-10-04 12:39:10 ERROR [PaymentGateway] Payment failed for order 12345: Invalid card number"`}
            </pre>
          </div>

          <p className="text-blue-200/90 mb-2"><strong>Example Queries:</strong></p>
          <div className="space-y-6">
            <div>
              <p className="text-blue-200/90 mb-2 italic">Query: "What happened with user john_doe's order?"</p>
              <div className="bg-gray-900 rounded-lg p-4 text-xs overflow-x-auto">
                <pre className="text-gray-300">
{`{
  "answer": "According to the log, here's what happened with user john_doe's order:

1. At 12:37:00, user john_doe placed an order with ID 12345.
2. At 12:37:05, the order failed to process due to insufficient funds.
3. At 12:39:00, the payment gateway initiated payment for the order.
4. At 12:39:10, the payment failed due to an invalid card number.

So, unfortunately, the order was not successfully processed and paid for.",
  "sources": [
    { "key": "20231004123700", "score": 0.8413863 },
    { "key": "20231004123705", "score": 0.8051659 },
    { "key": "20231004123900", "score": 0.7073802 },
    { "key": "20231004123910", "score": 0.6628850 }
  ]
}`}
                </pre>
              </div>
            </div>

            <div>
              <p className="text-blue-200/90 mb-2 italic">Query: "Show me all database errors"</p>
              <div className="bg-gray-900 rounded-lg p-4 text-xs overflow-x-auto">
                <pre className="text-gray-300">
{`{
  "answer": "Here are the database errors from the log:

* [20231004123805]: 2023-10-04 12:38:05 ERROR [Database] Query failed: INSERT INTO orders ... - Duplicate entry

These are the only database errors that occurred.",
  "sources": [
    { "key": "20231004123800", "score": 0.7184418 },
    { "key": "20231004123805", "score": 0.7179137 }
  ]
}`}
                </pre>
              </div>
            </div>

            <div>
              <p className="text-blue-200/90 mb-2 italic">Query: "What happened with the payment for order 12345?"</p>
              <div className="bg-gray-900 rounded-lg p-4 text-xs overflow-x-auto">
                <pre className="text-gray-300">
{`{
  "answer": "According to the log messages:

[20231004123900]: 2023-10-04 12:39:00 INFO [PaymentGateway] Initiated payment for order 12345

[20231004123910]: 2023-10-04 12:39:10 ERROR [PaymentGateway] Payment failed for order 12345: Invalid card number

It appears that the payment for order 12345 was initiated, but then failed due to an invalid card number.",
  "sources": [
    { "key": "20231004123900", "score": 0.8478195 },
    { "key": "20231004123910", "score": 0.7986328 }
  ]
}`}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 mb-4">
        <p>
          As shown in these examples, Nexus Search allows you to ask questions about your logs in natural language and receive contextually relevant answers. This is especially powerful for:
        </p>
        <ul className="list-disc pl-6 mt-2">
          <li>Troubleshooting application errors</li>
          <li>Tracking user activity flows</li>
          <li>Understanding transaction histories</li>
          <li>Investigating security incidents</li>
          <li>Analyzing system performance issues</li>
        </ul>
        <p className="mt-2">
          Unlike traditional log search tools that rely on exact keyword matching or complex query languages, Nexus Search understands the semantic meaning of your logs and queries, making it easier to find relevant information.
        </p>
      </div>

      <h3 id="limitations" className="text-xl font-bold mt-6 mb-2">Limitations</h3>
      
      <div className="mt-6 border border-amber-400/20 rounded-lg bg-amber-400/5">
        <div className="px-4 py-3 border-b border-amber-400/20 flex items-center gap-2">
          <BeakerIcon className="h-5 w-5 text-amber-400" />
          <h4 className="font-bold text-amber-400">Current Limitations</h4>
        </div>
        <ul className="list-disc pl-10 pr-4 py-3" style={{ color: 'rgba(217, 158, 41, 0.9)' }}>
          <li>The embedding model only supports English text (non-English content may have reduced accuracy)</li>
          <li>There is a processing delay of a few seconds between when data is written to HPKV and when it becomes searchable</li>
          <li>Up to 20 results can be returned with a single search request</li>
        </ul>
      </div>

      <h3 id="best-practices" className="text-xl font-bold mt-6 mb-2">Best Practices</h3>
      
      <div className="mt-6 border border-emerald-400/20 rounded-lg bg-emerald-400/5">
        <div className="px-4 py-3 border-b border-emerald-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-emerald-400" />
          <h4 className="font-bold text-emerald-400">Recommendations for Effective Use</h4>
        </div>
        <ul className="list-disc pl-10 pr-4 py-3" style={{ color: 'rgba(94, 196, 150, 1)' }}>
          <li>Optimize content structure: Store semantically coherent chunks of information under individual keys</li>
          <li>Use descriptive keys: Create meaningful key naming patterns that describe the content (e.g., "article:databases" instead of "a123")</li>
          <li>Adjust topK values: Start with the default of 5 and adjust based on your use case</li>
          <li>Filter with minScore: Use higher values (0.7+) for stricter matching or lower values (0.4-0.5) for broader results</li>
          <li>Use specific queries: More specific questions typically yield more relevant results</li>
          <li>Handle updates properly: When updating content, old embeddings are automatically replaced, but allow time for processing</li>
          <li>Validate search results: Always validate search results before presenting them to users</li>
          <li>Consider query patterns: Design your data model with typical query patterns in mind</li>
        </ul>
      </div>
    </div>
  );
};

export default NexusSearch; 