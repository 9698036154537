import React from 'react';
import { Link } from 'react-router-dom';

const Overview = () => (
  <div className="space-y-6">
    <h1 className="sr-only">HPKV Overview</h1>
    <h2 className="text-2xl font-bold mb-4">HPKV Overview</h2>
    <p>
      HPKV is a high-performance key-value store that provides fast, reliable, and scalable data storage through simple APIs. 
      It offers three powerful ways to interact with your data: REST API, WebSocket API, and RIOC (Remote IO Control).
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">REST API</h3>
    <p>
      The REST API provides a simple HTTP-based interface for interacting with HPKV. It's ideal for:
    </p>
    <ul className="list-disc pl-6">
      <li>Traditional web applications</li>
      <li>Mobile app backends</li>
      <li>Simple CRUD operations</li>
      <li>When you need broad language and framework support</li>
    </ul>
    <p>
      The REST API supports all basic operations including inserting, retrieving, updating, and deleting key-value pairs.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">WebSocket API</h3>
    <p>
      The WebSocket API offers a persistent connection for high-performance operations. It's particularly well-suited for:
    </p>
    <ul className="list-disc pl-6">
      <li>High-throughput applications</li>
      <li>When you need to minimize latency</li>
      <li>Real-time applications</li>
      <li>When you want to reduce HTTP overhead</li>
    </ul>
    <p>
      The WebSocket API provides the same core functionality as the REST API but with improved performance due to connection reuse.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">RIOC (Remote IO Control)</h3>
    <p>
      RIOC is our high-performance RPC protocol, available in Business and Enterprise plans. It's the optimal choice for:
    </p>
    <ul className="list-disc pl-6">
      <li>Ultra-low latency requirements</li>
      <li>High-performance enterprise applications</li>
      <li>When you need direct, efficient access</li>
      <li>Applications using our official SDKs</li>
    </ul>
    <p>
      RIOC provides the fastest possible way to interact with HPKV through a secure, efficient binary protocol with native SDK support.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">Key Features</h3>
    <ul className="list-disc pl-6">
      <li><strong>Performance:</strong> Fast and efficient data operations</li>
      <li><strong>Simplicity:</strong> Easy to use with straightforward APIs</li>
      <li><strong>Reliability:</strong> Designed for production workloads</li>
      <li><strong>Scalability:</strong> Handles growing data needs efficiently</li>
      <li><strong>Security:</strong> API key authentication and HTTPS/WSS encryption</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Choosing the Right API</h3>
    <p>
      When deciding between the available APIs, consider these factors:
    </p>
    <ul className="list-disc pl-6">
      <li><strong>REST API</strong> is best when:
        <ul className="list-disc pl-6 mt-2">
          <li>You need simple, stateless operations</li>
          <li>You're making occasional requests</li>
          <li>You want the simplest integration</li>
          <li>You need broad framework compatibility</li>
        </ul>
      </li>
      <li className="mt-4"><strong>WebSocket API</strong> is best when:
        <ul className="list-disc pl-6 mt-2">
          <li>You need to minimize latency</li>
          <li>You're making frequent requests</li>
          <li>You want to reduce network overhead</li>
          <li>Your application requires high throughput</li>
        </ul>
      </li>
      <li className="mt-4"><strong>RIOC</strong> is best when:
        <ul className="list-disc pl-6 mt-2">
          <li>You need the lowest possible latency</li>
          <li>You're using our official SDKs</li>
          <li>You want secure, efficient binary communication</li>
          <li>You need advanced features like batch operations</li>
        </ul>
      </li>
    </ul>

    <div className="mt-8">
      <p>
        Ready to get started? Check out our <Link to="/docs/getting-started" className="text-primary hover:underline">Getting Started Guide</Link>, 
        or dive into the details of our <Link to="/docs/rest-api" className="text-primary hover:underline">REST API</Link>, <Link to="/docs/websocket-api" className="text-primary hover:underline">WebSocket API</Link>, 
        and <Link to="/docs/rioc-api" className="text-primary hover:underline">RIOC</Link> documentation.
      </p>
    </div>
  </div>
);

export default Overview;