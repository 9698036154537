import React, { useState } from 'react';
import { PencilIcon, XMarkIcon, CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const Settings = ({ user, onUpdateProfile, onLogout }) => {
  const [editingField, setEditingField] = useState(null);
  const [editedName, setEditedName] = useState(user.name);
  const [editedCompanyName, setEditedCompanyName] = useState(user.company_name || '');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  const handleSave = async (field) => {
    const updates = {
      name: field === 'name' ? editedName : user.name,
      companyName: field === 'company_name' ? editedCompanyName : (user.company_name || '')
    };
    
    await onUpdateProfile(updates);
    setEditingField(null);
  };

  const handleCancel = (field) => {
    if (field === 'name') {
      setEditedName(user.name);
    } else if (field === 'company_name') {
      setEditedCompanyName(user.company_name || '');
    }
    setEditingField(null);
  };

  const handleDeleteAccount = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/auth/delete-account', {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to delete account');
      }

      // Remove token and logout
      localStorage.removeItem('token');
      onLogout();
    } catch (error) {
      setError(error.message);
      setShowError(true);
    }
  };

  const renderErrorBanner = () => {
    if (!showError || !error) return null;
    
    return (
      <div className="bg-red-500/10 text-red-500 px-6 py-3 rounded-lg shadow-lg flex items-center mb-4">
        <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
        <span>{error}</span>
        <button 
          onClick={() => setShowError(false)}
          className="ml-4 text-red-500 hover:text-red-400"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {renderErrorBanner()}
      <div className="grid grid-cols-1 gap-6">
        <div className="bg-component-bg rounded-lg p-6 shadow-sm">
          <h3 className="text-xl font-semibold text-text mb-6">User Profile</h3>
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-text mb-2">Full name</label>
              <div className="flex items-center space-x-2">
                {editingField === 'name' ? (
                  <div className="flex items-center space-x-2 flex-1">
                    <input
                      type="text"
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      className="flex-1 px-2 py-1 bg-background border border-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                    />
                    <button
                      onClick={() => handleSave('name')}
                      className="text-accent-purple hover:text-accent-blue"
                    >
                      <CheckCircleIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleCancel('name')}
                      className="text-text-secondary hover:text-text"
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                ) : (
                  <>
                    <p className="text-text flex-1">{user.name}</p>
                    <button
                      onClick={() => {
                        setEditedName(user.name);
                        setEditingField('name');
                      }}
                      className="text-text-secondary hover:text-text"
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>
                  </>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-text mb-2">Email address</label>
              <p className="text-text">{user.email}</p>
            </div>

            <div>
              <label className="block text-sm font-medium text-text mb-2">Company name</label>
              <div className="flex items-center space-x-2">
                {editingField === 'company_name' ? (
                  <div className="flex items-center space-x-2 flex-1">
                    <input
                      type="text"
                      value={editedCompanyName}
                      onChange={(e) => setEditedCompanyName(e.target.value)}
                      className="flex-1 px-2 py-1 bg-background border border-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                    />
                    <button
                      onClick={() => handleSave('company_name')}
                      className="text-accent-purple hover:text-accent-blue"
                    >
                      <CheckCircleIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleCancel('company_name')}
                      className="text-text-secondary hover:text-text"
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                ) : (
                  <>
                    <p className="text-text flex-1">{user.company_name || '-'}</p>
                    <button
                      onClick={() => {
                        setEditedCompanyName(user.company_name || '');
                        setEditingField('company_name');
                      }}
                      className="text-text-secondary hover:text-text"
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-component-bg rounded-lg p-6 shadow-sm">
          <h3 className="text-xl font-semibold text-text mb-6">Danger Zone</h3>
          <div>
            <p className="text-text-secondary mb-4">
              Once you delete your account, there is no going back. Please be certain.
            </p>
            {!showDeleteConfirmation ? (
              <button
                onClick={() => setShowDeleteConfirmation(true)}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                Delete Account
              </button>
            ) : (
              <div className="bg-red-500/10 p-4 rounded-lg border border-red-500">
                <p className="text-red-500 font-medium mb-4">
                  Are you absolutely sure you want to delete your account?
                </p>
                <p className="text-text-secondary mb-6">
                  This action cannot be undone. This will permanently delete your account, API keys, 
                  and all associated data. Your API keys will be immediately revoked and any applications 
                  using them will stop working.
                </p>
                <div className="flex space-x-4">
                  <button
                    onClick={handleDeleteAccount}
                    className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                  >
                    Yes, delete my account
                  </button>
                  <button
                    onClick={() => setShowDeleteConfirmation(false)}
                    className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings; 