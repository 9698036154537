import React, { useState } from 'react';
import CodeBlock from './CodeBlock';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeTabs = ({ snippets }) => {
  const [activeTab, setActiveTab] = useState(Object.keys(snippets)[0]);

  // Create a modified theme without background color
  const customStyle = {
    ...vscDarkPlus,
    'pre[class*="language-"]': {
      ...vscDarkPlus['pre[class*="language-"]'],
      background: 'transparent',
      margin: 0,
      borderRadius: 0
    },
    'code[class*="language-"]': {
      ...vscDarkPlus['code[class*="language-"]'],
      background: 'transparent'
    }
  };

  return (
    <div className="rounded-lg overflow-hidden border border-gray-700">
      <div className="flex border-b border-gray-700">
        {Object.keys(snippets).map((language) => (
          <button
            key={language}
            onClick={() => setActiveTab(language)}
            className={`px-4 py-2 text-sm font-medium transition-colors duration-200 ${
              activeTab === language
                ? 'bg-primary/20 text-primary'
                : 'text-text-secondary hover:text-text hover:bg-secondary/20'
            }`}
          >
            {language}
          </button>
        ))}
      </div>
      <div className="relative bg-component-bg">
        <CodeBlock
          code={snippets[activeTab].code}
          language={snippets[activeTab].language || activeTab.toLowerCase()}
          style={customStyle}
        />
      </div>
    </div>
  );
};

export default CodeTabs; 