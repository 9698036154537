import React from 'react';
import { LightBulbIcon, ShieldCheckIcon, CodeBracketIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import CodeTabs from './CodeTabs';
import BusinessEnterpriseBanner from './BusinessEnterpriseBanner';

const RiocApi = () => {
  const connectionSnippets = {
    'Node.js': {
      language: 'javascript',
      code: `const { RiocClient } = require('@hpkv/rioc');

// Create client configuration
const config = {
  host: 'your-hpkv-host',
  port: 7000,  // Default RIOC port
  timeoutMs: 5000,  // Optional timeout in milliseconds
  tls: {
    caPath: 'path/to/ca.crt',          // CA certificate
    certificatePath: 'path/to/client.crt', // Client certificate
    keyPath: 'path/to/client.key',        // Client private key
    verifyHostname: 'your-hpkv-host',     // Server hostname to verify
    verifyPeer: true                      // Enable certificate verification
  }
};

// Create client
const client = new RiocClient(config);`
    },
    'Python': {
      language: 'python',
      code: `from hpkv_rioc import RiocClient, RiocConfig, RiocTlsConfig

# Create client configuration
config = RiocConfig(
    host='your-hpkv-host',
    port=7000,  # Default RIOC port
    timeout_ms=5000,  # Optional timeout in milliseconds
    tls=RiocTlsConfig(
        certificate_path='path/to/client.crt',
        key_path='path/to/client.key',
        ca_path='path/to/ca.crt',
        verify_hostname='your-hpkv-host',
        verify_peer=True
    )
)

# Create client
client = RiocClient(config)`
    },
    '.NET': {
      language: 'csharp',
      code: `using HPKV.RIOC;

// Create client configuration
var config = new RiocConfig
{
    Host = "your-hpkv-host",
    Port = 7000,  // Default RIOC port
    TimeoutMs = 5000,  // Optional timeout in milliseconds
    Tls = new RiocTlsConfig
    {
        CertificatePath = "path/to/client.crt",
        KeyPath = "path/to/client.key",
        CaPath = "path/to/ca.crt",
        VerifyHostname = "your-hpkv-host",
        VerifyPeer = true
    }
};

// Create client
using var client = new RiocClient(config);`
    }
  };

  const operationsSnippets = {
    'Node.js': {
      language: 'javascript',
      code: `// Get current timestamp (required for write operations)
const timestamp = RiocClient.getTimestamp();

// Store a value (requires timestamp)
const key = Buffer.from('user:123');
const value = Buffer.from('John Doe');
client.insert(key, value, timestamp);

// Retrieve a value (no timestamp needed)
const retrievedValue = client.get(key);
console.log('Retrieved:', retrievedValue.toString());

// Range query - get all users from 100 to 200
const startKey = Buffer.from('user:100');
const endKey = Buffer.from('user:200');
const results = client.rangeQuery(startKey, endKey, 50); // limit to 50 records

for (const { key, value } of results) {
  console.log(\`\${key.toString()}: \${value.toString()}\`);
}

// Delete a value (requires timestamp)
const deleteTimestamp = RiocClient.getTimestamp();
client.delete(key, deleteTimestamp);

// Atomic increment/decrement
const counterKey = Buffer.from('counter:123');
const newValue = client.atomicIncDec(counterKey, 1, RiocClient.getTimestamp()); // Increment by 1
console.log('New counter value:', newValue);

// Batch operations
const batch = client.createBatch();
const batchTimestamp = RiocClient.getTimestamp();

batch.addGet(Buffer.from('key1'));
batch.addInsert(Buffer.from('key2'), Buffer.from('value2'), batchTimestamp);
batch.addDelete(Buffer.from('key3'), batchTimestamp);
batch.addRangeQuery(Buffer.from('user:100'), Buffer.from('user:200'), 10);

const tracker = batch.executeAsync();
tracker.wait(1000);  // Wait up to 1 second for completion

// Get results from the batch
const batchGetResult = tracker.getResponse(0);
const batchRangeResult = tracker.getRangeQueryResponse(3);

// Clean up resources
tracker.dispose();
batch.dispose();`
    },
    'Python': {
      language: 'python',
      code: `# Get current timestamp (required for write operations)
timestamp = client.get_timestamp()

# Store a value (requires timestamp)
client.insert_string('user:123', 'John Doe', timestamp)

# Retrieve a value (no timestamp needed)
value = client.get_string('user:123')
print('Retrieved:', value)

# Range query - get all users from 100 to 200
results = client.range_query_string('user:100', 'user:200', limit=50)

for key, value in results:
    print(f"{key}: {value}")

# Delete a value (requires timestamp)
delete_timestamp = client.get_timestamp()
client.delete_string('user:123', delete_timestamp)

# Atomic increment/decrement
new_value = client.atomic_inc_dec_string('counter:123', 1, client.get_timestamp())
print('New counter value:', new_value)

# Batch operations
with client.batch() as batch:
    batch_timestamp = client.get_timestamp()
    
    batch.add_get(b'key1')
    batch.add_insert(b'key2', b'value2', batch_timestamp)
    batch.add_delete(b'key3', batch_timestamp)
    batch.add_range_query(b'user:100', b'user:200', 10)
    
    tracker = batch.execute()
    tracker.wait(1000)  # Wait up to 1 second for completion
    
    # Get results from the batch
    batch_get_result = tracker.get_response(0)
    batch_range_result = tracker.get_range_query_response(3)

# Resources are automatically cleaned up when exiting the context manager`
    },
    '.NET': {
      language: 'csharp',
      code: `// Get current timestamp (required for write operations)
ulong timestamp = RiocClient.GetTimestamp();

// Store a value (requires timestamp)
client.InsertString("user:123", "John Doe", timestamp);

// Retrieve a value (no timestamp needed)
string value = client.GetString("user:123");
Console.WriteLine($"Retrieved: {value}");

// Range query - get all users from 100 to 200
var results = client.RangeQueryString("user:100", "user:200", limit: 50);

foreach (var kvp in results)
{
    Console.WriteLine($"{kvp.Key}: {kvp.Value}");
}

// Delete a value (requires timestamp)
ulong deleteTimestamp = RiocClient.GetTimestamp();
client.DeleteString("user:123", deleteTimestamp);

// Atomic increment/decrement
long newValue = client.AtomicIncDecString("counter:123", 1, RiocClient.GetTimestamp());
Console.WriteLine($"New counter value: {newValue}");

// Batch operations
using var batch = client.CreateBatch();
ulong batchTimestamp = RiocClient.GetTimestamp();

batch.AddGet(Encoding.UTF8.GetBytes("key1"));
batch.AddInsert(Encoding.UTF8.GetBytes("key2"), 
                Encoding.UTF8.GetBytes("value2"), 
                batchTimestamp);
batch.AddDelete(Encoding.UTF8.GetBytes("key3"), batchTimestamp);
batch.AddRangeQuery(Encoding.UTF8.GetBytes("user:100"), Encoding.UTF8.GetBytes("user:200"), 10);

using var tracker = batch.ExecuteAsync();
tracker.Wait(1000);  // Wait up to 1 second for completion

// Get results from the batch
var batchGetResult = tracker.GetResponse(0);
var batchRangeResult = tracker.GetRangeQueryResponse(3);

// Resources are automatically cleaned up by the 'using' statement`
    }
  };

  const enterpriseSnippets = {
    'Node.js': {
      language: 'javascript',
      code: `// Initialize without TLS for Enterprise setup
const client = new HPKVClient({
  host: 'localhost',
  port: 7000,
  tls: false  // Disable TLS
});

await client.connect();`
    },
    'Python': {
      language: 'python',
      code: `# Initialize without TLS for Enterprise setup
client = HPKVClient(
    host='localhost',
    port=7000,
    tls_enabled=False  # Disable TLS
)

await client.connect()`
    },
    '.NET': {
      language: 'csharp',
      code: `// Initialize without TLS for Enterprise setup
var client = new HPKVClient(new HPKVClientOptions
{
    Host = "localhost",
    Port = 7000,
    TlsEnabled = false  // Disable TLS
});

await client.ConnectAsync();`
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="sr-only">HPKV RIOC API</h1>
      <BusinessEnterpriseBanner />
      <h2 className="text-2xl font-bold mb-4">RIOC (Remote IO Control)</h2>
      <p>
        RIOC is a high-performance RPC protocol designed specifically for HPKV, offering the fastest possible way to interact with your data. 
        Available in Business and Enterprise plans, RIOC provides direct, low-latency access to HPKV with robust security through TLS 1.3.
      </p>

      <div className="mt-6 border border-blue-400/20 rounded-lg bg-blue-400/5">
        <div className="px-4 py-3 border-b border-blue-400/20 flex items-center gap-2">
          <ShieldCheckIcon className="h-5 w-5 text-blue-400" />
          <h4 className="font-bold text-blue-400">Security</h4>
        </div>
        <div className="p-4">
          <p className="text-blue-200/90">
            RIOC uses TLS 1.3 for authentication and encryption. Your certificates will be automatically generated and available in your Dashboard. 
            Enterprise users can optionally disable TLS for private setups where security is managed through other means.
          </p>
        </div>
      </div>

      <h3 id="key-benefits" className="text-xl font-bold mt-6 mb-2">Key Benefits</h3>
      <ul className="list-disc pl-6">
        <li>Ultra-low latency communication (in the ~15µs range over network)</li>
        <li>Efficient binary protocol</li>
        <li>Native SDK support for Node.js, Python, and .NET</li>
        <li>Secure by default with TLS 1.3</li>
        <li>Support for batch operations</li>
        <li>Connection pooling and automatic reconnection</li>
        <li>Atomic operations for counters and rate limiters</li>
      </ul>

      <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
        <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-indigo-400" />
          <h4 className="font-bold text-indigo-400">Note</h4>
        </div>
        <div className="p-4">
          <p className="text-indigo-200/90">
            Enterprise customers have access to a local vectored call interface that bypasses network communication entirely, reducing operation latency to the ~300ns range. This is ideal for applications requiring extreme low latency and running on the same machine as HPKV.
          </p>
        </div>
      </div>

      <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
        <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
          <CodeBracketIcon className="h-5 w-5 text-indigo-400" />
          <h4 id="open-source" className="font-bold text-indigo-400">Open Source Client</h4>
        </div>
        <div className="p-4">
          <p className="text-indigo-200/90 mb-4">
            The RIOC client SDKs are available as open source software on GitHub. You can find the source code, contribute, or create custom implementations.
          </p>
          <div className="mt-2 mb-4">
            <a href="https://github.com/hpkv-io/rioc" target="_blank" rel="noopener noreferrer" className="inline-flex items-center gap-2 px-4 py-2 bg-gray-800 hover:bg-gray-700 text-white rounded-lg transition-colors duration-200 font-medium">
              <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
              </svg>
              <span>RIOC GitHub Repository</span>
            </a>
          </div>
          <p className="text-indigo-200/90">
            The repository includes client SDKs for Node.js, Python, and .NET, core API documentation, implementation examples, and client protocol specifications. All client code is licensed under the Apache License 2.0.
          </p>
        </div>
      </div>

      <h3 id="connecting" className="text-xl font-bold mt-6 mb-2">Connecting with RIOC</h3>
      <p className="mb-4">
        RIOC is available through our official SDKs. Here's how to establish a connection:
      </p>
      <div className="mt-4">
        <CodeTabs snippets={connectionSnippets} />
      </div>

      <h3 id="basic-operations" className="text-xl font-bold mt-6 mb-2">Basic Operations</h3>
      <p className="mb-4">
        RIOC provides high-performance operations for working with HPKV:
      </p>
      <div className="mt-4">
        <CodeTabs snippets={operationsSnippets} />
      </div>

      <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
        <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-indigo-400" />
          <h4 className="font-bold text-indigo-400">Note</h4>
        </div>
        <div className="p-4">
          <p className="text-indigo-200/90">
            Please refer to <Link to="/docs/sdk-guides">SDK Guides</Link> for more information on the RIOC API usage.
          </p>
        </div>
      </div>

      <h3 id="enterprise-setup" className="text-xl font-bold mt-6 mb-2">Enterprise Setup</h3>
      <p className="mb-4">
        Enterprise users can run HPKV locally and optionally disable TLS for improved performance in secure environments:
      </p>
      <div className="mt-4">
        <CodeTabs snippets={enterpriseSnippets} />
      </div>

      <div id="best-practices" className="mt-6 border border-emerald-400/20 rounded-lg bg-emerald-400/5">
        <div className="px-4 py-3 border-b border-emerald-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-emerald-400" />
          <h4 className="font-bold text-emerald-400">Best Practices</h4>
        </div>
        <ul className="list-disc pl-10 pr-4 py-3" style={{ color: 'rgba(94, 196, 150, 1)' }}>
          <li>Preferably use timestamps from RiocClient.getTimestamp() for write operations</li>
          <li>Use connection pooling for concurrent operations</li>
          <li>Keep TLS enabled unless you have a specific reason not to</li>
          <li>Use batch operations for multiple related operations</li>
          <li>Monitor connection health and implement reconnection logic</li>
          <li>Store certificates securely and rotate them periodically</li>
        </ul>
      </div>
    </div>
  );
};

export default RiocApi; 