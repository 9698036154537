import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BoltIcon, ChartBarIcon, CircleStackIcon, BanknotesIcon, BellAlertIcon, CubeTransparentIcon } from '@heroicons/react/24/outline';

const features = [
  {
    name: 'Exceptional Speed',
    description: 'Ultra-low latency performance with consistent sub-microsecond response times across all operations, even under heavy load.',
    icon: BoltIcon,
  },
  {
    name: 'Persistent Storage',
    description: 'Using advanced storage layouts, direct I/O, zero-copy, and intelligent scheduling, we push the boundaries of what is possible with persistent storage.',
    icon: CircleStackIcon,
  },
  {
    name: 'Nexus Search',
    description: 'AI-powered semantic search and RAG capabilities let you query your data with natural language and get intelligent answers based on your stored content.',
    icon: CubeTransparentIcon,
  },
  {
    name: 'Real-time Capabilities',
    description: 'Built-in WebSocket pub-sub system for real-time monitoring and notifications when key values change, enabling reactive applications with minimal latency.',
    icon: BellAlertIcon,
  },
  {
    name: 'Low operational cost',
    description: 'Utilizing advanced techniques and novel algorithms, we provide 4-5x lower operational costs than leading in-memory KV stores.',
    icon: BanknotesIcon,
  },
  {
    name: 'Scalability',
    description: 'Designed to scale linearly with your data growth, without sacrificing performance.',
    icon: ChartBarIcon,
  }
];

const FeatureCard = ({ feature }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  return (
    <motion.div
      className="group relative overflow-hidden rounded-2xl bg-component-bg p-8 border border-gray-800 hover:border-accent-purple/50 transition-colors duration-300 min-h-[320px]"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Gradient overlay */}
      <div
        className="absolute inset-0 transition-opacity duration-300"
        style={{
          opacity: isHovered ? 1 : 0,
          background: isHovered
            ? `radial-gradient(800px circle at ${mousePosition.x}px ${mousePosition.y}px, rgba(93, 93, 255, 0.15), transparent 40%)`
            : '',
        }}
      />

      {/* Content */}
      <div className="relative z-10">
        <div className="flex flex-col h-full">
          <div className="mb-4">
            <div className="inline-flex items-center justify-center p-2 rounded-lg bg-accent-purple/10 group-hover:bg-accent-purple/20 transition-colors duration-300">
              <feature.icon className="h-6 w-6 text-accent-purple" />
            </div>
          </div>
          <h3 className="text-xl font-semibold text-heading-text group-hover:text-white transition-colors duration-300 mb-3">
            {feature.name}
          </h3>
          <p className="text-text group-hover:text-text/90 transition-colors duration-300 text-base">
            {feature.description}
          </p>
        </div>
      </div>

      {/* Subtle border gradient */}
      <div
        className="absolute inset-0 transition-opacity duration-300"
        style={{
          opacity: isHovered ? 1 : 0,
          background: 'linear-gradient(45deg, rgba(93, 93, 255, 0.15), transparent)',
          mask: 'linear-gradient(black, black) content-box, linear-gradient(black, black)',
          maskComposite: 'exclude',
        }}
      />
    </motion.div>
  );
};

const Features = () => {
  return (
    <section className="py-20 bg-background">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <p className="text-sm font-semibold text-accent-purple mb-4">Features</p>
          <h2 className="text-3xl md:text-4xl font-bold text-heading-text mb-6">
            Speed Meets Intelligence
          </h2>
          <p className="text-xl text-text">
            <span className="text-accent-purple">Blazing Fast</span> with sub-microsecond latency on local operations<br />
            <span className="text-accent-purple">AI-Powered</span> with semantic search and RAG capabilities<br />
            <span className="text-accent-purple">2x</span> faster than leading in-memory KV Stores with the <span className="text-accent-purple">power of AI</span>
          </p>
        </div>

        {/* Benchmark Highlights */}
        <div className="max-w-4xl mx-auto mb-16 p-6 rounded-2xl bg-component-bg border border-gray-800">
          <div className="text-center mb-6">
            <h3 className="text-2xl font-bold text-heading-text mb-2">Unmatched Performance with AI Capabilities</h3>
            <p className="text-text">Combining ultra-fast operations with AI-powered search capabilities</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6">
            <div className="text-center">
              <h4 className="text-lg font-semibold text-heading-text mb-2">Operational Speed</h4>
              <p className="text-text">
                <span className="text-accent-purple font-bold text-2xl">4-6.2x</span><br />
                faster throughput than Redis
              </p>
              <p className="text-sm text-text mt-2">
                Up to 1.7M ops/sec for reads<br />
                P50 latency under 12µs
              </p>
              <a 
                href="/blog/2025/02/redis-vs-hpkv-benchmark"
                className="inline-flex items-center text-accent-purple hover:text-accent-purple/90 transition-colors duration-300 mt-2"
              >
                View benchmark results
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </a>
            </div>
            <div className="text-center">
              <h4 className="text-lg font-semibold text-heading-text mb-2">AI-Powered Search</h4>
              <p className="text-text">
                <span className="text-accent-purple font-bold text-2xl">Natural Language</span><br />
                query capabilities with RAG
              </p>
              <p className="text-sm text-text mt-2">
                Semantic understanding of your data<br />
                AI-generated answers from your content
              </p>
              <a 
                href="/docs/nexus-search"
                className="inline-flex items-center text-accent-purple hover:text-accent-purple/90 transition-colors duration-300 mt-2"
              >
                Learn more about Nexus Search
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </a>
            </div>
          </div>
          
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {features.map((feature) => (
            <FeatureCard key={feature.name} feature={feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
