import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactCountryFlag from 'react-country-flag';

const Footer = () => {
  const linkGroups = [
    {
      title: 'Product',
      links: [
        { name: 'Features', href: '/features' },
        { name: 'Pricing', href: '/pricing' },
        { name: 'Documentation', href: '/docs', onClick: () => window.scrollTo(0, 0) },
        { name: 'API Reference', href: '/docs/rest-api', onClick: () => window.scrollTo(0, 0) },
      ],
    },
    {
      title: 'Company',
      links: [
        { name: 'About', href: '/about' },
        { name: 'Blog', href: '/blog' },
        // { name: 'Careers', href: '/careers' },
        { name: 'Contact', href: '/contact' },
      ],
    },
    {
      title: 'Resources',
      links: [
        { name: 'Community', href: 'https://discord.gg/hpkv' },
        { name: 'Roadmap', href: '/roadmap' },
        { name: 'Status', href: 'https://status.hpkv.io' },
      ],
    },
    {
      title: 'Legal',
      links: [
        { name: 'Terms of Service', href: '/terms' },
        { name: 'Privacy Policy', href: '/privacy' },
        { name: 'Fair Use Policy', href: '/fair-use' },
      ],
    },
  ];

  const socialLinks = [
    { name: 'GitHub', href: 'https://github.com/hpkv-io', icon: 'github' },
    { name: 'Twitter', href: 'https://twitter.com/hpkvHQ', icon: 'twitter' },
    { name: 'LinkedIn', href: 'https://www.linkedin.com/company/hpkv', icon: 'linkedin' },
  ];

  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-8 xl:col-span-1"
          >
            <Link to="/" className="text-2xl font-bold">HPKV</Link>
            <p className="text-gray-400 text-sm">
              High-Performance KV Store.<br />The only consistent nanosecond level KV store on the market.
            </p>
            <div className="flex space-x-6">
              {socialLinks.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-400 hover:text-white transition-colors">
                  <span className="sr-only">{item.name}</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    {item.icon === 'github' && (
                      <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
                    )}
                    {item.icon === 'twitter' && (
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 003.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    )}
                    {item.icon === 'linkedin' && (
                      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                    )}
                  </svg>
                </a>
              ))}
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2"
          >
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {linkGroups.slice(0, 2).map((group) => (
                <div key={group.title}>
                  <h3 className="text-sm font-semibold text-gray-300 tracking-wider uppercase">{group.title}</h3>
                  <ul className="mt-4 space-y-4">
                    {group.links.map((link) => (
                      <li key={link.name}>
                        <Link 
                          to={link.href} 
                          className="text-base text-gray-400 hover:text-white transition-colors"
                          onClick={link.onClick}
                        >
                          {link.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {linkGroups.slice(2).map((group) => (
                <div key={group.title}>
                  <h3 className="text-sm font-semibold text-gray-300 tracking-wider uppercase">{group.title}</h3>
                  <ul className="mt-4 space-y-4">
                    {group.links.map((link) => (
                      <li key={link.name}>
                        <Link to={link.href} className="text-base text-gray-400 hover:text-white transition-colors">
                          {link.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="mt-12 border-t border-gray-700 pt-8"
        >
          <p className="text-xs text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()} HPKV. All rights reserved.
            <br />
            <ReactCountryFlag 
                              countryCode="DK" 
                              svg 
                              style={{
                                width: '1.2em',
                                height: '1.2em',
                                marginRight: '0.5em',
                              }}
                              title="Denmark"
                              aria-label="Denmark Flag"
                              loading="lazy"
                            />Copenhagen, Denmark
          </p>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
