import React from 'react';
import { Link } from 'react-router-dom';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import CodeTabs from './CodeTabs';

const CodeExamples = () => {

  const basicCrudSnippets = {
    Python: {
      language: 'python',
      code: `import os
import sys
import requests
import json
import time
from typing import Any, Dict, Optional, Union
from dotenv import load_dotenv

class HPKVClient:
    def __init__(self, base_url: str = None, api_key: str = None):
        """Initialize HPKV client with API key.
        
        Args:
            base_url: HPKV server URL (optional, defaults to HPKV_BASE_URL env var)
            api_key: HPKV API key (optional, defaults to HPKV_API_KEY env var)
        """
        # Load environment variables if not already loaded
        if not os.getenv('HPKV_BASE_URL') and not os.getenv('HPKV_API_KEY'):
            load_dotenv()
            
        self.base_url = (base_url or os.getenv('HPKV_BASE_URL')).rstrip('/') if (base_url or os.getenv('HPKV_BASE_URL')) else None
        self.api_key = api_key or os.getenv('HPKV_API_KEY')
        
        if not self.base_url:
            raise ValueError("HPKV base URL not provided. Set HPKV_BASE_URL environment variable or pass base_url parameter.")
        if not self.api_key:
            raise ValueError("HPKV API key not provided. Set HPKV_API_KEY environment variable or pass api_key parameter.")
        
        self.headers = {
            'Content-Type': 'application/json',
            'x-api-key': self.api_key
        }
    
    def _serialize_value(self, value: Any) -> str:
        """Serialize value to string format.
        
        Args:
            value: Value to serialize
            
        Returns:
            str: Serialized value
        """
        if isinstance(value, str):
            return value
        return json.dumps(value)
    
    def _handle_response(self, response: requests.Response, operation: str) -> Union[Dict, str, None]:
        """Handle API response and extract data.
        
        Args:
            response: Response from API
            operation: Operation name for error messages
            
        Returns:
            Response data if successful, None otherwise
        """
        try:
            if response.status_code in [200, 201]:
                return response.json() if response.text else None
            else:
                error_msg = f"Error in {operation}: Status {response.status_code}"
                if response.text:
                    try:
                        error_data = response.json()
                        error_msg += f" - {error_data.get('message', response.text)}"
                    except:
                        error_msg += f" - {response.text}"
                print(error_msg, file=sys.stderr)
                return None
        except Exception as e:
            print(f"Error parsing response in {operation}: {str(e)}", file=sys.stderr)
            return None
        
    def create(self, key: str, value: Any) -> bool:
        """Create a new key-value pair.
        
        Args:
            key: Key to store
            value: Value to store
            
        Returns:
            bool: True if creation was successful, False otherwise
        """
        try:
            payload = {
                "key": key,
                "value": self._serialize_value(value)
            }
            
            print(f"Sending request to {self.base_url}/record")
            print(f"Payload: {json.dumps(payload, indent=2)}")
            
            response = requests.post(
                f"{self.base_url}/record",
                headers=self.headers,
                json=payload
            )
            
            if response.status_code not in [200, 201]:
                error_msg = f"Create failed with status {response.status_code}"
                if response.text:
                    try:
                        error_data = response.json()
                        error_msg += f" - {error_data.get('message', response.text)}"
                    except:
                        error_msg += f" - {response.text}"
                print(error_msg, file=sys.stderr)
                return False
                
            print(f"Create succeeded with status {response.status_code}")
            return True
                
        except Exception as e:
            print(f"Error creating record: {str(e)}", file=sys.stderr)
            return False

    def read(self, key: str) -> Optional[Any]:
        """Read a value by key."""
        try:
            response = requests.get(
                f"{self.base_url}/record/{key}",
                headers=self.headers
            )
            
            data = self._handle_response(response, "read")
            if data and 'value' in data:
                try:
                    return json.loads(data['value'])
                except (json.JSONDecodeError, TypeError):
                    return data['value']
            return None
            
        except Exception as e:
            print(f"Error reading record: {str(e)}", file=sys.stderr)
            return None

    def update(self, key: str, value: Any, partial_update: bool = False) -> bool:
        """Update an existing key-value pair."""
        try:
            payload = {
                "key": key,
                "value": self._serialize_value(value),
                "partialUpdate": partial_update
            }
            
            response = requests.post(
                f"{self.base_url}/record",
                headers=self.headers,
                json=payload
            )
            
            return response.status_code == 200
            
        except Exception as e:
            print(f"Error updating record: {str(e)}", file=sys.stderr)
            return False

    def delete(self, key: str) -> bool:
        """Delete a key-value pair."""
        try:
            response = requests.delete(
                f"{self.base_url}/record/{key}",
                headers=self.headers
            )
            
            return response.status_code == 200
            
        except Exception as e:
            print(f"Error deleting record: {str(e)}", file=sys.stderr)
            return False`
    },
    JavaScript: {
      language: 'javascript',
      code: `import fetch from 'node-fetch';

export class HPKVClient {
    constructor(baseUrl = null, apiKey = null) {
        this.baseUrl = (baseUrl || process.env.HPKV_BASE_URL || '');
        this.apiKey = apiKey || process.env.HPKV_API_KEY;

        if (!this.baseUrl) {
            throw new Error('HPKV base URL not provided. Set HPKV_BASE_URL environment variable or pass baseUrl parameter.');
        }
        if (!this.apiKey) {
            throw new Error('HPKV API key not provided. Set HPKV_API_KEY environment variable or pass apiKey parameter.');
        }

        this.headers = {
            'Content-Type': 'application/json',
            'x-api-key': this.apiKey
        };
    }

    _serializeValue(value) {
        if (typeof value === 'string') {
            return value;
        }
        return JSON.stringify(value);
    }

    async create(key, value) {
        try {
            const payload = {
                key,
                value: this._serializeValue(value)
            };

            console.log(\`Sending request to \${this.baseUrl}/record\`);
            console.log('Payload:', JSON.stringify(payload, null, 2));

            const response = await fetch(\`\${this.baseUrl}/record\`, {
                method: 'POST',
                headers: this.headers,
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const error = await response.text();
                console.error(\`Create failed with status \${response.status} - \${error}\`);
                return false;
            }

            console.log(\`Create succeeded with status \${response.status}\`);
            return true;
        } catch (error) {
            console.error('Error creating record:', error.message);
            return false;
        }
    }

    async read(key) {
        try {
            const response = await fetch(\`\${this.baseUrl}/record/\${key}\`, {
                headers: this.headers
            });

            if (!response.ok) {
                const error = await response.text();
                console.error(\`Error in read: Status \${response.status} - \${error}\`);
                return null;
            }

            const data = await response.json();
            if (!data || !data.value) {
                return null;
            }

            try {
                return JSON.parse(data.value);
            } catch {
                return data.value;
            }
        } catch (error) {
            console.error('Error reading record:', error.message);
            return null;
        }
    }

    async update(key, value, partialUpdate = false) {
        try {
            const payload = {
                key,
                value: this._serializeValue(value),
                partialUpdate
            };

            const response = await fetch(\`\${this.baseUrl}/record\`, {
                method: 'POST',
                headers: this.headers,
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const error = await response.text();
                console.error(\`Update failed with status \${response.status} - \${error}\`);
                return false;
            }

            return true;
        } catch (error) {
            console.error('Error updating record:', error.message);
            return false;
        }
    }

    async delete(key) {
        try {
            const response = await fetch(\`\${this.baseUrl}/record/\${key}\`, {
                method: 'DELETE',
                headers: this.headers
            });

            if (!response.ok) {
                const error = await response.text();
                console.error(\`Delete failed with status \${response.status} - \${error}\`);
                return false;
            }

            return true;
        } catch (error) {
            console.error('Error deleting record:', error.message);
            return false;
        }
    }
} `
    },
    Java: {
      language: 'java',
      code: `package io.hpkv.example;

import com.fasterxml.jackson.databind.JsonNode;
import com.fasterxml.jackson.databind.ObjectMapper;
import okhttp3.*;
import java.io.IOException;

public class HPKVClient {
    private final OkHttpClient httpClient;
    private final ObjectMapper objectMapper;
    private final String baseUrl;
    private final String apiKey;

    public HPKVClient(String baseUrl, String apiKey) {
        if (baseUrl == null || baseUrl.trim().isEmpty()) {
            throw new IllegalArgumentException("HPKV base URL not provided. Set HPKV_BASE_URL environment variable or pass baseUrl parameter.");
        }
        if (apiKey == null || apiKey.trim().isEmpty()) {
            throw new IllegalArgumentException("HPKV API key not provided. Set HPKV_API_KEY environment variable or pass apiKey parameter.");
        }

        this.baseUrl = baseUrl.replaceAll("/$", "");
        this.apiKey = apiKey;
        this.httpClient = new OkHttpClient();
        this.objectMapper = new ObjectMapper();
    }

    private String serializeValue(Object value) {
        if (value instanceof String) {
            return (String) value;
        }
        try {
            return objectMapper.writeValueAsString(value);
        } catch (Exception e) {
            throw new RuntimeException("Failed to serialize value", e);
        }
    }

    public boolean create(String key, Object value) {
        try {
            String payload = objectMapper.writeValueAsString(new Record(key, serializeValue(value)));
            
            System.out.println("Sending request to " + baseUrl + "/record");
            System.out.println("Payload: " + objectMapper.writerWithDefaultPrettyPrinter().writeValueAsString(
                objectMapper.readTree(payload)));

            Request request = new Request.Builder()
                .url(baseUrl + "/record")
                .addHeader("Content-Type", "application/json")
                .addHeader("x-api-key", apiKey)
                .post(RequestBody.create(payload, MediaType.parse("application/json")))
                .build();

            try (Response response = httpClient.newCall(request).execute()) {
                if (!response.isSuccessful()) {
                    String error = response.body() != null ? response.body().string() : "";
                    System.err.println("Create failed with status " + response.code() + " - " + error);
                    return false;
                }
                
                System.out.println("Create succeeded with status " + response.code());
                return true;
            }
        } catch (Exception e) {
            System.err.println("Error creating record: " + e.getMessage());
            return false;
        }
    }

    public <T> T read(String key, Class<T> valueType) {
        try {
            Request request = new Request.Builder()
                .url(baseUrl + "/record/" + key)
                .addHeader("Content-Type", "application/json")
                .addHeader("x-api-key", apiKey)
                .build();

            try (Response response = httpClient.newCall(request).execute()) {
                if (!response.isSuccessful()) {
                    String error = response.body() != null ? response.body().string() : "";
                    System.err.println("Error in read: Status " + response.code() + " - " + error);
                    return null;
                }

                String responseBody = response.body().string();
                JsonNode data = objectMapper.readTree(responseBody);
                if (!data.has("value")) {
                    return null;
                }

                String value = data.get("value").asText();
                try {
                    return objectMapper.readValue(value, valueType);
                } catch (Exception e) {
                    return (T) value;
                }
            }
        } catch (Exception e) {
            System.err.println("Error reading record: " + e.getMessage());
            return null;
        }
    }

    public boolean update(String key, Object value, boolean partialUpdate) {
        try {
            String payload = objectMapper.writeValueAsString(
                new Record(key, serializeValue(value), partialUpdate));

            Request request = new Request.Builder()
                .url(baseUrl + "/record")
                .addHeader("Content-Type", "application/json")
                .addHeader("x-api-key", apiKey)
                .post(RequestBody.create(payload, MediaType.parse("application/json")))
                .build();

            try (Response response = httpClient.newCall(request).execute()) {
                if (!response.isSuccessful()) {
                    String error = response.body() != null ? response.body().string() : "";
                    System.err.println("Update failed with status " + response.code() + " - " + error);
                    return false;
                }
                return true;
            }
        } catch (Exception e) {
            System.err.println("Error updating record: " + e.getMessage());
            return false;
        }
    }

    public boolean delete(String key) {
        try {
            Request request = new Request.Builder()
                .url(baseUrl + "/record/" + key)
                .addHeader("Content-Type", "application/json")
                .addHeader("x-api-key", apiKey)
                .delete()
                .build();

            try (Response response = httpClient.newCall(request).execute()) {
                if (!response.isSuccessful()) {
                    String error = response.body() != null ? response.body().string() : "";
                    System.err.println("Delete failed with status " + response.code() + " - " + error);
                    return false;
                }
                return true;
            }
        } catch (Exception e) {
            System.err.println("Error deleting record: " + e.getMessage());
            return false;
        }
    }

    private static class Record {
        public String key;
        public String value;
        public Boolean partialUpdate;

        public Record(String key, String value) {
            this.key = key;
            this.value = value;
        }

        public Record(String key, String value, Boolean partialUpdate) {
            this.key = key;
            this.value = value;
            this.partialUpdate = partialUpdate;
        }
    }
} `
    },
    'Go':{
      language: 'go',
      code:` package main

import (
	"bytes"
	"encoding/json"
	"fmt"
	"io"
	"net/http"
	"strings"
)

type HPKVClient struct {
	baseURL string
	apiKey  string
	client  *http.Client
}

type Record struct {
	Key          string      \`json:"key"\`
	Value        string      \`json:"value"\`
	PartialUpdate *bool      \`json:"partialUpdate,omitempty"\`
}

type Response struct {
	Value string \`json:"value"\`
}

func NewHPKVClient(baseURL, apiKey string) *HPKVClient {
	if baseURL == "" {
		panic("HPKV base URL not provided. Set HPKV_BASE_URL environment variable")
	}
	if apiKey == "" {
		panic("HPKV API key not provided. Set HPKV_API_KEY environment variable")
	}

	return &HPKVClient{
		baseURL: strings.TrimRight(baseURL, "/"),
		apiKey:  apiKey,
		client:  &http.Client{},
	}
}

func (c *HPKVClient) serializeValue(value interface{}) (string, error) {
	if str, ok := value.(string); ok {
		return str, nil
	}
	data, err := json.Marshal(value)
	if err != nil {
		return "", fmt.Errorf("failed to serialize value: %w", err)
	}
	return string(data), nil
}

func (c *HPKVClient) Create(key string, value interface{}) error {
	serializedValue, err := c.serializeValue(value)
	if err != nil {
		return err
	}

	record := Record{
		Key:   key,
		Value: serializedValue,
	}

	payload, err := json.Marshal(record)
	if err != nil {
		return fmt.Errorf("failed to marshal request: %w", err)
	}

	fmt.Printf("Sending request to %s/record", c.baseURL)
	fmt.Printf("Payload: %s", prettyJSON(payload))

	req, err := http.NewRequest(http.MethodPost, fmt.Sprintf("%s/record", c.baseURL), bytes.NewBuffer(payload))
	if err != nil {
		return fmt.Errorf("failed to create request: %w", err)
	}

	req.Header.Set("Content-Type", "application/json")
	req.Header.Set("x-api-key", c.apiKey)

	resp, err := c.client.Do(req)
	if err != nil {
		return fmt.Errorf("failed to send request: %w", err)
	}
	defer resp.Body.Close()

	if resp.StatusCode != http.StatusOK && resp.StatusCode != http.StatusCreated {
		body, _ := io.ReadAll(resp.Body)
		return fmt.Errorf("create failed with status %d: %s", resp.StatusCode, string(body))
	}

	fmt.Printf("Create succeeded with status %d", resp.StatusCode)
	return nil
}

func (c *HPKVClient) Read(key string, value interface{}) error {
	req, err := http.NewRequest(http.MethodGet, fmt.Sprintf("%s/record/%s", c.baseURL, key), nil)
	if err != nil {
		return fmt.Errorf("failed to create request: %w", err)
	}

	req.Header.Set("Content-Type", "application/json")
	req.Header.Set("x-api-key", c.apiKey)

	resp, err := c.client.Do(req)
	if err != nil {
		return fmt.Errorf("failed to send request: %w", err)
	}
	defer resp.Body.Close()

	if resp.StatusCode != http.StatusOK {
		body, _ := io.ReadAll(resp.Body)
		return fmt.Errorf("read failed with status %d: %s", resp.StatusCode, string(body))
	}

	var response Response
	if err := json.NewDecoder(resp.Body).Decode(&response); err != nil {
		return fmt.Errorf("failed to decode response: %w", err)
	}

	if err := json.Unmarshal([]byte(response.Value), value); err != nil {
		return fmt.Errorf("failed to unmarshal value: %w", err)
	}

	return nil
}

func (c *HPKVClient) Update(key string, value interface{}, partialUpdate bool) error {
	serializedValue, err := c.serializeValue(value)
	if err != nil {
		return err
	}

	record := Record{
		Key:          key,
		Value:        serializedValue,
		PartialUpdate: &partialUpdate,
	}

	payload, err := json.Marshal(record)
	if err != nil {
		return fmt.Errorf("failed to marshal request: %w", err)
	}

	req, err := http.NewRequest(http.MethodPost, fmt.Sprintf("%s/record", c.baseURL), bytes.NewBuffer(payload))
	if err != nil {
		return fmt.Errorf("failed to create request: %w", err)
	}

	req.Header.Set("Content-Type", "application/json")
	req.Header.Set("x-api-key", c.apiKey)

	resp, err := c.client.Do(req)
	if err != nil {
		return fmt.Errorf("failed to send request: %w", err)
	}
	defer resp.Body.Close()

	if resp.StatusCode != http.StatusOK {
		body, _ := io.ReadAll(resp.Body)
		return fmt.Errorf("update failed with status %d: %s", resp.StatusCode, string(body))
	}

	return nil
}

func (c *HPKVClient) Delete(key string) error {
	req, err := http.NewRequest(http.MethodDelete, fmt.Sprintf("%s/record/%s", c.baseURL, key), nil)
	if err != nil {
		return fmt.Errorf("failed to create request: %w", err)
	}

	req.Header.Set("Content-Type", "application/json")
	req.Header.Set("x-api-key", c.apiKey)

	resp, err := c.client.Do(req)
	if err != nil {
		return fmt.Errorf("failed to send request: %w", err)
	}
	defer resp.Body.Close()

	if resp.StatusCode != http.StatusOK {
		body, _ := io.ReadAll(resp.Body)
		return fmt.Errorf("delete failed with status %d: %s", resp.StatusCode, string(body))
	}

	return nil
}

func prettyJSON(data []byte) string {
	var prettyJSON bytes.Buffer
	if err := json.Indent(&prettyJSON, data, "", "  "); err != nil {
		return string(data)
	}
	return prettyJSON.String()
}`
    },
    'C#': {
      language: 'csharp',
      code: `using System;
using System.Net.Http;
using System.Net.Http.Json;
using System.Text.Json;
using System.Threading.Tasks;

namespace HPKVExample
{
    public class HPKVClient
    {
        private readonly HttpClient _httpClient;
        private readonly string _baseUrl;
        private readonly string _apiKey;

        public HPKVClient(string? baseUrl = null, string? apiKey = null)
        {
            // Load from environment variables if not provided
            var envBaseUrl = baseUrl ?? Environment.GetEnvironmentVariable("HPKV_BASE_URL");
            var envApiKey = apiKey ?? Environment.GetEnvironmentVariable("HPKV_API_KEY");

            if (string.IsNullOrEmpty(envBaseUrl))
                throw new ArgumentException("HPKV base URL not provided. Set HPKV_BASE_URL environment variable or pass baseUrl parameter.");
            if (string.IsNullOrEmpty(envApiKey))
                throw new ArgumentException("HPKV API key not provided. Set HPKV_API_KEY environment variable or pass apiKey parameter.");

            _baseUrl = envBaseUrl.TrimEnd('/');
            _apiKey = envApiKey;
            _httpClient = new HttpClient();
            _httpClient.DefaultRequestHeaders.Add("x-api-key", _apiKey);
            _httpClient.DefaultRequestHeaders.Add("Accept", "application/json");
        }

        private string SerializeValue<T>(T value)
        {
            if (value is string strValue)
                return strValue;
            return JsonSerializer.Serialize(value);
        }

        public async Task<bool> CreateAsync<T>(string key, T value)
        {
            try
            {
                var payload = new
                {
                    key = key,
                    value = SerializeValue(value)
                };

                Console.WriteLine($"Sending request to {_baseUrl}/record");
                Console.WriteLine($"Payload: {JsonSerializer.Serialize(payload, new JsonSerializerOptions { WriteIndented = true })}");

                var response = await _httpClient.PostAsJsonAsync($"{_baseUrl}/record", payload);
                
                if (!response.IsSuccessStatusCode)
                {
                    var error = await response.Content.ReadAsStringAsync();
                    Console.Error.WriteLine($"Create failed with status {response.StatusCode} - {error}");
                    return false;
                }

                Console.WriteLine($"Create succeeded with status {response.StatusCode}");
                return true;
            }
            catch (Exception ex)
            {
                Console.Error.WriteLine($"Error creating record: {ex.Message}");
                return false;
            }
        }

        public async Task<T?> ReadAsync<T>(string key)
        {
            try
            {
                var response = await _httpClient.GetAsync($"{_baseUrl}/record/{key}");
                if (!response.IsSuccessStatusCode)
                {
                    var error = await response.Content.ReadAsStringAsync();
                    Console.Error.WriteLine($"Error in read: Status {response.StatusCode} - {error}");
                    return default;
                }

                var data = await response.Content.ReadFromJsonAsync<RecordResponse>();
                if (data?.Value == null)
                    return default;

                try
                {
                    return JsonSerializer.Deserialize<T>(data.Value);
                }
                catch
                {
                    return (T)Convert.ChangeType(data.Value, typeof(T));
                }
            }
            catch (Exception ex)
            {
                Console.Error.WriteLine($"Error reading record: {ex.Message}");
                return default;
            }
        }

        public async Task<bool> UpdateAsync<T>(string key, T value, bool partialUpdate = false)
        {
            try
            {
                var payload = new
                {
                    key = key,
                    value = SerializeValue(value),
                    partialUpdate = partialUpdate
                };

                var response = await _httpClient.PostAsJsonAsync($"{_baseUrl}/record", payload);
                if (!response.IsSuccessStatusCode)
                {
                    var error = await response.Content.ReadAsStringAsync();
                    Console.Error.WriteLine($"Update failed with status {response.StatusCode} - {error}");
                    return false;
                }

                return true;
            }
            catch (Exception ex)
            {
                Console.Error.WriteLine($"Error updating record: {ex.Message}");
                return false;
            }
        }

        public async Task<bool> DeleteAsync(string key)
        {
            try
            {
                var response = await _httpClient.DeleteAsync($"{_baseUrl}/record/{key}");
                if (!response.IsSuccessStatusCode)
                {
                    var error = await response.Content.ReadAsStringAsync();
                    Console.Error.WriteLine($"Delete failed with status {response.StatusCode} - {error}");
                    return false;
                }

                return true;
            }
            catch (Exception ex)
            {
                Console.Error.WriteLine($"Error deleting record: {ex.Message}");
                return false;
            }
        }

        private class RecordResponse
        {
            public string Value { get; set; } = string.Empty;
        }
    }
} `
    }
  };

  const atomicIncrementSnippets = {
    Python: {
      language: 'python',
      code: `import os
import requests
import json
from typing import Dict, Any, Optional
from dotenv import load_dotenv

def atomic_increment(key: str, increment: int) -> Dict[str, Any]:
    """
    Perform an atomic increment operation on a key in HPKV.
    If the key doesn't exist, it will be created with an initial value of 0.
    
    Args:
        key (str): The key to increment
        increment (int): The value to add (positive) or subtract (negative)
    
    Returns:
        Dict[str, Any]: Response from the HPKV API
    
    Raises:
        ValueError: If the API request fails or returns an error
    """
    HPKV_BASE_URL = os.getenv("HPKV_BASE_URL")
    HPKV_API_KEY = os.getenv("HPKV_API_KEY")

    try:
        # First, try to increment the key
        payload = {
            "key": key,
            "increment": increment
        }
        
        response = requests.post(
            f"{HPKV_BASE_URL}/record/atomic",
            headers={
                "Content-Type": "application/json",
                "x-api-key": HPKV_API_KEY
            },
            json=payload,
        )
             
        # Raise an exception for bad status codes
        response.raise_for_status()
        
        result = response.json()
        
        # Check if the API returned an error message
        if not result.get("success", False):
            error_msg = result.get("message", "Unknown error")
            raise ValueError(f"HPKV API error: {error_msg}")
            
        return result
        
    except requests.exceptions.RequestException as e:
        raise ValueError(f"Failed to connect to HPKV API: {str(e)}")
    except ValueError as e:
        raise ValueError(f"Invalid response from HPKV API: {str(e)}")`
    },
    JavaScript: {
      language: 'javascript',
      code: `import axios from 'axios';
async function atomicIncrement(key, increment) {
    if (!HPKV_BASE_URL || !HPKV_API_KEY) {
        throw new Error('HPKV_BASE_URL and HPKV_API_KEY must be set in environment variables');
    }

    try {
        const payload = {
            key,
            increment
        };

        const response = await axios.post(
            \`\${HPKV_BASE_URL}/record/atomic\`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': HPKV_API_KEY
                }
            }
        );
           
        return response.data;
    } catch (error) {
        throw new Error(\`Failed to connect to HPKV API: \${error.message}\`);
    }
}`
    },
    Go: {
      language: 'go',
      code: `func atomicIncrement(key string, increment int) (map[string]interface{}, error) {
	if hpkvBaseURL == "" || hpkvAPIKey == "" {
		return nil, fmt.Errorf("HPKV_BASE_URL and HPKV_API_KEY must be set in environment variables")
	}

	payload := map[string]interface{}{
		"key":       key,
		"increment": increment,
	}

	jsonData, err := json.Marshal(payload)
	if err != nil {
		return nil, fmt.Errorf("error marshaling payload: %v", err)
	}

	fmt.Printf("Attempting atomic increment with payload: %s\n", string(jsonData))

	req, err := http.NewRequest("POST", fmt.Sprintf("%s/record/atomic", hpkvBaseURL), bytes.NewBuffer(jsonData))
	if err != nil {
		return nil, fmt.Errorf("error creating request: %v", err)
	}

	req.Header.Set("Content-Type", "application/json")
	req.Header.Set("x-api-key", hpkvAPIKey)

	client := &http.Client{}
	resp, err := client.Do(req)
	if err != nil {
		return nil, fmt.Errorf("error making request: %v", err)
	}
	defer resp.Body.Close()

	body, err := io.ReadAll(resp.Body)
	if err != nil {
		return nil, fmt.Errorf("error reading response body: %v", err)
	}

	if resp.StatusCode != http.StatusOK {
		return nil, fmt.Errorf("request failed with status %d: %s", resp.StatusCode, string(body))
	}

	var result map[string]interface{}
	if err := json.Unmarshal(body, &result); err != nil {
		return nil, fmt.Errorf("error unmarshaling response: %v", err)
	}

	if success, ok := result["success"].(bool); !ok || !success {
		message := "Unknown error"
		if msg, ok := result["message"].(string); ok {
			message = msg
		}
		return nil, fmt.Errorf("HPKV API error: %s", message)
	}

	return result, nil
}`
    },
    'C#': {
      language: 'csharp',
      code: `private static async Task<JsonDocument> AtomicIncrement(string key, int increment)
    {
        if (string.IsNullOrEmpty(HpkvBaseUrl) || string.IsNullOrEmpty(HpkvApiKey))
        {
            throw new ArgumentException("HPKV_BASE_URL and HPKV_API_KEY must be set in environment variables");
        }

        try
        {
            var payload = new
            {
                key = key,
                increment = increment
            };

            Console.WriteLine($"Attempting atomic increment with payload: {JsonSerializer.Serialize(payload)}");

            using var client = new HttpClient();
            client.DefaultRequestHeaders.Add("x-api-key", HpkvApiKey);

            var content = new StringContent(
                JsonSerializer.Serialize(payload),
                Encoding.UTF8,
                "application/json"
            );

            var response = await client.PostAsync($"{HpkvBaseUrl}/record/atomic", content);
            var responseContent = await response.Content.ReadAsStringAsync();

            response.EnsureSuccessStatusCode();
            var result = JsonDocument.Parse(responseContent);

            if (!result.RootElement.GetProperty("success").GetBoolean())
            {
                var errorMsg = result.RootElement.GetProperty("message").GetString() ?? "Unknown error";
                throw new Exception($"HPKV API error: {errorMsg}");
            }

            return result;
        }
        catch (HttpRequestException ex)
        {
            throw new Exception($"Failed to connect to HPKV API: {ex.Message}");
        }
        catch (Exception ex)
        {
            throw new Exception($"Invalid response from HPKV API: {ex.Message}");
        }
    }`
    }
  };

  const rangeQuerySnippets = {
    Python: {
      language: 'python',
      code: `    def perform_range_query(self, start_key: str, end_key: str, limit: Optional[int] = None) -> Dict[str, Any]:
        """
        Perform a range query to retrieve records within a specified key range.
        
        Args:
            start_key: Starting key for the range (inclusive)
            end_key: Ending key for the range (inclusive)
            limit: Maximum number of records to return (optional)
            
        Returns:
            Dictionary containing the response data
        """
        params = {
            "startKey": start_key,
            "endKey": end_key
        }
        
        if limit is not None:
            params["limit"] = limit

        response = requests.get(
            f"{self.base_url}/records",
            headers=self._get_headers(),
            params=params
        )
        response.raise_for_status()
        
        return response.json()`
    },
    JavaScript: {
      language: 'javascript',
      code: ` async performRangeQuery(startKey, endKey, limit = null) {
        const params = {
            startKey,
            endKey
        };
        
        if (limit !== null) {
            params.limit = limit;
        }

        try {
            const response = await axios.get(
                \`\${this.baseUrl}/records\`,
                {
                    headers: this._getHeaders(),
                    params
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error performing range query:', error.message);
            throw error;
        }
    }`
    },
    Go: {
      language: 'go',
      code: `func (e *HPKVRangeQueriesExample) performRangeQuery(startKey, endKey string, limit *int) (map[string]interface{}, error) {
	params := url.Values{}
	params.Set("startKey", startKey)
	params.Set("endKey", endKey)
	if limit != nil {
		params.Set("limit", fmt.Sprintf("%d", *limit))
	}

	req, err := http.NewRequest("GET", e.baseURL+"/records?"+params.Encode(), nil)
	if err != nil {
		return nil, fmt.Errorf("error creating request: %v", err)
	}

	for key, value := range e.getHeaders() {
		req.Header.Set(key, value)
	}

	resp, err := e.client.Do(req)
	if err != nil {
		return nil, fmt.Errorf("error performing range query: %v", err)
	}
	defer resp.Body.Close()

	if resp.StatusCode != http.StatusOK {
		body, _ := io.ReadAll(resp.Body)
		return nil, fmt.Errorf("error performing range query: status code %d, body: %s", resp.StatusCode, string(body))
	}

	body, err := io.ReadAll(resp.Body)
	if err != nil {
		return nil, fmt.Errorf("error reading response body: %v", err)
	}

	var result map[string]interface{}
	if err := json.Unmarshal(body, &result); err != nil {
		return nil, fmt.Errorf("error unmarshaling response: %v", err)
	}

	return result, nil
}
`
    },
    'C#': {
      language: 'csharp',
      code: `public async Task<Dictionary<string, object>> PerformRangeQueryAsync(string startKey, string endKey, int? limit = null)
        {
            var queryParams = new List<string>
            {
                $"startKey={Uri.EscapeDataString(startKey)}",
                $"endKey={Uri.EscapeDataString(endKey)}"
            };

            if (limit.HasValue)
            {
                queryParams.Add($"limit={limit.Value}");
            }

            try
            {
                var response = await _httpClient.GetAsync($"{_baseUrl}/records?{string.Join("&", queryParams)}");
                if (!response.IsSuccessStatusCode)
                {
                    var errorBody = await response.Content.ReadAsStringAsync();
                    throw new HttpRequestException($"Error performing range query. Status: {response.StatusCode}, Body: {errorBody}");
                }
                var content = await response.Content.ReadAsStringAsync();
                return JsonSerializer.Deserialize<Dictionary<string, object>>(content) ?? throw new JsonException("Failed to deserialize response");
            }
            catch (Exception ex)
            {
                Console.WriteLine($"Error performing range query: {ex.Message}");
                throw;
            }
        }`
    }
  };

  const websocketSnippets = {
    Python: {
      language: 'python',
      code: `import os
import sys
import json
import asyncio
import websockets
import ssl
from typing import Any, Dict, Optional, Union
from enum import Enum

class OperationCode(Enum):
    """Enumeration of HPKV WebSocket operation codes."""
    GET = 1
    INSERT = 2
    UPDATE = 3
    DELETE = 4

class HPKVWebSocketClient:
    def __init__(self, base_url: str = None, api_key: str = None):
        """Initialize HPKV WebSocket client with API key.
        
        Args:
            base_url: HPKV server URL (optional, defaults to HPKV_BASE_URL env var)
            api_key: HPKV API key (optional, defaults to HPKV_API_KEY env var)
        """
        # Load environment variables if not already loaded
        if not os.getenv('HPKV_BASE_URL') and not os.getenv('HPKV_API_KEY'):
            load_dotenv()
            
        self.base_url = (base_url or os.getenv('HPKV_BASE_URL')).rstrip('/') if (base_url or os.getenv('HPKV_BASE_URL')) else None
        self.api_key = api_key or os.getenv('HPKV_API_KEY')
        
        if not self.base_url:
            raise ValueError("HPKV base URL not provided. Set HPKV_BASE_URL environment variable or pass base_url parameter.")
        if not self.api_key:
            raise ValueError("HPKV API key not provided. Set HPKV_API_KEY environment variable or pass api_key parameter.")
        
        # Convert HTTP URL to WebSocket URL and add API key as query parameter
        self.ws_url = f"{self.base_url.replace('http://', 'ws://').replace('https://', 'wss://')}/ws?apiKey={self.api_key}"
        self.message_id = 0
        self.response_futures = {}
        self.websocket = None
        self.message_handler_task = None
        
        # Create SSL context
        self.ssl_context = ssl.create_default_context()
        self.ssl_context.check_hostname = False
        self.ssl_context.verify_mode = ssl.CERT_NONE
        
    def _get_next_message_id(self) -> int:
        """Get next available message ID."""
        self.message_id += 1
        return self.message_id
    
    def _serialize_value(self, value: Any) -> str:
        """Serialize value to string format."""
        if isinstance(value, str):
            return value
        return json.dumps(value)
    
    async def _handle_message(self, message: str):
        """Handle incoming WebSocket message."""
        try:
            response = json.loads(message)
            message_id = response.get('messageId')
            if message_id in self.response_futures:
                future = self.response_futures.pop(message_id)
                if 'error' in response:
                    future.set_exception(Exception(response['error']))
                else:
                    future.set_result(response)
        except Exception as e:
            print(f"Error handling message: {str(e)}", file=sys.stderr)
    
    async def _message_handler(self):
        """Handle incoming WebSocket messages."""
        try:
            async for message in self.websocket:
                await self._handle_message(message)
        except websockets.exceptions.ConnectionClosed:
            print("WebSocket connection closed", file=sys.stderr)
        except Exception as e:
            print(f"Error in message handler: {str(e)}", file=sys.stderr)
    
    async def connect(self):
        """Establish WebSocket connection."""
        if not self.websocket:
            self.websocket = await websockets.connect(
                self.ws_url,
                ssl=self.ssl_context
            )
            self.message_handler_task = asyncio.create_task(self._message_handler())
    
    async def disconnect(self):
        """Close WebSocket connection."""
        if self.message_handler_task:
            self.message_handler_task.cancel()
            try:
                await self.message_handler_task
            except asyncio.CancelledError:
                pass
        if self.websocket:
            await self.websocket.close()
            self.websocket = None
        self.message_handler_task = None
    
    async def _send_message(self, message: Dict) -> Any:
        """Send message and wait for response."""
        if not self.websocket:
            await self.connect()
            
        message_id = self._get_next_message_id()
        message['messageId'] = message_id
        
        future = asyncio.Future()
        self.response_futures[message_id] = future
        
        try:
            await self.websocket.send(json.dumps(message))
            return await future
        except Exception as e:
            print(f"Error sending message: {str(e)}", file=sys.stderr)
            raise
    
    async def create(self, key: str, value: Any) -> bool:
        """Create a new key-value pair."""
        try:
            message = {
                "op": OperationCode.INSERT.value,  # Insert operation
                "key": key,
                "value": self._serialize_value(value)
            }
            
            response = await self._send_message(message)
            return 'error' not in response
                
        except Exception as e:
            print(f"Error creating record: {str(e)}", file=sys.stderr)
            return False

    async def read(self, key: str) -> Optional[Any]:
        """Read a value by key."""
        try:
            message = {
                "op": OperationCode.GET.value,  # Get operation
                "key": key
            }
            
            response = await self._send_message(message)
            if 'error' in response:
                return None
                
            try:
                return json.loads(response['value'])
            except (json.JSONDecodeError, TypeError):
                return response['value']
                
        except Exception as e:
            print(f"Error reading record: {str(e)}", file=sys.stderr)
            return None

    async def update(self, key: str, value: Any, partial_update: bool = False) -> bool:
        """Update an existing key-value pair."""
        try:
            message = {
                "op": OperationCode.UPDATE.value if partial_update else OperationCode.INSERT.value,  # Update or Insert operation
                "key": key,
                "value": self._serialize_value(value)
            }
            
            response = await self._send_message(message)
            return 'error' not in response
                
        except Exception as e:
            print(f"Error updating record: {str(e)}", file=sys.stderr)
            return False

    async def delete(self, key: str) -> bool:
        """Delete a key-value pair."""
        try:
            message = {
                "op": OperationCode.DELETE.value,  # Delete operation
                "key": key
            }
            
            response = await self._send_message(message)
            return 'error' not in response
                
        except Exception as e:
            print(f"Error deleting record: {str(e)}", file=sys.stderr)
            return False`
    },
    JavaScript: {
      language: 'javascript',
      code: `const WebSocket = require('ws');

const OperationCode = Object.freeze({
    GET: 1,
    INSERT: 2,
    UPDATE: 3,
    DELETE: 4
});

class HPKVWebSocketClient {
    constructor(baseUrl, apiKey) {
        this.baseUrl = baseUrl.replace(/^http/, 'ws');
        this.apiKey = apiKey;
        this.messageId = 0;
        this.responseFutures = new Map();
        this.ws = null;
        this.connect();
    }

    connect() {
        return new Promise((resolve, reject) => {
            try {
                this.ws = new WebSocket(\`\${this.baseUrl}/ws?apiKey=\${this.apiKey}\`);
                
                this.ws.on('open', () => {
                    console.log('WebSocket connection established');
                    resolve();
                });

                this.ws.on('message', (data) => {
                    try {
                        const response = JSON.parse(data);
                        const msgId = response.messageId;
                        if (msgId && this.responseFutures.has(msgId)) {
                            const { resolve, reject } = this.responseFutures.get(msgId);
                            this.responseFutures.delete(msgId);
                            
                            if (response.error) {
                                reject(new Error(response.error));
                            } else {
                                resolve(response);
                            }
                        }
                    } catch (error) {
                        console.error('Error handling message:', error);
                    }
                });

                this.ws.on('close', (code, reason) => {
                    console.log(\`WebSocket connection closed: \${code} - \${reason}\`);
                });

                this.ws.on('error', (error) => {
                    console.error('WebSocket error:', error);
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }

    async sendMessage(message) {
        if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
            await this.connect();
        }

        return new Promise((resolve, reject) => {
            try {
                const msgId = ++this.messageId;
                message.messageId = msgId;
                
                this.responseFutures.set(msgId, { resolve, reject });
                this.ws.send(JSON.stringify(message));
            } catch (error) {
                reject(error);
            }
        });
    }

    async create(key, value) {
        try {
            const message = {
                op: OperationCode.INSERT,
                key,
                value: typeof value === 'string' ? value : JSON.stringify(value)
            };
            
            const response = await this.sendMessage(message);
            return !response.error;
        } catch (error) {
            console.error('Error creating record:', error);
            return false;
        }
    }

    async read(key) {
        try {
            const message = {
                op: OperationCode.GET,
                key
            };
            
            const response = await this.sendMessage(message);
            if (response.error) {
                return null;
            }
            
            try {
                return JSON.parse(response.value);
            } catch {
                return response.value;
            }
        } catch (error) {
            console.error('Error reading record:', error);
            return null;
        }
    }

    async update(key, value, partialUpdate = false) {
        try {
            const message = {
                op: partialUpdate ? OperationCode.UPDATE : OperationCode.INSERT,
                key,
                value: typeof value === 'string' ? value : JSON.stringify(value)
            };
            
            const response = await this.sendMessage(message);
            return !response.error;
        } catch (error) {
            console.error('Error updating record:', error);
            return false;
        }
    }

    async delete(key) {
        try {
            const message = {
                op: OperationCode.DELETE,
                key
            };
            
            const response = await this.sendMessage(message);
            return !response.error;
        } catch (error) {
            console.error('Error deleting record:', error);
            return false;
        }
    }
}

module.exports = HPKVWebSocketClient; `
    },
    Java: {
      language: 'java',
      code: `import com.fasterxml.jackson.databind.ObjectMapper;
import org.java_websocket.client.WebSocketClient;
import org.java_websocket.handshake.ServerHandshake;
import org.slf4j.Logger;
import org.slf4j.LoggerFactory;

import java.net.URI;
import java.util.Map;
import java.util.concurrent.CompletableFuture;
import java.util.concurrent.ConcurrentHashMap;
import java.util.concurrent.atomic.AtomicInteger;

public class HPKVWebSocketClient extends WebSocketClient {
    private static final Logger logger = LoggerFactory.getLogger(HPKVWebSocketClient.class);
    private final ObjectMapper objectMapper;
    private final AtomicInteger messageId;
    private final Map<Integer, CompletableFuture<Map<String, Object>>> responseFutures;

    public enum OperationCode {
        GET(1),
        INSERT(2),
        UPDATE(3),
        DELETE(4);

        private final int value;

        OperationCode(int value) {
            this.value = value;
        }

        public int getValue() {
            return value;
        }
    }

    public HPKVWebSocketClient(String serverUri, String apiKey) throws Exception {
        super(new URI(serverUri + "/ws?apiKey=" + apiKey));
        this.objectMapper = new ObjectMapper();
        this.messageId = new AtomicInteger(0);
        this.responseFutures = new ConcurrentHashMap<>();
    }

    @Override
    public void onOpen(ServerHandshake handshakedata) {
        logger.info("WebSocket connection established");
    }

    @Override
    public void onMessage(String message) {
        try {
            Map<String, Object> response = objectMapper.readValue(message, Map.class);
            Integer msgId = (Integer) response.get("messageId");
            if (msgId != null && responseFutures.containsKey(msgId)) {
                CompletableFuture<Map<String, Object>> future = responseFutures.remove(msgId);
                if (response.containsKey("error")) {
                    future.completeExceptionally(new Exception((String) response.get("error")));
                } else {
                    future.complete(response);
                }
            }
        } catch (Exception e) {
            logger.error("Error handling message: {}", e.getMessage());
        }
    }

    @Override
    public void onClose(int code, String reason, boolean remote) {
        logger.info("WebSocket connection closed: {} - {}", code, reason);
    }

    @Override
    public void onError(Exception ex) {
        logger.error("WebSocket error: {}", ex.getMessage());
    }

    private CompletableFuture<Map<String, Object>> sendMessage(Map<String, Object> message) {
        try {
            int msgId = messageId.incrementAndGet();
            message.put("messageId", msgId);
            
            CompletableFuture<Map<String, Object>> future = new CompletableFuture<>();
            responseFutures.put(msgId, future);
            
            send(objectMapper.writeValueAsString(message));
            return future;
        } catch (Exception e) {
            CompletableFuture<Map<String, Object>> future = new CompletableFuture<>();
            future.completeExceptionally(e);
            return future;
        }
    }

    public CompletableFuture<Boolean> create(String key, Object value) {
        try {
            Map<String, Object> message = Map.of(
                "op", OperationCode.INSERT.getValue(),
                "key", key,
                "value", value instanceof String ? value : objectMapper.writeValueAsString(value)
            );
            
            return sendMessage(message)
                .thenApply(response -> !response.containsKey("error"));
        } catch (Exception e) {
            CompletableFuture<Boolean> future = new CompletableFuture<>();
            future.completeExceptionally(e);
            return future;
        }
    }

    public CompletableFuture<Object> read(String key) {
        Map<String, Object> message = Map.of(
            "op", OperationCode.GET.getValue(),
            "key", key
        );
        
        return sendMessage(message)
            .thenApply(response -> {
                if (response.containsKey("error")) {
                    return null;
                }
                try {
                    return objectMapper.readValue((String) response.get("value"), Object.class);
                } catch (Exception e) {
                    return response.get("value");
                }
            });
    }

    public CompletableFuture<Boolean> update(String key, Object value, boolean partialUpdate) {
        try {
            Map<String, Object> message = Map.of(
                "op", partialUpdate ? OperationCode.UPDATE.getValue() : OperationCode.INSERT.getValue(),
                "key", key,
                "value", value instanceof String ? value : objectMapper.writeValueAsString(value)
            );
            
            return sendMessage(message)
                .thenApply(response -> !response.containsKey("error"));
        } catch (Exception e) {
            CompletableFuture<Boolean> future = new CompletableFuture<>();
            future.completeExceptionally(e);
            return future;
        }
    }

    public CompletableFuture<Boolean> delete(String key) {
        Map<String, Object> message = Map.of(
            "op", OperationCode.DELETE.getValue(),
            "key", key
        );
        
        return sendMessage(message)
            .thenApply(response -> !response.containsKey("error"));
    }
} `
    },
    'Go': {
      language: 'go',
      code: `package main

import (
	"encoding/json"
	"fmt"
	"log"
	"strings"
	"sync"
	"sync/atomic"

	"github.com/gorilla/websocket"
)

type OperationCode int

const (
	Get OperationCode = iota + 1
	Insert
	Update
	Delete
)

type HPKVWebSocketClient struct {
	conn            *websocket.Conn
	url             string
	messageID       uint64
	responseFutures sync.Map
}

type Message struct {
	Op        OperationCode \`json:"op"\`
	Key       string        \`json:"key"\`
	Value     interface{}   \`json:"value,omitempty"\`
	MessageID uint64        \`json:"messageId"\`
}

type Response struct {
	MessageID uint64      \`json:"messageId"\`
	Value     interface{} \`json:"value,omitempty"\`
	Error     string      \`json:"error,omitempty"\`
}

type ResponseFuture struct {
	Response chan Response
	Error    chan error
}

func NewHPKVWebSocketClient(baseURL, apiKey string) (*HPKVWebSocketClient, error) {
	// Convert HTTP/HTTPS URL to WS/WSS URL
	wsURL := baseURL
	if strings.HasPrefix(baseURL, "https://") {
		wsURL = "wss://" + strings.TrimPrefix(baseURL, "https://")
	} else if strings.HasPrefix(baseURL, "http://") {
		wsURL = "ws://" + strings.TrimPrefix(baseURL, "http://")
	} else if !strings.HasPrefix(baseURL, "ws://") && !strings.HasPrefix(baseURL, "wss://") {
		wsURL = "wss://" + baseURL
	}

	wsURL = fmt.Sprintf("%s/ws?apiKey=%s", wsURL, apiKey)
	conn, _, err := websocket.DefaultDialer.Dial(wsURL, nil)

	if err != nil {
		return nil, fmt.Errorf("failed to connect: %v", err)
	}

	client := &HPKVWebSocketClient{
		conn:      conn,
		url:       wsURL,
		messageID: 0,
	}

	go client.handleMessages()

	return client, nil
}

func (c *HPKVWebSocketClient) handleMessages() {
	for {
		var response Response
		err := c.conn.ReadJSON(&response)
		if err != nil {
			log.Printf("Error reading message: %v", err)
			return
		}

		if future, ok := c.responseFutures.LoadAndDelete(response.MessageID); ok {
			f := future.(*ResponseFuture)
			if response.Error != "" {
				f.Error <- fmt.Errorf(response.Error)
			} else {
				f.Response <- response
			}
		}
	}
}

func (c *HPKVWebSocketClient) sendMessage(message Message) (*Response, error) {
	messageID := atomic.AddUint64(&c.messageID, 1)
	message.MessageID = messageID

	future := &ResponseFuture{
		Response: make(chan Response, 1),
		Error:    make(chan error, 1),
	}
	c.responseFutures.Store(messageID, future)

	err := c.conn.WriteJSON(message)
	if err != nil {
		return nil, fmt.Errorf("failed to send message: %v", err)
	}

	select {
	case response := <-future.Response:
		return &response, nil
	case err := <-future.Error:
		return nil, err
	}
}

func (c *HPKVWebSocketClient) Create(key string, value interface{}) error {
	// Convert value to JSON string if it's not already a string
	var jsonValue interface{}
	switch v := value.(type) {
	case string:
		jsonValue = v
	default:
		jsonBytes, err := json.Marshal(v)
		if err != nil {
			return fmt.Errorf("failed to marshal value: %v", err)
		}
		jsonValue = string(jsonBytes)
	}

	message := Message{
		Op:    Insert,
		Key:   key,
		Value: jsonValue,
	}

	response, err := c.sendMessage(message)
	if err != nil {
		return err
	}

	if response.Error != "" {
		return fmt.Errorf(response.Error)
	}

	return nil
}

func (c *HPKVWebSocketClient) Read(key string) (interface{}, error) {
	message := Message{
		Op:  Get,
		Key: key,
	}

	response, err := c.sendMessage(message)
	if err != nil {
		return nil, err
	}

	if response.Error != "" {
		return nil, fmt.Errorf(response.Error)
	}

	// Handle string values that might be JSON
	if strValue, ok := response.Value.(string); ok {
		var jsonValue interface{}
		if err := json.Unmarshal([]byte(strValue), &jsonValue); err == nil {
			return jsonValue, nil
		}
	}

	return response.Value, nil
}

func (c *HPKVWebSocketClient) Update(key string, value interface{}, partialUpdate bool) error {
	// For partial updates, first read the existing value
	var existingValue interface{}
	if partialUpdate {
		var err error
		existingValue, err = c.Read(key)
		if err != nil {
			return fmt.Errorf("failed to read existing value: %v", err)
		}

		// Merge the existing value with the new value
		if existingMap, ok := existingValue.(map[string]interface{}); ok {
			if newMap, ok := value.(map[string]interface{}); ok {
				for k, v := range newMap {
					existingMap[k] = v
				}
				value = existingMap
			}
		}
	}

	// Convert value to JSON string if it's not already a string
	var jsonValue interface{}
	switch v := value.(type) {
	case string:
		jsonValue = v
	default:
		jsonBytes, err := json.Marshal(v)
		if err != nil {
			return fmt.Errorf("failed to marshal value: %v", err)
		}
		jsonValue = string(jsonBytes)
	}

	message := Message{
		Op:    Insert, // Always use full update
		Key:   key,
		Value: jsonValue,
	}

	response, err := c.sendMessage(message)
	if err != nil {
		return err
	}

	if response.Error != "" {
		return fmt.Errorf(response.Error)
	}

	return nil
}

func (c *HPKVWebSocketClient) Delete(key string) error {
	message := Message{
		Op:  Delete,
		Key: key,
	}

	response, err := c.sendMessage(message)
	if err != nil {
		return err
	}

	if response.Error != "" {
		return fmt.Errorf(response.Error)
	}

	return nil
}

func (c *HPKVWebSocketClient) Close() error {
	return c.conn.Close()
}`
    },
    'C#': {
      language: 'csharp',
      code: `using System;
using System.Collections.Concurrent;
using System.Net.WebSockets;
using System.Text;
using System.Text.Json;
using System.Threading;
using System.Threading.Tasks;

namespace HPKV.Examples.WebSocket
{
    public enum OperationCode
    {
        Get = 1,
        Insert = 2,
        Update = 3,
        Delete = 4
    }

    public class HPKVWebSocketClient : IDisposable
    {
        private readonly ClientWebSocket _webSocket;
        private readonly string _url;
        private ulong _messageId;
        private readonly Dictionary<ulong, TaskCompletionSource<JsonElement>> _responseFutures;
        private readonly CancellationTokenSource _cts;
        private Task? _receiveTask;

        public HPKVWebSocketClient(string baseUrl, string apiKey)
        {
            baseUrl = baseUrl.Replace("http://", "ws://").Replace("https://", "wss://");
            if (!baseUrl.StartsWith("ws://") && !baseUrl.StartsWith("wss://"))
            {
                baseUrl = "wss://" + baseUrl;
            }
            _url = $"{baseUrl}/ws?apiKey={apiKey}";
            _webSocket = new ClientWebSocket();
            _messageId = 0;
            _responseFutures = new Dictionary<ulong, TaskCompletionSource<JsonElement>>();
            _cts = new CancellationTokenSource();
        }

        public async Task ConnectAsync()
        {
            await _webSocket.ConnectAsync(new Uri(_url), _cts.Token);
            _receiveTask = ReceiveLoop();
            Console.WriteLine("WebSocket connection established");
        }

        private async Task ReceiveLoop()
        {
            var buffer = new byte[4096];
            try
            {
                while (!_cts.Token.IsCancellationRequested)
                {
                    var result = await _webSocket.ReceiveAsync(new ArraySegment<byte>(buffer), _cts.Token);
                    if (result.MessageType == WebSocketMessageType.Close)
                    {
                        await _webSocket.CloseAsync(WebSocketCloseStatus.NormalClosure, string.Empty, _cts.Token);
                        break;
                    }

                    var message = Encoding.UTF8.GetString(buffer, 0, result.Count);
                    var response = JsonSerializer.Deserialize<JsonElement>(message);
                    
                    if (response.TryGetProperty("messageId", out var messageIdElement))
                    {
                        var messageId = messageIdElement.GetUInt64();
                        if (_responseFutures.TryGetValue(messageId, out var tcs))
                        {
                            _responseFutures.Remove(messageId);
                            tcs.SetResult(response);
                        }
                    }
                }
            }
            catch (Exception ex)
            {
                Console.Error.WriteLine($"Error in receive loop: {ex.Message}");
            }
        }

        private async Task<JsonElement> SendMessageAsync(object message)
        {
            var messageId = ++_messageId;
            var messageWithId = JsonSerializer.SerializeToElement(new
            {
                messageId,
                op = message.GetType().GetProperty("op")?.GetValue(message),
                key = message.GetType().GetProperty("key")?.GetValue(message),
                value = message.GetType().GetProperty("value")?.GetValue(message)
            });

            var tcs = new TaskCompletionSource<JsonElement>();
            _responseFutures[messageId] = tcs;

            var messageBytes = Encoding.UTF8.GetBytes(messageWithId.ToString());
            await _webSocket.SendAsync(new ArraySegment<byte>(messageBytes), WebSocketMessageType.Text, true, _cts.Token);

            var response = await tcs.Task;
            if (response.TryGetProperty("error", out var error) && !error.ValueEquals(""))
            {
                throw new Exception(error.GetString());
            }

            return response;
        }

        public async Task<bool> CreateAsync(string key, object value)
        {
            try
            {
                var message = new { op = OperationCode.Insert, key, value = JsonSerializer.Serialize(value) };
                await SendMessageAsync(message);
                return true;
            }
            catch (Exception ex)
            {
                Console.Error.WriteLine($"Error creating record: {ex.Message}");
                return false;
            }
        }

        public async Task<JsonElement?> ReadAsync(string key)
        {
            try
            {
                var message = new { op = OperationCode.Get, key };
                var response = await SendMessageAsync(message);
                
                if (response.TryGetProperty("value", out var value))
                {
                    if (value.ValueKind == JsonValueKind.String)
                    {
                        return JsonSerializer.Deserialize<JsonElement>(value.GetString()!);
                    }
                    return value;
                }
                return null;
            }
            catch (Exception ex)
            {
                Console.Error.WriteLine($"Error reading record: {ex.Message}");
                return null;
            }
        }

        public async Task<bool> UpdateAsync(string key, object value, bool partialUpdate)
        {
            try
            {
                // For partial updates, first read the existing value
                if (partialUpdate)
                {
                    var existingData = await ReadAsync(key);
                    if (existingData != null)
                    {
                        var newData = JsonSerializer.Deserialize<JsonElement>(JsonSerializer.Serialize(value));
                        var existingDict = JsonSerializer.Deserialize<Dictionary<string, JsonElement>>(existingData.Value.GetRawText());
                        var newDict = JsonSerializer.Deserialize<Dictionary<string, JsonElement>>(newData.GetRawText());

                        foreach (var (k, v) in newDict)
                        {
                            existingDict[k] = v;
                        }

                        value = existingDict;
                    }
                }

                var message = new { op = OperationCode.Insert, key, value = JsonSerializer.Serialize(value) };
                await SendMessageAsync(message);
                return true;
            }
            catch (Exception ex)
            {
                Console.Error.WriteLine($"Error updating record: {ex.Message}");
                return false;
            }
        }

        public async Task<bool> DeleteAsync(string key)
        {
            try
            {
                var message = new { op = OperationCode.Delete, key };
                await SendMessageAsync(message);
                return true;
            }
            catch (Exception ex)
            {
                Console.Error.WriteLine($"Error deleting record: {ex.Message}");
                return false;
            }
        }

        public void Dispose()
        {
            _cts.Cancel();
            if (_webSocket.State == WebSocketState.Open)
            {
                _webSocket.CloseAsync(WebSocketCloseStatus.NormalClosure, string.Empty, CancellationToken.None).Wait();
            }
            _webSocket.Dispose();
            _cts.Dispose();
        }
    }
} `
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">Code Examples</h2>
      <p>
        This section provides practical examples of using HPKV in different programming languages. 
        All examples are available in our <Link to="https://github.com/hpkv-io/hpkv-examples" className="text-primary hover:underline">GitHub repository</Link>.
      </p>

      <div className="space-y-8">
        <div>
          <h3 id="basic-crud" className="text-xl font-bold mb-2">Basic CRUD Operations</h3>
          <p className="mb-4">
            Basic CRUD (Create, Read, Update, Delete) operations are the foundation of working with HPKV. 
            These operations allow you to store and manage key-value pairs efficiently. Below sample is a HPKV client implementation in different programming languages.
            The client in the example use <Link to="/docs/rest-api" className="text-primary hover:underline">HPKV REST API</Link> to perform the operations.
          </p>

          <div className="mt-4">
            <CodeTabs snippets={basicCrudSnippets} />
          </div>

          <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
            <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
              <LightBulbIcon className="h-5 w-5 text-indigo-400" />
              <h4 className="font-bold text-indigo-400">Note</h4>
            </div>
            <div className="p-4">
              <p className="text-indigo-200/90">
                The examples above demonstrate the basic operations. For more complex scenarios and complete examples, 
                check out the <code className="bg-component-bg text-text p-1 rounded">basic-crud</code> directory in our 
                <Link to="https://github.com/hpkv-io/hpkv-examples/tree/main/examples/basic-crud" className="text-primary hover:underline"> examples repository</Link>.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h3 id="atomic-increment" className="text-xl font-bold mb-2">Atomic Increment Operations</h3>
          <p className="mb-4">
            Atomic increment operations allow you to safely modify numeric values without race conditions. 
            This is particularly useful for counters, rate limiters, and other scenarios requiring atomic updates.
            The code sample below demonstrates how to increment/decrement a key using <Link to="/docs/rest-api" className="text-primary hover:underline">HPKV REST API</Link> .
          </p>

          <div className="mt-4">
            <CodeTabs snippets={atomicIncrementSnippets} />
          </div>

          <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
            <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
              <LightBulbIcon className="h-5 w-5 text-indigo-400" />
              <h4 className="font-bold text-indigo-400">Note</h4>
            </div>
            <div className="p-4">
              <p className="text-indigo-200/90">
                For more examples of atomic operations, including concurrent scenarios and error handling, 
                visit the <code className="bg-component-bg text-text p-1 rounded">atomic-inc</code> directory in our 
               <Link to="https://github.com/hpkv-io/hpkv-examples/tree/main/examples/atomic-inc" className="text-primary hover:underline"> examples repository</Link>.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h3 id="range-queries" className="text-xl font-bold mb-2">Range Queries</h3>
          <p className="mb-4">
            Range queries allow you to efficiently retrieve multiple records within a specified key range. 
            This is useful for pagination, batch processing, and retrieving related records.
            See the code sample below for how to perform range queries using <Link to="/docs/rest-api" className="text-primary hover:underline">HPKV REST API</Link>.
          </p>

          <div className="mt-4">
            <CodeTabs snippets={rangeQuerySnippets} />
          </div>

          <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
            <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
              <LightBulbIcon className="h-5 w-5 text-indigo-400" />
              <h4 className="font-bold text-indigo-400">Note</h4>
            </div>
            <div className="p-4">
              <p className="text-indigo-200/90">
                For more examples of range queries, including filtering and pagination, 
                check out the <code className="bg-component-bg text-text p-1 rounded">range-queries</code> directory in our 
                <Link to="https://github.com/hpkv-io/hpkv-examples/tree/main/examples/range-queries" className="text-primary hover:underline"> examples repository</Link>.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h3 id="websockets" className="text-xl font-bold mb-2">Using WebSockets</h3>
          <p className="mb-4">
            HPKV provides WebSocket support for real-time updates and notifications. 
            This allows you to receive immediate updates when records change, enabling reactive applications.
            Below is a sample <Link to="/docs/websocket-api" className="text-primary hover:underline">HPKV WebSocket</Link> client implementation in different programming languages.
          </p>

          <div className="mt-4">
            <CodeTabs snippets={websocketSnippets} />
          </div>

          <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
            <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
              <LightBulbIcon className="h-5 w-5 text-indigo-400" />
              <h4 className="font-bold text-indigo-400">Note</h4>
            </div>
            <div className="p-4">
              <p className="text-indigo-200/90">
                For more examples of WebSocket usage, including reconnection handling and message correlation, 
                visit the <code className="bg-component-bg text-text p-1 rounded">web-sockets</code> directory in our 
                <Link to="https://github.com/hpkv-io/hpkv-examples/tree/main/examples/web-sockets" className="text-primary hover:underline"> examples repository</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeExamples; 