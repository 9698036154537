import React from 'react';
import { motion } from 'framer-motion';

const FairUse = () => {
  return (
    <div className="min-h-screen bg-background py-16 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-4xl mx-auto"
      >
        <h1 className="text-4xl font-bold text-heading-text mb-8">Fair Use Policy</h1>
        
        <div className="space-y-8 text-text">
          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">1. Purpose</h2>
            <p>This Fair Use Policy is designed to ensure that all users of HPKV can enjoy reliable, fast, and efficient service. We aim to provide the best possible experience for all our users while preventing abuse that could degrade the service quality.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">2. API Rate Limits</h2>
            <p>To ensure fair access to all users, we implement the following rate limits:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Limits are based on your service plan</li>
              <li>Rate limits are calculated per API key</li>
              <li>Exceeding rate limits will result in temporary request rejection</li>
              <li>Persistent rate limit violations may result in account review</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">3. Storage Limits</h2>
            <p>Storage usage is subject to the following guidelines:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Each service plan has defined storage limits</li>
              <li>Individual record sizes are limited based on your plan</li>
              <li>Approaching storage limits will trigger notifications</li>
              <li>Exceeding storage limits may result in write operations being rejected</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">4. Request Patterns</h2>
            <p>To maintain service quality, we monitor for and may restrict:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Excessive concurrent connections</li>
              <li>Aggressive retry patterns</li>
              <li>Sustained high-frequency requests</li>
              <li>Bulk operations that impact service performance</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">5. Prohibited Usage</h2>
            <p>The following activities are prohibited:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Attempting to bypass rate limits or quotas</li>
              <li>Using the service for illegal content or activities</li>
              <li>Sharing API keys or account credentials</li>
              <li>Reverse engineering the service</li>
              <li>Automated scanning or testing without prior approval</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">6. Monitoring and Enforcement</h2>
            <p>We actively monitor service usage and may take the following actions:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Automated throttling of excessive requests</li>
              <li>Notification of policy violations</li>
              <li>Temporary suspension of service access</li>
              <li>Account termination for severe or repeated violations</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">7. Service Optimization</h2>
            <p>To optimize your service usage:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Implement appropriate caching strategies</li>
              <li>Use batch operations when appropriate</li>
              <li>Monitor your usage through our dashboard</li>
              <li>Contact support for optimization guidance</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">8. Policy Updates</h2>
            <p>We may update this Fair Use Policy to reflect service improvements and changing needs. Users will be notified of significant changes via email and/or dashboard notifications.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">9. Contact</h2>
            <p>For questions about this Fair Use Policy or to report abuse, please contact our support team.</p>
          </section>
        </div>
      </motion.div>
    </div>
  );
};

export default FairUse; 