import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthSuccess = ({ onLogin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (!token) {
      navigate('/login?error=Invalid authentication data');
      return;
    }

    const fetchUserData = async () => {
      try {
        // Store token in localStorage
        localStorage.setItem('token', token);
        
        // Fetch user data from API
        const response = await fetch('/api/auth/user', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        
        const userData = await response.json();
        
        // Trigger auth change event
        window.dispatchEvent(new Event('auth-change'));
        
        // Call onLogin if provided
        if (onLogin) {
          onLogin(userData);
        }
        
        // Redirect to dashboard
        navigate('/dashboard');
      } catch (error) {
        console.error('Error processing authentication data:', error);
        setError('Authentication failed. Please try again.');
        localStorage.removeItem('token');
      }
    };

    fetchUserData();
  }, [navigate, location, onLogin]);

  if (error) {
    return (
      <div className="min-h-screen bg-background flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-500 mb-4">{error}</p>
          <button 
            onClick={() => navigate('/login')}
            className="px-4 py-2 bg-accent-purple text-white rounded-lg hover:bg-accent-blue transition-colors"
          >
            Return to Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background flex items-center justify-center">
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-accent-purple mx-auto"></div>
        <p className="mt-4 text-text">Completing authentication...</p>
      </div>
    </div>
  );
};

export default AuthSuccess; 