import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';

// Simple markdown renderer for previews - no code blocks or complex elements
function PreviewMarkdown({ content }) {
  return (
    <ReactMarkdown
      components={{
        // Override heading components to use regular text
        h1: 'span',
        h2: 'span',
        h3: 'span',
        h4: 'span',
        h5: 'span',
        h6: 'span',
        // Remove complex elements
        code: 'span',
        pre: 'span',
        // Keep basic formatting
        p: ({ children }) => <span>{children}</span>,
        strong: ({ children }) => <strong className="font-semibold">{children}</strong>,
        em: ({ children }) => <em className="italic">{children}</em>,
      }}
    >
      {content}
    </ReactMarkdown>
  );
}

export default function BlogCard({ title, excerpt, date, slug, readTime }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.02 }}
      className="relative w-full"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-accent-purple/50 to-accent-blue/50 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
      <Link to={`/blog/${slug}`} className="block group">
        <div className="relative bg-component-bg rounded-xl shadow-md overflow-hidden hover:shadow-xl transition-all duration-300 border border-gray-700">
          <div className="p-8">
            <div className="flex items-center text-sm text-text mb-3">
              <time dateTime={date} className="font-medium">
                {new Date(date).toLocaleDateString('en-US', { 
                  year: 'numeric', 
                  month: 'long', 
                  day: 'numeric' 
                })}
              </time>
              <span className="mx-2">•</span>
              <span className="font-medium">{readTime} min read</span>
            </div>
            <h3 className="text-2xl font-bold text-heading-text mb-3 group-hover:text-accent-purple transition-colors duration-300">
              {title}
            </h3>
            <p className="text-text line-clamp-3">
              <PreviewMarkdown content={excerpt} />
            </p>
            <div className="flex items-center text-accent-purple dark:text-accent-blue font-medium group-hover:text-accent-blue dark:group-hover:text-accent-purple transition-colors duration-300">
              Read more
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 group-hover:translate-x-1 transition-transform duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
} 