import React from 'react';
import { LightBulbIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CodeTabs from './CodeTabs';
import CodeBlock from './CodeBlock';

const WebSocketApi = () => {
  // Create a modified theme without background color
  const customStyle = {
    ...vscDarkPlus,
    'pre[class*="language-"]': {
      ...vscDarkPlus['pre[class*="language-"]'],
      background: 'transparent',
      margin: 0
    },
    'code[class*="language-"]': {
      ...vscDarkPlus['code[class*="language-"]'],
      background: 'transparent'
    }
  };

  const insertSnippets = {
    JavaScript: {
      language: 'javascript',
      code: `ws.send(JSON.stringify({
  op: 2,
  key: "user:123",
  value: "John Doe",
  messageId: 1
}));`
    },
    Python: {
      language: 'python',
      code: `await websocket.send(json.dumps({
    "op": 2,
    "key": "user:123",
    "value": "John Doe",
    "messageId": 1
}))`
    },
    'C#': {
      language: 'csharp',
      code: `await webSocket.SendAsync(
    JsonSerializer.SerializeToUtf8Bytes(
        new {
            op = 2,
            key = "user:123",
            value = "John Doe",
            messageId = 1
        }
    ),
    WebSocketMessageType.Text,
    true,
    CancellationToken.None
);`
    },
    Java: {
      language: 'java',
      code: `webSocket.send(
    new JSONObject()
        .put("op", 2)
        .put("key", "user:123")
        .put("value", "John Doe")
        .put("messageId", 1)
        .toString()
);`
    }
  };

  const getSnippets = {
    JavaScript: {
      language: 'javascript',
      code: `ws.send(JSON.stringify({
  op: 1,
  key: "user:123",
  messageId: 2
}));`
    },
    Python: {
      language: 'python',
      code: `await websocket.send(json.dumps({
    "op": 1,
    "key": "user:123",
    "messageId": 2
}))`
    },
    'C#': {
      language: 'csharp',
      code: `await webSocket.SendAsync(
    JsonSerializer.SerializeToUtf8Bytes(
        new {
            op = 1,
            key = "user:123",
            messageId = 2
        }
    ),
    WebSocketMessageType.Text,
    true,
    CancellationToken.None
);`
    },
    Java: {
      language: 'java',
      code: `webSocket.send(
    new JSONObject()
        .put("op", 1)
        .put("key", "user:123")
        .put("messageId", 2)
        .toString()
);`
    }
  };

  const updateSnippets = {
    JavaScript: {
      language: 'javascript',
      code: `ws.send(JSON.stringify({
  op: 3,
  key: "user:123",
  value: "World!",  // Will be appended to existing value
  messageId: 3
}));`
    },
    Python: {
      language: 'python',
      code: `await websocket.send(json.dumps({
    "op": 3,
    "key": "user:123",
    "value": "World!",  # Will be appended to existing value
    "messageId": 3
}))`
    },
    'C#': {
      language: 'csharp',
      code: `await webSocket.SendAsync(
    JsonSerializer.SerializeToUtf8Bytes(
        new {
            op = 3,
            key = "user:123",
            value = "World!",  // Will be appended to existing value
            messageId = 3
        }
    ),
    WebSocketMessageType.Text,
    true,
    CancellationToken.None
);`
    },
    Java: {
      language: 'java',
      code: `webSocket.send(
    new JSONObject()
        .put("op", 3)
        .put("key", "user:123")
        .put("value", "World!")  // Will be appended to existing value
        .put("messageId", 3)
        .toString()
);`
    }
  };

  const deleteSnippets = {
    JavaScript: {
      language: 'javascript',
      code: `ws.send(JSON.stringify({
  op: 4,
  key: "user:123",
  messageId: 4
}));`
    },
    Python: {
      language: 'python',
      code: `await websocket.send(json.dumps({
    "op": 4,
    "key": "user:123",
    "messageId": 4
}))`
    },
    'C#': {
      language: 'csharp',
      code: `await webSocket.SendAsync(
    JsonSerializer.SerializeToUtf8Bytes(
        new {
            op = 4,
            key = "user:123",
            messageId = 4
        }
    ),
    WebSocketMessageType.Text,
    true,
    CancellationToken.None
);`
    },
    Java: {
      language: 'java',
      code: `webSocket.send(
    new JSONObject()
        .put("op", 4)
        .put("key", "user:123")
        .put("messageId", 4)
        .toString()
);`
    }
  };

  const rangeQuerySnippets = {
    JavaScript: {
      language: 'javascript',
      code: `ws.send(JSON.stringify({
  op: 5,
  key: "user:100",    // startKey
  endKey: "user:200", // endKey
  limit: 50,          // Optional: max records to return
  messageId: 5
}));`
    },
    Python: {
      language: 'python',
      code: `await websocket.send(json.dumps({
    "op": 5,
    "key": "user:100",    # startKey
    "endKey": "user:200", # endKey
    "limit": 50,          # Optional: max records to return
    "messageId": 5
}))`
    },
    'C#': {
      language: 'csharp',
      code: `await webSocket.SendAsync(
    JsonSerializer.SerializeToUtf8Bytes(
        new {
            op = 5,
            key = "user:100",    // startKey
            endKey = "user:200", // endKey
            limit = 50,          // Optional: max records to return
            messageId = 5
        }
    ),
    WebSocketMessageType.Text,
    true,
    CancellationToken.None
);`
    },
    Java: {
      language: 'java',
      code: `webSocket.send(
    new JSONObject()
        .put("op", 5)
        .put("key", "user:100")    // startKey
        .put("endKey", "user:200") // endKey
        .put("limit", 50)          // Optional: max records to return
        .put("messageId", 5)
        .toString()
);`
    }
  };

  const atomicIncrementSnippets = {
    JavaScript: {
      language: 'javascript',
      code: `ws.send(JSON.stringify({
  op: 6,
  key: "counter:123",
  value: 1,  // Use negative value to decrement
  messageId: 6
}));`
    },
    Python: {
      language: 'python',
      code: `await websocket.send(json.dumps({
    "op": 6,
    "key": "counter:123",
    "value": 1,  # Use negative value to decrement
    "messageId": 6
}))`
    },
    'C#': {
      language: 'csharp',
      code: `await webSocket.SendAsync(
    JsonSerializer.SerializeToUtf8Bytes(
        new {
            op = 6,
            key = "counter:123",
            value = 1,  // Use negative value to decrement
            messageId = 6
        }
    ),
    WebSocketMessageType.Text,
    true,
    CancellationToken.None
);`
    },
    Java: {
      language: 'java',
      code: `webSocket.send(
    new JSONObject()
        .put("op", 6)
        .put("key", "counter:123")
        .put("value", 1)  // Use negative value to decrement
        .put("messageId", 6)
        .toString()
);`
    }
  };

  const connectionSnippets = {
    JavaScript: {
      language: 'javascript',
      code: `const ws = new WebSocket('wss://YOUR_BASE_URL/ws?apiKey=YOUR_API_KEY');

ws.onopen = () => {
  console.log('Connected to HPKV');
};

ws.onmessage = (event) => {
  const response = JSON.parse(event.data);
  console.log('Received:', response);
};

ws.onerror = (error) => {
  console.error('WebSocket error:', error);
};

ws.onclose = () => {
  console.log('Disconnected from HPKV');
};`
    },
    Python: {
      language: 'python',
      code: `import websockets
import json

async with websockets.connect(
    'wss://YOUR_BASE_URL/ws',
    extra_headers={'x-api-key': 'YOUR_API_KEY'}
) as websocket:
    # Handle messages
    async for message in websocket:
        response = json.loads(message)
        print('Received:', response)`
    },
    'C#': {
      language: 'csharp',
      code: `using var ws = new ClientWebSocket();
await ws.ConnectAsync(
    new Uri("wss://YOUR_BASE_URL/ws?apiKey=YOUR_API_KEY"),
    CancellationToken.None
);

// Receive messages
var buffer = new byte[4096];
var result = await ws.ReceiveAsync(
    new ArraySegment<byte>(buffer), CancellationToken.None
);

while (!result.CloseStatus.HasValue)
{
    var message = Encoding.UTF8.GetString(
        buffer, 0, result.Count
    );
    Console.WriteLine($"Received: {message}");
    
    result = await ws.ReceiveAsync(
        new ArraySegment<byte>(buffer), CancellationToken.None
    );
}`
    },
    Java: {
      language: 'java',
      code: `WebSocketClient client = new WebSocketClient();
Session session = client.connectToServer(
    new Endpoint() {
        @Override
        public void onOpen(Session session, EndpointConfig config) {
            session.addMessageHandler(new MessageHandler.Whole<String>() {
                @Override
                public void onMessage(String message) {
                    System.out.println("Received: " + message);
                }
            });
        }
    }, 
    URI.create("wss://YOUR_BASE_URL/ws?apiKey=YOUR_API_KEY")
);`
    }
  };

  const authSnippets = {
    'Query Parameter': {
      language: 'javascript',
      code: `const ws = new WebSocket('wss://YOUR_BASE_URL/ws?apiKey=YOUR_API_KEY');`
    },
    'Header': {
      language: 'javascript',
      code: `const ws = new WebSocket('wss://YOUR_BASE_URL/ws');
ws.setRequestHeader('x-api-key', 'YOUR_API_KEY');`
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="sr-only">HPKV WebSocket API</h1>
      <h2 className="text-2xl font-bold mb-4">WebSocket API</h2>
      <p>
        The WebSocket API provides a persistent connection for high-performance operations with HPKV. 
        It's designed for applications that need to minimize latency and reduce overhead from multiple HTTP requests.
      </p>

      <h3 id="connection" className="text-xl font-bold mt-6 mb-2">Connection</h3>
      <p>Connect to the WebSocket endpoint using your base URL and API key. You can provide the API key in two ways:</p>
      
      <div className="mt-4">
        <CodeTabs snippets={authSnippets} />
      </div>

      <div className="mt-4">
        <CodeTabs snippets={connectionSnippets} />
      </div>

      <h3 id="message-format" className="text-xl font-bold mt-6 mb-2">Message Format</h3>
      <p>All messages are JSON objects with the following structure:</p>
      <div className="bg-component-bg rounded-lg p-4">
        <CodeBlock
          language="json"
          style={customStyle}
          code={`{
  "op": number,     // Operation code
  "key": string,    // The key to operate on
  "value": string,  // The value (for insert/update operations)
  "messageId": number  // Optional: ID to match responses with requests
}`}
        />
      </div>

      <h3 id="operations" className="text-xl font-bold mt-6 mb-2">Operations</h3>
      <div className="bg-component-bg rounded-lg p-4 mb-6">
        <CodeBlock
          language="text"
          style={customStyle}
          code={`Operation Codes (op):
1: Get    - Retrieve a record by key
2: Insert/Update - Create a new record or replace existing
3: JSON Patch/Append - Append to an existing record or apply JSON patch if both values are valid JSON
4: Delete - Remove a record by key
5: Range  - Retrieve multiple records in a key range
6: Atomic Increment/Decrement`}
        />
      </div>

      <div className="space-y-8">
        <div>
          <h4 id="insert-record" className="text-lg font-bold mb-2">1. Insert/Update a Record</h4>
          <CodeTabs snippets={insertSnippets} />
        </div>

        <div>
          <h4 id="get-record" className="text-lg font-bold mb-2">2. Get a Record</h4>
          <CodeTabs snippets={getSnippets} />
        </div>

        <div>
          <h4 id="append-record" className="text-lg font-bold mb-2">3. JSON Patch/Append to a Record</h4>
          <p className="text-sm text-text-secondary mb-2">
            If the record "user:123" has value "Hello ", appending "World!" will result in "Hello World!"
          </p>
          <CodeTabs snippets={updateSnippets} />
          
          <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
            <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
              <LightBulbIcon className="h-5 w-5 text-indigo-400" />
              <h4 className="font-bold text-indigo-400">JSON Atomic Patching</h4>
            </div>
            <div className="p-4">
              <p className="text-indigo-200/90">
                When using the Update operation (op 3), if both the existing stored value and the new value are valid JSON objects, HPKV will automatically perform a JSON patch operation instead of simple appending. This merges the JSON objects, updating existing fields and adding new ones.
              </p>
              <p className="text-indigo-200/90 mt-2">
                For example, if the record "user:123" has value <code className="bg-component-bg text-text p-1 rounded">{`{"name":"John","age":25}`}</code> and you send an update with <code className="bg-component-bg text-text p-1 rounded">{`{"age":30,"city":"New York"}`}</code>, the resulting value will be <code className="bg-component-bg text-text p-1 rounded">{`{"name":"John","age":30,"city":"New York"}`}</code>.
              </p>
              <p className="text-indigo-200/90 mt-2">
                If either value is not valid JSON, the system falls back to the standard append behavior.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h4 id="delete-record" className="text-lg font-bold mb-2">4. Delete a Record</h4>
          <CodeTabs snippets={deleteSnippets} />
        </div>

        <div>
          <h4 id="range-query" className="text-lg font-bold mb-2">5. Range Query</h4>
          <p>Retrieve multiple records within a key range.</p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="json"
              style={customStyle}
              code={`{
  "op": 5,             // Operation code
  "key": "string",     // startKey (inclusive)
  "endKey": "string",  // endKey (inclusive)
  "limit": number,     // Optional: max records to return (default: 100, max: 1000)
  "messageId": number  // Optional: ID to match responses with requests
}`}
            />
          </div>

          <div className="mt-4">
            <CodeTabs snippets={rangeQuerySnippets} />
          </div>

          <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
            <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
              <LightBulbIcon className="h-5 w-5 text-indigo-400" />
              <h4 className="font-bold text-indigo-400">Note</h4>
            </div>
            <div className="p-4">
              <p className="text-indigo-200/90">
              Range queries are perfect for retrieving collections of related data. For example, you can fetch all products in a category with <code className="bg-component-bg text-text p-1 rounded">key="product:category1:"</code> and <code className="bg-component-bg text-text p-1 rounded">endKey="product:category1:~"</code>.
              </p>
              <p className="text-indigo-200/90">
              The tilde (~) character has a high ASCII value, making it useful as an end marker for prefixed keys.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h4 id="atomic-increment" className="text-lg font-bold mb-2">6. Atomic Increment/Decrement</h4>
          <p>
            Atomically increment or decrement a numeric value stored at the specified key.
          </p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="json"
              style={customStyle}
              code={`{
  "op": 6,
  "key": "string",
  "value": number,  // Positive to increment, negative to decrement
  "timestamp": number,  // Optional: milliseconds since epoch
  "messageId": number   // Optional: ID to track this request
}`}
            />
          </div>

          <div className="mt-4">
            <CodeTabs snippets={atomicIncrementSnippets} />
          </div>

          <p className="mt-2"><strong>Response:</strong></p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="json"
              style={customStyle}
              code={`{
  "success": true,
  "message": "Record incremented/decremented successfully",
  "newValue": 42,  // The new value after increment/decrement
  "code": 200,
  "messageId": 6
}`}
            />
          </div>

          <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
            <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
              <LightBulbIcon className="h-5 w-5 text-indigo-400" />
              <h4 className="font-bold text-indigo-400">Note</h4>
            </div>
            <div className="p-4">
              <p className="text-indigo-200/90">
                Atomic operations are useful for counters, rate limiters, and other scenarios where you need to ensure consistency without race conditions. 
                The system will automatically create the key if it doesn't exist in case of an increment.
              </p>
            </div>
          </div>
        </div>
      </div>

      <h3 id="key-monitoring" className="text-xl font-bold mt-6 mb-2">Key Monitoring (Pub-Sub)</h3>
      <p>
        HPKV provides a bi-directional WebSocket feature that enables real-time monitoring of key changes. This pub-sub system 
        automatically notifies clients when monitored keys are modified through any interface (REST or WebSocket).
      </p>

      <h4 id="token-generation" className="text-lg font-bold mb-2">1. Generate a Monitoring Token</h4>
      <p>First, generate a WebSocket token with the keys you want to monitor using the REST API:</p>
      <div className="bg-component-bg rounded-lg p-4">
        <CodeBlock
          language="bash"
          style={customStyle}
          code={`# Generate a token with keys to monitor
curl -X POST "https://YOUR_BASE_URL/token/websocket" \\
  -H "x-api-key: YOUR_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{
    "subscribeKeys": ["user:123", "counter:456", "product:789"],
    "accessPattern": "^(user|product):[0-9]+"
  }'`}
        />
      </div>

      <div className="mt-4">
        <p className="font-semibold mb-2">Response:</p>
        <div className="bg-component-bg rounded-lg p-4">
          <CodeBlock
            language="json"
            style={customStyle}
            code={`{
  "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9..."
}`}
          />
        </div>
      </div>

      <h4 id="access-pattern-control" className="text-lg font-bold mt-4 mb-2">Access Pattern Control</h4>
      <p className="mb-2">
        The optional <code className="text-blue-400">accessPattern</code> parameter allows you to restrict which keys the token can perform operations on (get, insert, update, delete, range, atomic increment/decrement).
      </p>
      <p className="mb-2">
        This parameter accepts a valid regex pattern string that will be used to validate keys for all operations except subscription notifications.
      </p>
      <p className="mb-2">
        For example, the pattern <code className="text-blue-400">^(user|product):[0-9]+</code> would only allow operations on keys that start with <code className="text-blue-400">"user:"</code> or <code className="text-blue-400">"product:"</code> followed by numbers; 
        or the pattern <code className="text-blue-400">$^</code> can be used to restrict all operations for the token, except for subscription notifications.
      </p>
      <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
        <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-indigo-400" />
          <h4 className="font-bold text-indigo-400">Note</h4>
        </div>
        <div className="p-4">
          <p className="text-indigo-200/90">
            The access pattern only restricts which keys the token can perform operations on. It does not affect the subscription keys specified in the <code className="text-blue-400">subscribeKeys</code> array. You will continue to receive notifications for all subscribed keys regardless of the access pattern.
          </p>
        </div>
      </div>
      
      <div className="mt-6 border border-blue-400/20 rounded-lg bg-blue-400/5">
        <div className="px-4 py-3 border-b border-blue-400/20 flex items-center gap-2">
          <ShieldCheckIcon className="h-5 w-5 text-blue-400" />
          <h4 className="font-bold text-blue-400">Regex Pattern Security Restrictions</h4>
        </div>
        <div className="p-4">
          <p className="text-blue-200/90">
            For security reasons, certain regex features are not allowed in access patterns:
          </p>
          <p className="text-blue-200/90 mb-2">
            Disallowed regex features include:
          </p>
          <ul className="list-disc pl-5 space-y-1 mb-2 text-blue-200/90">
            <li>Backreferences (<code className="text-pink-500">\1</code>, <code className="text-pink-500">\2</code>, etc.)</li>
            <li>Lookaheads and lookbehinds (<code className="text-pink-500">(?=...)</code>, <code className="text-pink-500">(?&lt;=...)</code>, etc.)</li>
            <li>Nested repetition quantifiers (<code className="text-pink-500">(a+)+</code>)</li>
            <li>Possessive quantifiers (<code className="text-pink-500">a++</code>, <code className="text-pink-500">a*+</code>)</li>
          </ul>
          <p className="text-blue-200/90 mb-2">
            Allowed regex features include:
          </p>
          <ul className="list-disc pl-5 space-y-1 mb-2 text-blue-200/90">
            <li>Basic character matching (<code className="text-green-500">a-z</code>, <code className="text-green-500">0-9</code>, etc.)</li>
            <li>Simple quantifiers (<code className="text-green-500">*</code>, <code className="text-green-500">+</code>, <code className="text-green-500">?</code>) not nested</li>
            <li>Character classes (<code className="text-green-500">[abc]</code>, <code className="text-green-500">[^abc]</code>)</li>
            <li>Alternation (<code className="text-green-500">a|b</code>)</li>
            <li>Anchors (<code className="text-green-500">^</code>, <code className="text-green-500">$</code>)</li>
            <li>Groups for organization (not for backreferences)</li>
          </ul>
        </div>
      </div>

      <h4 id="connect-with-token" className="text-lg font-bold mt-4 mb-2">2. Connect with the Token</h4>
      <p>Connect to the WebSocket endpoint using the generated token:</p>
      
      <div className="mt-4">
        <CodeTabs snippets={{
          JavaScript: {
            language: 'javascript',
            code: `const ws = new WebSocket('wss://YOUR_BASE_URL/ws?token=YOUR_TOKEN');

ws.onopen = () => {
  console.log('Connected to HPKV monitoring service');
};

ws.onmessage = (event) => {
  const notification = JSON.parse(event.data);
  if (notification.type === 'notification') {
    console.log('Key change notification:', notification);
    // Handle the notification based on key and value
  }
};`
          },
          Python: {
            language: 'python',
            code: `import json
import asyncio
import websockets

async def connect_to_websocket():
    uri = "wss://YOUR_BASE_URL/ws?token=YOUR_TOKEN"
    
    async with websockets.connect(uri) as websocket:
        print("Connected to HPKV monitoring service")
        
        while True:
            message = await websocket.recv()
            notification = json.loads(message)
            
            if notification.get('type') == 'notification':
                print(f"Key change notification: {notification}")
                # Handle the notification based on key and value

asyncio.run(connect_to_websocket())`
          },
          'C#': {
            language: 'csharp',
            code: `using System;
using System.Net.WebSockets;
using System.Text;
using System.Threading;
using System.Threading.Tasks;
using System.Text.Json;

class HPKVMonitor
{
    public async Task ConnectAndMonitor()
    {
        using (ClientWebSocket webSocket = new ClientWebSocket())
        {
            Uri uri = new Uri("wss://YOUR_BASE_URL/ws?token=YOUR_TOKEN");
            
            await webSocket.ConnectAsync(uri, CancellationToken.None);
            Console.WriteLine("Connected to HPKV monitoring service");
            
            byte[] buffer = new byte[1024 * 4];
            
            while (webSocket.State == WebSocketState.Open)
            {
                WebSocketReceiveResult result = await webSocket.ReceiveAsync(
                    new ArraySegment<byte>(buffer), CancellationToken.None);
                
                if (result.MessageType == WebSocketMessageType.Text)
                {
                    string message = Encoding.UTF8.GetString(buffer, 0, result.Count);
                    var notification = JsonSerializer.Deserialize<KeyNotification>(message);
                    
                    if (notification.Type == "notification")
                    {
                        Console.WriteLine($"Key change notification: {message}");
                        // Handle the notification based on key and value
                    }
                }
            }
        }
    }
}

public class KeyNotification
{
    public string Type { get; set; }
    public string Key { get; set; }
    public string Value { get; set; }
    public long Timestamp { get; set; }
}`
          },
          Java: {
            language: 'java',
            code: `import java.net.URI;
import org.java_websocket.client.WebSocketClient;
import org.java_websocket.handshake.ServerHandshake;
import org.json.JSONObject;

public class HPKVMonitor {
    public static void main(String[] args) {
        try {
            URI serverUri = new URI("wss://YOUR_BASE_URL/ws?token=YOUR_TOKEN");
            
            WebSocketClient client = new WebSocketClient(serverUri) {
                @Override
                public void onOpen(ServerHandshake handshake) {
                    System.out.println("Connected to HPKV monitoring service");
                }
                
                @Override
                public void onMessage(String message) {
                    try {
                        JSONObject notification = new JSONObject(message);
                        
                        if ("notification".equals(notification.getString("type"))) {
                            System.out.println("Key change notification: " + notification);
                            // Handle the notification based on key and value
                        }
                    } catch (Exception e) {
                        e.printStackTrace();
                    }
                }
                
                @Override
                public void onClose(int code, String reason, boolean remote) {
                    System.out.println("Connection closed: " + reason);
                }
                
                @Override
                public void onError(Exception ex) {
                    ex.printStackTrace();
                }
            };
            
            client.connect();
            
        } catch (Exception e) {
            e.printStackTrace();
        }
    }
}`
          }
        }} />
      </div>

      <h4 id="notification-format" className="text-lg font-bold mt-4 mb-2">3. Notification Format</h4>
      <p>When a monitored key changes, you'll receive a notification with the following format:</p>
      <div className="bg-component-bg rounded-lg p-4">
        <CodeBlock
          language="json"
          style={customStyle}
          code={`{
  "type": "notification",
  "key": "user:123",
  "value": "John Doe", // The new value, will be null if key was deleted
  "timestamp": 1628701234567
}`}
        />
      </div>

      <h4 id="handling-notifications" className="text-lg font-bold mt-4 mb-2">4. Handling Notifications</h4>
      <p>Here's how to handle the notifications in various programming languages:</p>
      
      <div className="mt-4">
        <CodeTabs snippets={{
          JavaScript: {
            language: 'javascript',
            code: `// Process notifications
ws.onmessage = (event) => {
  const data = JSON.parse(event.data);
  
  if (data.type === 'notification') {
    const { key, value, timestamp } = data;
    
    if (value === null) {
      console.log(\`Key \${key} was deleted at \${new Date(timestamp)}\`);
      // Handle deletion (e.g., remove from UI)
      removeItemFromUI(key);
    } else {
      console.log(\`Key \${key} was updated to \${value} at \${new Date(timestamp)}\`);
      // Handle update (e.g., update UI with new value)
      updateItemInUI(key, value);
    }
  }
};`
          },
          Python: {
            language: 'python',
            code: `async def handle_notification(notification):
    if notification.get('type') == 'notification':
        key = notification.get('key')
        value = notification.get('value')
        timestamp = notification.get('timestamp')
        time_str = datetime.fromtimestamp(timestamp/1000).strftime('%Y-%m-%d %H:%M:%S')
        
        if value is None:
            print(f"Key {key} was deleted at {time_str}")
            # Handle deletion (e.g., remove from UI)
            remove_item_from_ui(key)
        else:
            print(f"Key {key} was updated to {value} at {time_str}")
            # Handle update (e.g., update UI with new value)
            update_item_in_ui(key, value)

# In the websocket receive loop
async def message_loop(websocket):
    while True:
        message = await websocket.recv()
        notification = json.loads(message)
        await handle_notification(notification)`
          },
          'C#': {
            language: 'csharp',
            code: `private void HandleNotification(KeyNotification notification)
{
    if (notification.Type == "notification")
    {
        var timestamp = DateTimeOffset.FromUnixTimeMilliseconds(notification.Timestamp)
            .DateTime.ToString("yyyy-MM-dd HH:mm:ss");
            
        if (notification.Value == null)
        {
            Console.WriteLine($"Key {notification.Key} was deleted at {timestamp}");
            // Handle deletion (e.g., remove from UI)
            RemoveItemFromUI(notification.Key);
        }
        else
        {
            Console.WriteLine($"Key {notification.Key} was updated to {notification.Value} at {timestamp}");
            // Handle update (e.g., update UI with new value)
            UpdateItemInUI(notification.Key, notification.Value);
        }
    }
}

// In the WebSocket receive loop
private async Task ProcessMessages(ClientWebSocket webSocket)
{
    byte[] buffer = new byte[1024 * 4];
    
    while (webSocket.State == WebSocketState.Open)
    {
        WebSocketReceiveResult result = await webSocket.ReceiveAsync(
            new ArraySegment<byte>(buffer), CancellationToken.None);
            
        if (result.MessageType == WebSocketMessageType.Text)
        {
            string message = Encoding.UTF8.GetString(buffer, 0, result.Count);
            var notification = JsonSerializer.Deserialize<KeyNotification>(message);
            HandleNotification(notification);
        }
    }
}`
          },
          Java: {
            language: 'java',
            code: `private void handleNotification(JSONObject notification) {
    if ("notification".equals(notification.getString("type"))) {
        String key = notification.getString("key");
        long timestamp = notification.getLong("timestamp");
        Date date = new Date(timestamp);
        SimpleDateFormat formatter = new SimpleDateFormat("yyyy-MM-dd HH:mm:ss");
        String timeStr = formatter.format(date);
        
        if (notification.isNull("value")) {
            System.out.println("Key " + key + " was deleted at " + timeStr);
            // Handle deletion (e.g., remove from UI)
            removeItemFromUI(key);
        } else {
            String value = notification.getString("value");
            System.out.println("Key " + key + " was updated to " + value + " at " + timeStr);
            // Handle update (e.g., update UI with new value)
            updateItemInUI(key, value);
        }
    }
}

// In the WebSocket client
@Override
public void onMessage(String message) {
    try {
        JSONObject notification = new JSONObject(message);
        handleNotification(notification);
    } catch (Exception e) {
        e.printStackTrace();
    }
}`
          }
        }} />
      </div>

      <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
        <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-indigo-400" />
          <h4 className="font-bold text-indigo-400">Note</h4>
        </div>
        <div className="p-4">
          <p className="text-indigo-200/90">
            The key monitoring feature follows a pub-sub pattern. Any change to monitored keys will trigger notifications to all connected clients that are subscribed to those keys.
          </p>
          <p className="text-indigo-200/90 mt-2">
            Each key must be specified exactly as it will appear in the database. Only exact key matches will trigger notifications.
          </p>
        </div>
      </div>

      <h3 id="response-format" className="text-xl font-bold mt-6 mb-2">Response Format</h3>
      <p>Responses are JSON objects with the following structure:</p>
      <div className="bg-component-bg rounded-lg p-4">
        <CodeBlock
          language="json"
          style={customStyle}
          code={`{
  "code": number,     // HTTP-style status code
  "messageId": number,  // Matches the request messageId if provided
  "key": string,     // Present in successful GET responses
  "value": string,   // Present in successful GET responses
  "error": string,   // Present when an error occurs
  "success": boolean // Present in successful write operations
}`}
        />
      </div>
      <div>
        <p className="font-semibold mb-2">Success Response (Range Query):</p>
        <div className="bg-component-bg rounded-lg p-4">
          <CodeBlock
            language="json"
            style={customStyle}
            code={`{
  "records": [
    {
      "key": "user:100",
      "value": "Alice"
    },
    {
      "key": "user:101",
      "value": "Bob"
    },
    // ... more records
  ],
  "count": 50,
  "truncated": false,
  "code": 200,
  "messageId": 5
}`}
          />
        </div>
      </div>

      <h3 id="error-handling" className="text-xl font-bold mt-6 mb-2">Error Handling</h3>
      <p>Common error codes in responses:</p>
      <ul className="list-disc pl-6">
        <li><code className="bg-component-bg text-text p-1 rounded">400</code>: Invalid request format</li>
        <li><code className="bg-component-bg text-text p-1 rounded">403</code>: Permission denied</li>
        <li><code className="bg-component-bg text-text p-1 rounded">404</code>: Record not found</li>
        <li><code className="bg-component-bg text-text p-1 rounded">429</code>: Rate limit exceeded</li>
        <li><code className="bg-component-bg text-text p-1 rounded">500</code>: Internal server error</li>
      </ul>

      <h3 id="live-demo" className="text-xl font-bold mt-6 mb-2">Live Demo</h3>
      <p>
        We've created a simple yet functional Todo application to demonstrate the WebSocket API in action. 
        The demo is a frontend-only application that uses HPKV as its backend storage through WebSocket connections, 
        showcasing real-time updates and persistent storage capabilities. Multiple users connecting with the same API key 
        can collaborate in real-time, seeing each other's changes instantly as they add, complete, or remove todos.
      </p>
      
      <div className="mt-4 mb-4 w-1/4">
        <img 
          src="/images/screenshot-darkmode.png" 
          alt="HPKV WebSocket Todo Demo Screenshot" 
          className="rounded-lg shadow-lg border border-gray-700 w-full"
        />
      </div>

      <div className="flex gap-4">
        <a 
          href="https://showcase-todo.hpkv.io" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Try Live Demo
        </a>
        <a 
          href="https://github.com/hpkv-io/showcase-ws-todo" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="inline-flex items-center gap-2 px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
          </svg>
          View Source Code
        </a>
      </div>

      <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
        <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-indigo-400" />
          <h4 className="font-bold text-indigo-400">Note</h4>
        </div>
        <div className="p-4">
          <p className="text-indigo-200/90">
            You'll need a valid API key to try the demo.
          </p>
          <p className="text-indigo-200/90">
            Not signed up yet? Get started with HPKV today and experience lightning-fast, real-time data storage for your applications.{' '}
            <a 
              href="/signup" 
              className="text-blue-400 hover:text-blue-300 font-medium"
            >
              Sign up now →
            </a>
          </p>
        </div>
      </div>

      <div id="best-practices" className="mt-6 border border-emerald-400/20 rounded-lg bg-emerald-400/5">
        <div className="px-4 py-3 border-b border-emerald-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-emerald-400" />
          <h4 className="font-bold text-emerald-400">Best Practices</h4>
        </div>
        <ul className="list-disc pl-10 pr-4 py-3" style={{ color: 'rgba(94, 196, 150, 1)' }}>
          <li>Use messageId to match responses with requests</li>
          <li>Implement reconnection logic with exponential backoff</li>
          <li>Handle connection errors and implement proper error recovery</li>
          <li>Monitor the connection state and implement heartbeat if needed</li>
          <li>Consider using a WebSocket client library that handles reconnection</li>
        </ul>
      </div>
    </div>
  );
};

export default WebSocketApi; 