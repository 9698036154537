import React from 'react';
import { motion } from 'framer-motion';

const Terms = () => {
  return (
    <div className="min-h-screen bg-background py-16 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-4xl mx-auto"
      >
        <h1 className="text-4xl font-bold text-heading-text mb-8">Terms of Service</h1>
        
        <div className="space-y-8 text-text">
          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">1. Acceptance of Terms</h2>
            <p>By accessing and using HPKV's services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">2. Service Description</h2>
            <p>HPKV provides a high-performance key-value store service through both RESTful and WebSocket interfaces. Our service includes:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Fast and reliable data storage</li>
              <li>Secure data access</li>
              <li>Data storage and retrieval capabilities</li>
              <li>Real-time data operations</li>
              <li>Regional and global deployment options</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">3. Data Processing and Storage</h2>
            <p>Your data processing and storage location depends on your service configuration:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Standard plans: Data is processed and stored in the region you select (EU or US) when creating your API key</li>
              <li>Business plan with global service: Data may be processed and stored across multiple regions for optimal performance</li>
              <li>You are responsible for ensuring compliance with data protection requirements in your chosen region(s)</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">4. API Usage and Limitations</h2>
            <p>Your use of the HPKV API is subject to the following conditions:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Rate limits as specified in your service plan</li>
              <li>Data size restrictions based on your plan</li>
              <li>API key requirements and security measures</li>
              <li>Compliance with our fair use policy</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">5. User Responsibilities</h2>
            <p>As a user of HPKV, you agree to:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Maintain the security of your API keys</li>
              <li>Use the service in compliance with all applicable laws, including GDPR and other EU data protection laws</li>
              <li>Not attempt to circumvent service limitations</li>
              <li>Not use the service for any illegal or unauthorized purposes</li>
              <li>Ensure you have the legal right to process any personal data you store using our service</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">6. Service Availability</h2>
            <p>While we strive for high availability, we do not guarantee uninterrupted service. We reserve the right to:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Perform maintenance with reasonable notice</li>
              <li>Modify or discontinue features</li>
              <li>Update the service as needed</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">7. Intellectual Property</h2>
            <p>All intellectual property rights in the service belong to HPKV. Users retain all rights to their data stored in the service.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">8. Termination</h2>
            <p>We reserve the right to terminate or suspend access to our service immediately, without prior notice, for any breach of these Terms of Service.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">9. Changes to Terms</h2>
            <p>We may modify these terms at any time. Continued use of the service after any modifications indicates acceptance of the new terms.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">10. Limitation of Liability</h2>
            <p>HPKV shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of the service.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-heading-text mb-4">11. Contact</h2>
            <p>For any questions regarding these terms, please contact us through our support channels.</p>
          </section>
        </div>
      </motion.div>
    </div>
  );
};

export default Terms; 