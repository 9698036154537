import { useEffect, useRef, useState, useCallback } from 'react';

/**
 * Hook to load and manage Cloudflare Turnstile
 * @param {Object} options - Configuration options
 * @param {String} options.siteKey - Turnstile site key
 * @param {Function} options.onVerify - Callback when token is verified
 * @param {Function} options.onError - Callback when an error occurs
 * @param {Function} options.onExpire - Callback when token expires
 * @param {String} options.theme - Theme for the widget ('light' or 'dark')
 * @returns {Object} - Turnstile ref and token state
 */
const useTurnstile = (options = {}) => {
  const [token, setToken] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const turnstileRef = useRef(null);
  const optionsRef = useRef(options);
  
  // Store current callbacks in refs to avoid dependency issues
  const onVerifyRef = useRef(options.onVerify);
  const onErrorRef = useRef(options.onError);
  const onExpireRef = useRef(options.onExpire);
  
  // Update refs when callbacks change
  useEffect(() => {
    onVerifyRef.current = options.onVerify;
    onErrorRef.current = options.onError;
    onExpireRef.current = options.onExpire;
    optionsRef.current = options;
  }, [options]);

  // Stable callback functions that don't change on re-renders
  const handleVerify = useCallback((responseToken) => {
    setToken(responseToken);
    if (onVerifyRef.current) {
      onVerifyRef.current(responseToken);
    }
  }, []);

  const handleError = useCallback(() => {
    setToken('');
    if (onErrorRef.current) {
      onErrorRef.current();
    }
  }, []);

  const handleExpire = useCallback(() => {
    setToken('');
    if (onExpireRef.current) {
      onExpireRef.current();
    }
  }, []);

  // Load Turnstile script on mount
  useEffect(() => {
    // Only load if window.loadTurnstile is available
    if (window.loadTurnstile) {
      window.loadTurnstile();
      
      // Check if Turnstile is loaded
      const checkInterval = setInterval(() => {
        if (window.turnstile && window.turnstileLoaded) {
          setIsLoaded(true);
          clearInterval(checkInterval);
        }
      }, 100);
      
      return () => clearInterval(checkInterval);
    }
  }, []);

  // Render Turnstile when loaded and ref is available
  useEffect(() => {
    if (isLoaded && window.turnstile && turnstileRef.current && !isRendered) {
      const siteKey = optionsRef.current.siteKey || "0x4AAAAAAA48xdPOI1jvNGiS";
      const theme = optionsRef.current.theme || "dark";
      
      window.turnstile.render(turnstileRef.current, {
        sitekey: siteKey,
        theme,
        callback: handleVerify,
        'error-callback': handleError,
        'expired-callback': handleExpire
      });
      
      setIsRendered(true);
    }

    // Cleanup Turnstile widget on unmount
    return () => {
      if (window.turnstile && isRendered) {
        window.turnstile.remove();
        setIsRendered(false);
      }
    };
  }, [isLoaded, isRendered, handleVerify, handleError, handleExpire]);

  // Reset function to reset the widget
  const reset = useCallback(() => {
    if (window.turnstile && isRendered) {
      window.turnstile.reset();
      setToken('');
    }
  }, [isRendered]);

  return {
    turnstileRef,
    token,
    reset,
    isLoaded
  };
};

export default useTurnstile; 