import React from 'react';
import Roadmap from '../components/Roadmap';

const RoadmapPage = () => {
  return (
    <div className="min-h-screen bg-background">
      <div className="pt-24">
        <Roadmap />
      </div>
    </div>
  );
};

export default RoadmapPage; 