import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import useTurnstile from '../hooks/useTurnstile';

const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [signupSuccess, setSignupSuccess] = useState(false);
  const navigate = useNavigate();
  
  // Use the Turnstile hook
  const { turnstileRef, token: turnstileToken, reset: resetTurnstile } = useTurnstile({
    onError: () => setError('CAPTCHA verification failed')
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!turnstileToken) {
      setError('Please complete the CAPTCHA challenge');
      return;
    }

    try {
      const response = await fetch('/api/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          name, 
          email, 
          password,
          turnstileToken 
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Error signing up');
      }

      setSignupSuccess(true);
    } catch (err) {
      console.error('Signup error:', err);
      setError(err.message);
      // Reset Turnstile on error
      resetTurnstile();
    }
  };

  const handleOauthSignup = async (provider) => {
    setError('');
    try {
      // In a real implementation, you would redirect to the OAuth provider's authorization URL
      // For now, we'll just simulate the process
      window.location.href = `/api/auth/${provider}/signup`;
    } catch (err) {
      console.error(`${provider} signup error:`, err);
      setError(`Error signing up with ${provider}`);
    }
  };

  if (signupSuccess) {
    return (
      <div className="min-h-screen bg-background">
        <div className="flex items-center justify-center min-h-screen">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-component-bg p-8 rounded-xl shadow-lg border border-gray-800 w-full max-w-md mx-4"
          >
            <div className="text-center">
              <h2 className="text-2xl font-bold text-heading-text mb-4">Thank You for Signing Up!</h2>
              <p className="text-text mb-8">Please check your email to verify your account.</p>
              <button
                onClick={() => navigate('/login')}
                className="w-full bg-accent-purple text-white font-semibold py-3 px-4 rounded-lg hover:bg-accent-blue transition-colors duration-200"
              >
                Go to Login
              </button>
            </div>
          </motion.div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background">

      <div className="hidden md:block absolute top-0 bottom-0 left-1/2 bg-gray-50" aria-hidden="true" />
      <div className="relative flex flex-col items-center justify-center min-h-screen">
        <div className="absolute w-1/3 h-1/3 bg-accent-purple/30 rounded-full blur-3xl -top-10 -left-10 animate-pulse" />
        <div className="absolute w-1/3 h-1/3 bg-accent-blue/30 rounded-full blur-3xl -bottom-10 -right-10 animate-pulse" />

        <div className="w-full max-w-md space-y-8 px-4">
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl font-bold tracking-tight text-heading-text"
            >
              Create your account
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="mt-3 text-base text-text"
            >
              Start your journey with us today
            </motion.p>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-component-bg p-8 rounded-xl shadow-lg border border-gray-800"
          >
            {error && (
              <div className="mb-4 p-4 rounded-lg bg-red-500/10 border border-red-500/20">
                <p className="text-sm text-red-500">{error}</p>
              </div>
            )}

            <div className="space-y-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-text mb-2">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-text mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-text mb-2">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>

                <div className="flex justify-center">
                  <div ref={turnstileRef}></div>
                </div>

                <div className="text-sm text-text">
                  By creating an account, you agree to our{' '}
                  <Link to="/terms" className="font-medium text-accent-purple hover:text-accent-blue">
                    Terms
                  </Link>{' '}
                  and{' '}
                  <Link to="/privacy" className="font-medium text-accent-purple hover:text-accent-blue">
                    Privacy Policy
                  </Link>
                </div>

                <button
                  type="submit"
                  className="w-full bg-accent-purple text-white font-semibold py-3 px-4 rounded-lg hover:bg-accent-blue transition-colors duration-200"
                >
                  Create Account
                </button>
              </form>
              
              <div className="relative mt-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-700"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-component-bg text-text">Or sign up with</span>
                </div>
              </div>
              
              {/* Social Sign Up Options */}
              <div className="flex justify-center space-x-4 mt-4">
                <button
                  type="button"
                  onClick={() => handleOauthSignup('google')}
                  className="flex items-center justify-center w-12 h-12 bg-background border border-gray-700 rounded-full hover:bg-gray-800 transition-colors duration-200"
                  aria-label="Sign up with Google"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="#4285F4" d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" />
                    <path fill="#34A853" d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" />
                    <path fill="#FBBC05" d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" />
                    <path fill="#EA4335" d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" />
                  </svg>
                </button>
                
                <button
                  type="button"
                  onClick={() => handleOauthSignup('apple')}
                  className="flex items-center justify-center w-12 h-12 bg-background border border-gray-700 rounded-full hover:bg-gray-800 transition-colors duration-200"
                  aria-label="Sign up with Apple"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="currentColor" d="M17.05 20.28c-.98.95-2.05.8-3.08.35-1.09-.46-2.09-.48-3.24 0-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8 1.18-.24 2.31-.93 3.57-.84 1.51.12 2.65.72 3.4 1.8-3.12 1.87-2.38 5.98.48 7.13-.57 1.5-1.31 2.99-2.53 4.08zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25.29 2.58-2.34 4.5-3.74 4.25z" />
                  </svg>
                </button>
                
                <button
                  type="button"
                  onClick={() => handleOauthSignup('github')}
                  className="flex items-center justify-center w-12 h-12 bg-background border border-gray-700 rounded-full hover:bg-gray-800 transition-colors duration-200"
                  aria-label="Sign up with GitHub"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="currentColor" d="M12 2C6.477 2 2 6.477 2 12c0 4.42 2.865 8.166 6.839 9.489.5.092.682-.217.682-.482 0-.237-.008-.866-.013-1.7-2.782.603-3.369-1.342-3.369-1.342-.454-1.155-1.11-1.462-1.11-1.462-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.269 2.75 1.025A9.578 9.578 0 0112 6.836c.85.004 1.705.114 2.504.336 1.909-1.294 2.747-1.025 2.747-1.025.546 1.377.202 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.935.359.309.678.919.678 1.852 0 1.336-.012 2.415-.012 2.743 0 .267.18.578.688.48C19.138 20.161 22 16.416 22 12c0-5.523-4.477-10-10-10z" />
                  </svg>
                </button>
                
                <button
                  type="button"
                  onClick={() => handleOauthSignup('microsoft')}
                  className="flex items-center justify-center w-12 h-12 bg-background border border-gray-700 rounded-full hover:bg-gray-800 transition-colors duration-200"
                  aria-label="Sign up with Microsoft"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="#f25022" d="M11.4 4H4v7.4h7.4V4z"/>
                    <path fill="#00a4ef" d="M11.4 12.6H4V20h7.4v-7.4z"/>
                    <path fill="#7fba00" d="M20 4h-7.4v7.4H20V4z"/>
                    <path fill="#ffb900" d="M20 12.6h-7.4V20H20v-7.4z"/>
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
