import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { LightBulbIcon, ShieldCheckIcon, CodeBracketIcon } from '@heroicons/react/24/outline';
import CodeTabs from './CodeTabs';
import BusinessEnterpriseBanner from './BusinessEnterpriseBanner';

const SdkGuides = () => {
  const [activeSDK, setActiveSDK] = useState('node');

  const customStyle = {
    ...vscDarkPlus,
    'pre[class*="language-"]': {
      ...vscDarkPlus['pre[class*="language-"]'],
      background: 'transparent',
      margin: 0
    },
    'code[class*="language-"]': {
      ...vscDarkPlus['code[class*="language-"]'],
      background: 'transparent'
    }
  };

  const installationSnippets = {
    'Node.js': {
      language: 'bash',
      code: `npm install @hpkv/rioc`
    },
    'Python': {
      language: 'bash',
      code: `pip install hpkv-rioc`
    },
    '.NET': {
      language: 'bash',
      code: `dotnet add package HPKV.RIOC`
    }
  };

  const usageExamples = {
    'Node.js': {
      language: 'javascript',
      code: `const { RiocClient, RiocConfig } = require('@hpkv/rioc');

// Create a client
const config = new RiocConfig({
  host: 'your-hpkv-host.com',
  port: 8080,
  apiKey: 'YOUR_API_KEY'
});
const client = new RiocClient(config);

try {
  // Basic operations
  const key = Buffer.from('user:123');
  const value = Buffer.from('John Doe');
  
  // Insert a record
  client.insert(key, value, RiocClient.getTimestamp());
  
  // Get a record
  const retrievedValue = client.get(key);
  console.log(retrievedValue.toString()); // "John Doe"
  
  // Atomic increment/decrement
  const counterKey = Buffer.from('counter:visits');
  // First create the counter if it doesn't exist
  client.insert(counterKey, Buffer.from('0'), RiocClient.getTimestamp());
  // Increment the counter
  const newCount = client.atomicIncDec(counterKey, 1, RiocClient.getTimestamp());
  console.log('Visit count:', newCount); // 1
  
  // Range query - get all users from 100 to 200
  const startKey = Buffer.from('user:100');
  const endKey = Buffer.from('user:200');
  const results = client.rangeQuery(startKey, endKey, 50); // limit to 50 records
  
  for (const { key, value } of results) {
    console.log(\`\${key.toString()}: \${value.toString()}\`);
  }
  
  // Batch operations
  const batch = client.createBatch();
  batch.addGet(Buffer.from('user:123'));
  batch.addRangeQuery(startKey, endKey, 10);
  
  const tracker = batch.executeAsync();
  tracker.wait();
  
  // Get results from the batch
  const batchGetResult = tracker.getResponse(0);
  const batchRangeResult = tracker.getRangeQueryResponse(1);
  
  console.log(batchGetResult.toString());
  console.log(\`Found \${batchRangeResult.length} records in range\`);
  
  // Clean up
  batch.dispose();
  tracker.dispose();
} finally {
  // Always dispose the client when done
  client.dispose();
}`
    },
    'Python': {
      language: 'python',
      code: `from hpkv.rioc import RiocClient, RiocConfig

# Create a client
config = RiocConfig(
    host="your-hpkv-host.com",
    port=8080,
    api_key="YOUR_API_KEY"
)
client = RiocClient(config)

try:
    # Basic operations
    # Insert a record
    client.insert_string("user:123", "John Doe")
    
    # Get a record
    value = client.get_string("user:123")
    print(value)  # "John Doe"
    
    # Atomic increment/decrement
    # First create the counter if it doesn't exist
    client.insert_string("counter:visits", "0")
    # Increment the counter
    new_count = client.atomic_inc_dec_string("counter:visits", 1, client.get_timestamp())
    print(f"Visit count: {new_count}")  # 1
    
    # Range query - get all users from 100 to 200
    results = client.range_query_string("user:100", "user:200", limit=50)
    
    for key, value in results:
        print(f"{key}: {value}")
    
    # Batch operations
    batch = client.create_batch()
    batch.add_get_string("user:123")
    batch.add_range_query_string("user:100", "user:200", limit=10)
    
    tracker = batch.execute_async()
    tracker.wait()
    
    # Get results from the batch
    batch_get_result = tracker.get_response_string(0)
    batch_range_result = tracker.get_range_query_response_string(1)
    
    print(batch_get_result)
    print(f"Found {len(batch_range_result)} records in range")
    
finally:
    # Always close the client when done
    client.close()`
    },
    '.NET': {
      language: 'csharp',
      code: `using HPKV.RIOC;

// Create a client
var config = new RiocConfig
{
    Host = "your-hpkv-host.com",
    Port = 8080,
    ApiKey = "YOUR_API_KEY"
};

using var client = new RiocClient(config);

// Basic operations
// Insert a record
client.InsertString("user:123", "John Doe", RiocClient.GetTimestamp());

// Get a record
string value = client.GetString("user:123");
Console.WriteLine(value);  // "John Doe"

// Atomic increment/decrement
// First create the counter if it doesn't exist
client.InsertString("counter:visits", "0", RiocClient.GetTimestamp());
// Increment the counter
long newCount = client.AtomicIncDecString("counter:visits", 1, RiocClient.GetTimestamp());
Console.WriteLine($"Visit count: {newCount}");  // 1

// Range query - get all users from 100 to 200
var results = client.RangeQueryString("user:100", "user:200", limit: 50);

foreach (var kvp in results)
{
    Console.WriteLine($"{kvp.Key}: {kvp.Value}");
}

// Batch operations
using var batch = client.CreateBatch();
batch.AddGetString("user:123");
batch.AddRangeQueryString("user:100", "user:200", limit: 10);

using var tracker = batch.ExecuteAsync();
tracker.Wait();

// Get results from the batch
string batchGetResult = tracker.GetResponseString(0);
var batchRangeResult = tracker.GetRangeQueryResponseString(1);

Console.WriteLine(batchGetResult);
Console.WriteLine($"Found {batchRangeResult.Count} records in range");`
    }
  };

  const NodeSDKDocs = () => (
    <div className="space-y-6">
      <h3 id="nodejs-sdk" className="text-xl font-bold">Node.js SDK Reference</h3>
      
      <h4 className="text-lg font-bold mt-6">RiocClient</h4>
      <p>The main client class for interacting with HPKV through RIOC.</p>

      <h5 className="font-bold mt-4">Constructor</h5>
      <SyntaxHighlighter language="typescript" style={customStyle}>
        {`class RiocClient {
  constructor(config: RiocConfig);
}`}
      </SyntaxHighlighter>
      <p>Creates a new RIOC client with the specified configuration.</p>

      <h5 className="font-bold mt-4">Properties</h5>
      <SyntaxHighlighter language="typescript" style={customStyle}>
        {`class RiocClient {
  readonly isDisposed: boolean;  // Whether the client has been disposed
}`}
      </SyntaxHighlighter>

      <h5 className="font-bold mt-4">Methods</h5>
      <SyntaxHighlighter language="typescript" style={customStyle}>
        {`class RiocClient {
  /**
   * Gets a timestamp for write operations.
   * This method provides accurate timing for operations, though using local system time is also acceptable.
   */
  static getTimestamp(): bigint;
  
  /**
   * Retrieves the value associated with the specified key
   */
  get(key: Buffer): Buffer;
  
  /**
   * Retrieves multiple records within a key range
   */
  rangeQuery(startKey: Buffer, endKey: Buffer, limit?: number): { key: Buffer, value: Buffer }[];
  
  /**
   * Inserts or updates a key-value pair
   */
  insert(key: Buffer, value: Buffer, timestamp: bigint): void;
  
  /**
   * Deletes the specified key
   */
  delete(key: Buffer, timestamp: bigint): void;
  
  /**
   * Atomically increments or decrements a numeric value
   * @param key The key of the value to increment/decrement
   * @param increment The amount to increment (positive) or decrement (negative)
   * @param timestamp The operation timestamp
   * @returns The new value after the operation
   */
  atomicIncDec(key: Buffer, increment: number, timestamp: bigint): number;
  
  /**
   * Creates a new batch for executing multiple operations
   */
  createBatch(): RiocBatch;
  
  /**
   * Disposes of the client and releases all resources
   */
  dispose(): void;
}`}
      </SyntaxHighlighter>

      <h5 className="font-bold mt-4">String Helper Methods</h5>
      <SyntaxHighlighter language="typescript" style={customStyle}>
        {`class RiocClient {
  /**
   * Gets a value as a string
   */
  getString(key: string): string;
  
  /**
   * Inserts a string value
   */
  insertString(key: string, value: string, timestamp: bigint): void;
  
  /**
   * Deletes a key (string version)
   */
  deleteString(key: string, timestamp: bigint): void;
  
  /**
   * Performs a range query with string keys and values
   */
  rangeQueryString(startKey: string, endKey: string, limit?: number): { key: string, value: string }[];
  
  /**
   * Atomically increments or decrements a numeric value (string version)
   * @returns The new value after the operation
   */
  atomicIncDecString(key: string, increment: number, timestamp: bigint): number;
}`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">RiocBatch</h4>
      <p>Represents a batch of operations to be executed together.</p>

      <h5 className="font-bold mt-4">Methods</h5>
      <SyntaxHighlighter language="typescript" style={customStyle}>
        {`class RiocBatch {
  /**
   * Adds a get operation to the batch
   */
  addGet(key: Buffer): void;
  
  /**
   * Adds a range query operation to the batch
   */
  addRangeQuery(startKey: Buffer, endKey: Buffer, limit?: number): void;
  
  /**
   * Adds an insert operation
   */
  addInsert(key: Buffer, value: Buffer, timestamp: bigint): void;
  
  /**
   * Adds a delete operation
   */
  addDelete(key: Buffer, timestamp: bigint): void;
  
  /**
   * Executes the batch asynchronously
   */
  executeAsync(): RiocBatchTracker;
  
  /**
   * Disposes of the batch and releases all resources
   */
  dispose(): void;
}`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">RiocBatchTracker</h4>
      <p>Tracks the execution of a batch operation.</p>

      <h5 className="font-bold mt-4">Methods</h5>
      <SyntaxHighlighter language="typescript" style={customStyle}>
        {`class RiocBatchTracker {
  /**
   * Waits for the batch operation to complete
   */
  wait(timeoutMs: number): void;
  
  /**
   * Gets the response for a GET operation
   */
  getResponse(index: number): Buffer;
  
  /**
   * Gets the response for a RANGE QUERY operation
   */
  getRangeQueryResponse(index: number): { key: Buffer, value: Buffer }[];
  
  /**
   * Disposes of the tracker and releases all resources
   */
  dispose(): void;
}`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">Configuration Types</h4>
      
      <h5 className="font-bold mt-4">RiocConfig</h5>
      <SyntaxHighlighter language="typescript" style={customStyle}>
        {`interface RiocConfig {
  host: string;          // The host to connect to
  port: number;          // The port to connect to
  timeoutMs?: number;    // Operation timeout in milliseconds (default: 5000)
  tls?: RiocTlsConfig;  // TLS configuration
}`}
      </SyntaxHighlighter>

      <h5 className="font-bold mt-4">RiocTlsConfig</h5>
      <SyntaxHighlighter language="typescript" style={customStyle}>
        {`interface RiocTlsConfig {
  caPath: string;           // CA certificate path
  certificatePath: string;  // Client certificate path
  keyPath: string;         // Client private key path
  verifyHostname: string;  // Server hostname to verify
  verifyPeer: boolean;     // Enable certificate verification
}`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">Error Types</h4>
      <ul className="space-y-4">
        <li>
          <code>RiocError</code>
          <p>Base error class for all RIOC errors.</p>
        </li>
        <li>
          <code>RiocTimeoutError</code>
          <p>Thrown when an operation times out.</p>
        </li>
        <li>
          <code>RiocConnectionError</code>
          <p>Thrown when there are connection issues.</p>
        </li>
      </ul>
    </div>
  );

  const PythonSDKDocs = () => (
    <div className="space-y-6">
      <h3 id="python-sdk" className="text-xl font-bold">Python SDK Reference</h3>
      
      <h4 className="text-lg font-bold mt-6">RiocClient</h4>
      <p>The main client class for interacting with HPKV through RIOC.</p>

      <h5 className="font-bold mt-4">Constructor</h5>
      <SyntaxHighlighter language="python" style={customStyle}>
        {`class RiocClient:
    def __init__(self, config: RiocConfig):
        """Creates a new RIOC client with the specified configuration."""`}
      </SyntaxHighlighter>
      <p>Creates a new RIOC client with the specified configuration.</p>

      <h5 className="font-bold mt-4">Methods</h5>
      <SyntaxHighlighter language="python" style={customStyle}>
        {`class RiocClient:
    @staticmethod
    def get_timestamp() -> int:
        """
        Gets a timestamp for write operations.
        This method provides accurate timing for operations, though using local system time is also acceptable.
        """
        
    """
    Retrieves the value associated with the specified key.
    """
    def get(self, key: bytes) -> bytes:
        
    """
    Retrieves the value as a UTF-8 string.
    """
    def get_string(self, key: str) -> str:
        
    """
    Retrieves multiple records within a key range.
    """
    def range_query(self, start_key: bytes, end_key: bytes, limit: Optional[int] = None) -> List[Tuple[bytes, bytes]]:
        
    """
    Retrieves multiple records within a key range using UTF-8 strings.
    """
    def range_query_string(self, start_key: str, end_key: str, limit: Optional[int] = None) -> List[Tuple[str, str]]:
        
    """
    Inserts or updates a key-value pair.
    """
    def insert(self, key: bytes, value: bytes, timestamp: Optional[int] = None) -> None:
        
    """
    Inserts or updates using UTF-8 strings.
    """
    def insert_string(self, key: str, value: str, timestamp: Optional[int] = None) -> None:
        
    """
    Deletes the specified key.
    """
    def delete(self, key: bytes, timestamp: Optional[int] = None) -> None:
        
    """
    Deletes using a UTF-8 string key.
    """
    def delete_string(self, key: str, timestamp: Optional[int] = None) -> None:
        
    """
    Creates a new batch for executing multiple operations.
    """
    def create_batch(self) -> RiocBatch:
        
    """
    Context manager for batch operations.
    """
    @contextmanager
    def batch(self) -> Generator[RiocBatch, None, None]:
        
    """
    Atomically increments or decrements a numeric value.
    """
    def atomic_inc_dec(self, key: bytes, increment: int, timestamp: int) -> int:
        
    # String helper methods
    def range_query_string(self, start_key: str, end_key: str, limit: int = 100) -> List[Tuple[str, str]]:
        """
        Retrieves multiple records within a key range using UTF-8 strings.
        """
        
    def atomic_inc_dec_string(self, key: str, increment: int, timestamp: int) -> int:
        """
        Atomically increments or decrements a numeric value (string version)
        
        Returns:
            The new value after the operation
        """`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">RiocBatch</h4>
      <p>Represents a batch of operations to be executed together.</p>

      <h5 className="font-bold mt-4">Methods</h5>
      <SyntaxHighlighter language="python" style={customStyle}>
        {`class RiocBatch:
    """
    Adds a get operation to the batch.
    """
    def add_get(self, key: bytes) -> None:
        
    """
    Adds a get operation using a UTF-8 string key.
    """
    def add_get_string(self, key: str) -> None:
        
    """
    Adds a range query operation to the batch.
    """
    def add_range_query(self, start_key: bytes, end_key: bytes, limit: Optional[int] = None) -> None:
        
    """
    Adds a range query operation using UTF-8 string keys.
    """
    def add_range_query_string(self, start_key: str, end_key: str, limit: Optional[int] = None) -> None:
        
    """
    Adds an insert operation to the batch.
    """
    def add_insert(self, key: bytes, value: bytes, timestamp: Optional[int] = None) -> None:

    """
    Adds a delete operation to the batch.
    """
    def add_delete(self, key: bytes, timestamp: int) -> None:
    
    """
    Executes the batch and returns a tracker.
    """
    def execute(self) -> RiocBatchTracker:`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">RiocBatchTracker</h4>
      <p>Tracks the execution of a batch operation.</p>

      <h5 className="font-bold mt-4">Methods</h5>
      <SyntaxHighlighter language="python" style={customStyle}>
        {`class RiocBatchTracker:
    """
    Waits for the batch operation to complete.
    """
    def wait(self, timeout_ms: int = -1) -> None:
    
    """
    Gets the response for a GET operation at the specified index.
    """
    def get_response(self, index: int) -> bytes:
    
    """
    Gets the response for a GET operation as a UTF-8 string.
    """
    def get_response_string(self, index: int) -> str:
    
    """
    Gets the response for a RANGE QUERY operation at the specified index.
    """
    def get_range_query_response(self, index: int) -> List[RangeQueryResult]:
    
    """
    Gets the response for a RANGE QUERY operation as UTF-8 strings.
    """
    def get_range_query_response_string(self, index: int) -> List[Tuple[str, str]]:
    
    """
    Releases all resources used by the tracker
    """
    def dispose():
        pass`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">Configuration Classes</h4>
      
      <h5 className="font-bold mt-4">RiocConfig</h5>
      <SyntaxHighlighter language="python" style={customStyle}>
        {`@dataclass
class RiocConfig:
    host: str            # The host to connect to
    port: int           # The port to connect to
    timeout_ms: int = 5000  # Operation timeout in milliseconds
    tls: Optional[RiocTlsConfig] = None  # TLS configuration`}
      </SyntaxHighlighter>

      <h5 className="font-bold mt-4">RiocTlsConfig</h5>
      <SyntaxHighlighter language="python" style={customStyle}>
        {`@dataclass
class RiocTlsConfig:
    certificate_path: str   # Client certificate path
    key_path: str          # Client private key path
    ca_path: str           # CA certificate path
    verify_hostname: str   # Server hostname to verify
    verify_peer: bool = True  # Enable certificate verification`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">Exception Types</h4>
      <ul className="space-y-4">
        <li>
          <code>RiocError</code>
          <p>Base exception for all RIOC errors.</p>
        </li>
        <li>
          <code>RiocTimeoutError</code>
          <p>Raised when an operation times out.</p>
        </li>
        <li>
          <code>RiocConnectionError</code>
          <p>Raised when there are connection issues.</p>
        </li>
      </ul>
    </div>
  );

  const DotNetSDKDocs = () => (
    <div className="space-y-6">
      <h3 id="dotnet-sdk" className="text-xl font-bold">.NET SDK Reference</h3>
      
      <h4 className="text-lg font-bold mt-6">RiocClient</h4>
      <p>The main client class for interacting with HPKV through RIOC.</p>

      <h5 className="font-bold mt-4">Constructor</h5>
      <SyntaxHighlighter language="csharp" style={customStyle}>
        {`public sealed class RiocClient : IDisposable
{
    public RiocClient(RiocConfig config, ILogger? logger = null);
}`}
      </SyntaxHighlighter>
      <p>Creates a new RIOC client with the specified configuration and optional logger.</p>

      <h5 className="font-bold mt-4">Properties</h5>
      <SyntaxHighlighter language="csharp" style={customStyle}>
        {`public sealed class RiocClient : IDisposable
{
    public bool IsDisposed { get; }  // Whether the client has been disposed
}`}
      </SyntaxHighlighter>

      <h5 className="font-bold mt-4">Methods</h5>
      <SyntaxHighlighter language="csharp" style={customStyle}>
        {`public sealed class RiocClient : IDisposable
{
    /// <summary>
    /// Gets a timestamp for write operations.
    /// This method provides accurate timing for operations, though using local system time is also acceptable.
    /// </summary>
    public static ulong GetTimestamp();
    
    /// <summary>
    /// Retrieves the value associated with the specified key
    /// </summary>
    public byte[] Get(ReadOnlySpan<byte> key);
    
    /// <summary>
    /// Retrieves the value as a UTF-8 string
    /// </summary>
    public string GetString(string key);
    
    /// <summary>
    /// Retrieves multiple records within a key range
    /// </summary>
    public List<KeyValuePair<byte[], byte[]>> RangeQuery(ReadOnlySpan<byte> startKey, ReadOnlySpan<byte> endKey, int? limit = null);
    
    /// <summary>
    /// Retrieves multiple records within a key range using UTF-8 strings
    /// </summary>
    public List<KeyValuePair<string, string>> RangeQueryString(string startKey, string endKey, int? limit = null);
    
    /// <summary>
    /// Inserts or updates a key-value pair
    /// </summary>
    public void Insert(ReadOnlySpan<byte> key, ReadOnlySpan<byte> value, ulong timestamp);
    
    /// <summary>
    /// Inserts or updates using UTF-8 strings
    /// </summary>
    public void InsertString(string key, string value, ulong timestamp);
    
    /// <summary>
    /// Deletes the specified key
    /// </summary>
    public void Delete(ReadOnlySpan<byte> key, ulong timestamp);
    
    /// <summary>
    /// Deletes using a UTF-8 string key
    /// </summary>
    public void DeleteString(string key, ulong timestamp);
    
    /// <summary>
    /// Creates a new batch for executing multiple operations
    /// </summary>
    public RiocBatch CreateBatch();
    
    /// <summary>
    /// Atomically increments or decrements a numeric value
    /// </summary>
    /// <param name="key">The key of the value to increment/decrement</param>
    /// <param name="increment">The amount to increment (positive) or decrement (negative)</param>
    /// <param name="timestamp">The operation timestamp</param>
    /// <returns>The new value after the operation</returns>
    public long AtomicIncDec(ReadOnlySpan<byte> key, long increment, ulong timestamp);
    
    /// <summary>
    /// Releases all resources used by the client
    /// </summary>
    public void Dispose();
}`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">RiocBatch</h4>
      <p>Represents a batch of operations to be executed together.</p>

      <h5 className="font-bold mt-4">Methods</h5>
      <SyntaxHighlighter language="csharp" style={customStyle}>
        {`public sealed class RiocBatch : IDisposable
{
    /// <summary>
    /// Adds a get operation to the batch
    /// </summary>
    public void AddGet(ReadOnlySpan<byte> key);
    
    /// <summary>
    /// Adds a get operation using a UTF-8 string key
    /// </summary>
    public void AddGetString(string key);
    
    /// <summary>
    /// Adds a range query operation to the batch
    /// </summary>
    public void AddRangeQuery(ReadOnlySpan<byte> startKey, ReadOnlySpan<byte> endKey, int? limit = null);
    
    /// <summary>
    /// Adds a range query operation using UTF-8 string keys
    /// </summary>
    public void AddRangeQueryString(string startKey, string endKey, int? limit = null);
    
    /// <summary>
    /// Adds an insert operation to the batch
    /// </summary>
    public void AddInsert(ReadOnlySpan<byte> key, ReadOnlySpan<byte> value, ulong timestamp);
    
    /// <summary>
    /// Adds a delete operation to the batch
    /// </summary>
    public void AddDelete(ReadOnlySpan<byte> key, ulong timestamp);
    
    /// <summary>
    /// Executes the batch asynchronously and returns a tracker
    /// </summary>
    public RiocBatchTracker ExecuteAsync();
    
    /// <summary>
    /// Releases all resources used by the batch
    /// </summary>
    public void Dispose();
}`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">RiocBatchTracker</h4>
      <p>Tracks the execution of a batch operation.</p>

      <h5 className="font-bold mt-4">Methods</h5>
      <SyntaxHighlighter language="csharp" style={customStyle}>
        {`public sealed class RiocBatchTracker : IDisposable
{
    /// <summary>
    /// Waits for the batch operation to complete with optional timeout
    /// </summary>
    public void Wait(int timeoutMs = -1);
    
    /// <summary>
    /// Gets the response for a GET operation at the specified index
    /// </summary>
    public byte[] GetResponse(int index);
    
    /// <summary>
    /// Gets the response for a GET operation as a string
    /// </summary>
    public string GetResponseString(int index);
    
    /// <summary>
    /// Gets the response for a RANGE QUERY operation at the specified index
    /// </summary>
    public List<KeyValuePair<byte[], byte[]>> GetRangeQueryResponse(int index);
    
    /// <summary>
    /// Gets the response for a RANGE QUERY operation as strings
    /// </summary>
    public List<KeyValuePair<string, string>> GetRangeQueryResponseString(int index);
    
    /// <summary>
    /// Releases all resources used by the tracker
    /// </summary>
    public void Dispose();
}`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">Configuration Classes</h4>
      
      <h5 className="font-bold mt-4">RiocConfig</h5>
      <SyntaxHighlighter language="csharp" style={customStyle}>
        {`public class RiocConfig
{
    public required string Host { get; set; }  // The host to connect to
    public required int Port { get; set; }     // The port to connect to
    public int TimeoutMs { get; set; } = 5000; // Operation timeout in milliseconds
    public RiocTlsConfig? Tls { get; set; }    // TLS configuration
}`}
      </SyntaxHighlighter>

      <h5 className="font-bold mt-4">RiocTlsConfig</h5>
      <SyntaxHighlighter language="csharp" style={customStyle}>
        {`public class RiocTlsConfig
{
    public string? CertificatePath { get; set; }  // Client certificate path
    public string? KeyPath { get; set; }          // Client private key path
    public string? CaPath { get; set; }           // CA certificate path
    public string? VerifyHostname { get; set; }   // Server hostname to verify
    public bool VerifyPeer { get; set; } = true;  // Enable certificate verification
}`}
      </SyntaxHighlighter>

      <h4 className="text-lg font-bold mt-6">Exception Types</h4>
      <ul className="space-y-4">
        <li>
          <code>RiocException</code>
          <p>Base exception for all RIOC errors.</p>
        </li>
        <li>
          <code>RiocTimeoutException</code>
          <p>Thrown when an operation times out.</p>
        </li>
        <li>
          <code>RiocConnectionException</code>
          <p>Thrown when there are connection issues.</p>
        </li>
      </ul>
    </div>
  );

  return (
    <div className="space-y-8">
      <h1 className="sr-only">HPKV SDK Guides</h1>
      <BusinessEnterpriseBanner />
      <h2 className="text-2xl font-bold mb-4">SDK Reference Documentation</h2>
      <p>
        HPKV provides official SDKs for Node.js, Python, and .NET to simplify integration with your applications. 
        These SDKs offer a native interface to RIOC (Remote IO Control), our high-performance protocol for 
        ultra-low latency operations.
      </p>

      <div className="mt-6 border border-blue-400/20 rounded-lg bg-blue-400/5">
        <div className="px-4 py-3 border-b border-blue-400/20 flex items-center gap-2">
          <ShieldCheckIcon className="h-5 w-5 text-blue-400" />
          <h4 className="font-bold text-blue-400">Security Note</h4>
        </div>
        <div className="p-4">
          <p className="text-blue-200/90">
            All SDKs use TLS 1.3 by default for secure communication. Your certificates will be automatically 
            generated and available in your Dashboard. Enterprise users can optionally disable TLS for private 
            setups where security is managed through other means.
          </p>
        </div>
      </div>

      <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
        <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
          <CodeBracketIcon className="h-5 w-5 text-indigo-400" />
          <h4 id="open-source" className="font-bold text-indigo-400">Open Source Client</h4>
        </div>
        <div className="p-4">
          <p className="text-indigo-200/90 mb-4">
            The RIOC client SDKs are available as open source software on GitHub. You can find the source code, contribute, or create custom implementations.
          </p>
          <div className="mt-2 mb-4">
            <a href="https://github.com/hpkv-io/rioc" target="_blank" rel="noopener noreferrer" className="inline-flex items-center gap-2 px-4 py-2 bg-gray-800 hover:bg-gray-700 text-white rounded-lg transition-colors duration-200 font-medium">
              <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
              </svg>
              <span>RIOC GitHub Repository</span>
            </a>
          </div>
          <p className="text-indigo-200/90">
            The repository includes client SDKs for Node.js, Python, and .NET, core API documentation, implementation examples, and client protocol specifications. All client code is licensed under the Apache License 2.0.
          </p>
        </div>
      </div>

      <h3 id="installation" className="text-xl font-bold mb-2">Installation</h3>
      <CodeTabs snippets={installationSnippets} />

      <h3 id="usage-examples" className="text-xl font-bold mt-6 mb-2">Usage Examples</h3>
      <p className="mb-4">
        Here's how to perform common operations with our SDKs:
      </p>
      <div className="rounded-lg p-4">
        <CodeTabs snippets={usageExamples} />
      </div>

      <h3 id="sdk-reference" className="text-xl font-bold mt-6 mb-2">SDK Reference</h3>
      <div className="flex space-x-4 mb-6">
        <button
          className={`px-4 py-2 rounded-md ${
            activeSDK === 'node' ? 'bg-primary text-white' : 'bg-component-bg'
          }`}
          onClick={() => setActiveSDK('node')}
        >
          Node.js
        </button>
        <button
          className={`px-4 py-2 rounded-md ${
            activeSDK === 'python' ? 'bg-primary text-white' : 'bg-component-bg'
          }`}
          onClick={() => setActiveSDK('python')}
        >
          Python
        </button>
        <button
          className={`px-4 py-2 rounded-md ${
            activeSDK === 'dotnet' ? 'bg-primary text-white' : 'bg-component-bg'
          }`}
          onClick={() => setActiveSDK('dotnet')}
        >
          .NET
        </button>
      </div>

      {activeSDK === 'node' && <NodeSDKDocs />}
      {activeSDK === 'python' && <PythonSDKDocs />}
      {activeSDK === 'dotnet' && <DotNetSDKDocs />}

      <div id="best-practices" className="mt-6 border border-emerald-400/20 rounded-lg bg-emerald-400/5">
        <div className="px-4 py-3 border-b border-emerald-400/20 flex items-center gap-2">
          <LightBulbIcon className="h-5 w-5 text-emerald-400" />
          <h4 className="font-bold text-emerald-400">Best Practices</h4>
        </div>
        <ul className="list-disc pl-10 pr-4 py-3" style={{ color: 'rgba(94, 196, 150, 1)' }}>
          <li>Use timestamps from the SDK's timestamp generation methods for write operations for accurate operation timing</li>
          <li>Implement proper error handling with specific error types</li>
          <li>Use batch operations when performing multiple related operations</li>
          <li>Use range queries for efficiently retrieving multiple related records</li>
          <li>Keep your SDK version up to date for the latest features and security updates</li>
          <li>Store TLS certificates securely and implement proper certificate rotation</li>
          <li>Use string methods (e.g., insert_string) for text data to handle encoding automatically</li>
          <li>Implement reconnection logic with exponential backoff for production applications</li>
          <li>Monitor operation latencies and implement appropriate timeout values</li>
        </ul>
      </div>
    </div>
  );
};

export default SdkGuides;