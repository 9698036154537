import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ClipboardIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';

const CodeBlock = ({ code, language, style }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="relative group">
      <button
        onClick={handleCopy}
        className="absolute right-2 top-2 p-2 rounded-lg bg-component-bg/50 hover:bg-component-bg transition-colors duration-200 opacity-0 group-hover:opacity-100 cursor-pointer"
        title={copied ? "Copied!" : "Copy to clipboard"}
      >
        {copied ? (
          <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-500" />
        ) : (
          <ClipboardIcon className="h-5 w-5 text-text-secondary hover:text-text" />
        )}
      </button>
      <SyntaxHighlighter language={language} style={style}>
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeBlock; 