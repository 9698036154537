import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowDownIcon, XMarkIcon } from '@heroicons/react/24/outline';

const MobileTabsCallout = ({ isOpen, onClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
          className="fixed bottom-20 left-6 z-50 md:hidden"
        >
          <div className="bg-accent-purple text-white px-4 py-3 rounded-lg shadow-lg max-w-xs relative">
            <div className="flex items-start">
              <div className="flex-1">
                <p className="font-medium mb-1">Navigation tabs</p>
                <p className="text-sm opacity-90">
                  Use these tabs to navigate between different sections of your dashboard
                </p>
              </div>
              <button 
                onClick={onClose}
                className="ml-2 text-white/80 hover:text-white"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
            <div className="flex justify-start mt-2">
              <ArrowDownIcon className="h-5 w-5 animate-bounce" />
            </div>
            
            {/* Triangle pointer */}
            <div className="absolute -bottom-2 left-6 w-0 h-0 border-l-8 border-r-8 border-t-8 border-t-accent-purple border-l-transparent border-r-transparent"></div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MobileTabsCallout; 