import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const NotifyDialog = ({ isOpen, onClose, planName, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ type: '', message: '' });

    try {
      const response = await fetch('/api/notify-interest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          planName,
          interactionType: 'registered'
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setStatus({
          type: 'success',
          message: data.isExisting 
            ? 'Thank you for your continued interest! We already have your information and will notify you when this plan becomes available.'
            : 'Thank you for your interest! We will notify you when this plan becomes available.'
        });
        if (!data.isExisting) {
          setEmail('');
        }
        setTimeout(() => {
          onClose();
          setStatus({ type: '', message: '' });
        }, 3000);
      } else {
        throw new Error(data.error || 'Something went wrong');
      }
    } catch (error) {
      setStatus({ type: 'error', message: error.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = async () => {
    try {
      // Track the cancellation
      await fetch('/api/notify-interest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          planName,
          interactionType: 'canceled'
        }),
      });
    } catch (error) {
      console.error('Failed to track dialog cancellation:', error);
    }
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <div className="fixed inset-0 bg-black/50 z-40" onClick={handleClose} />
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4"
          >
            <div className="bg-component-bg rounded-lg p-6 max-w-md w-full shadow-xl">
              <h3 className="text-xl font-semibold text-heading-text mb-4">
                Get notified about {planName}
              </h3>
              <p className="text-text mb-4">
                This plan will be available soon. Leave your email address, and we'll notify you when it's ready.
              </p>
              <p className="text-text mb-6 font-bold text-accent-purple">
                We will not share your email address with anyone.
              </p>
              <div className="bg-accent-purple/10 p-4 rounded-lg mb-6">
                <p className="text-text">
                  In the meantime, check out our{' '}
                  <Link to="/signup" className="font-semibold text-accent-purple hover:text-accent-blue">
                    Free Plan
                  </Link>
                  !
                </p>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-text mb-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                    placeholder="you@example.com"
                  />
                </div>

                {status.message && (
                  <div 
                    className={`p-4 rounded-lg ${
                      status.type === 'success' 
                        ? 'bg-accent-purple/10 text-accent-purple border border-accent-purple/20' 
                        : 'bg-red-500/10 text-red-500 border border-red-500/20'
                    }`}
                  >
                    {status.message}
                  </div>
                )}

                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="px-4 py-2 text-text hover:text-heading-text transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="px-4 py-2 bg-accent-purple hover:bg-accent-blue transition-colors rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? 'Submitting...' : 'Notify Me'}
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default NotifyDialog; 