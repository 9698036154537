import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import mermaid from 'mermaid';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { InformationCircleIcon, ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/24/outline';

// Initialize mermaid
mermaid.initialize({
  startOnLoad: false, // Important: disable auto-rendering
  theme: 'dark',
  themeVariables: {
    primaryColor: '#6366f1', // indigo-500
    primaryTextColor: '#f3f4f6', // gray-100
    primaryBorderColor: '#4f46e5', // indigo-600
    lineColor: '#9ca3af', // gray-400
    secondaryColor: '#4f46e5', // indigo-600
    tertiaryColor: '#312e81', // indigo-900
    textColor: '#f3f4f6', // gray-100
    mainBkg: '#1f2937', // gray-800
    nodeBorder: '#4f46e5', // indigo-600
    clusterBkg: '#1f2937', // gray-800
    titleColor: '#f3f4f6', // gray-100
    edgeLabelBackground: '#1f2937', // gray-800
    nodeTextColor: '#f3f4f6', // gray-100
  },
  securityLevel: 'loose',
  fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
});

// Static counter for unique mermaid IDs
let diagramIdCounter = 0;

const MermaidRenderer = ({ content }) => {
  const [svg, setSvg] = useState('');
  const [id] = useState(`mermaid-${diagramIdCounter++}`);
  
  useEffect(() => {
    try {
      // Render the mermaid diagram to SVG
      mermaid.render(id, content).then(result => {
        setSvg(result.svg);
      });
    } catch (error) {
      console.error('Mermaid rendering error:', error);
    }
  }, [content, id]);

  // Directly insert the rendered SVG
  return (
    <div 
      className="my-8 p-4 rounded-lg bg-component-bg shadow-lg flex justify-center"
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

const AdmonitionIcon = ({ type }) => {
  switch (type.toUpperCase()) {
    case 'NOTE':
      return <InformationCircleIcon className="h-6 w-6 text-blue-400" />;
    case 'WARNING':
      return <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400" />;
    case 'DANGER':
      return <XCircleIcon className="h-6 w-6 text-red-400" />;
    default:
      return <InformationCircleIcon className="h-6 w-6 text-blue-400" />;
  }
};

export default function MarkdownRenderer({ content }) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          const language = match ? match[1] : '';

          if (language === 'mermaid') {
            return <MermaidRenderer content={String(children)} />;
          }

          // Check if this is a standalone code block (not inline and no parent paragraph)
          const isStandaloneCode = !inline && (!node.position?.start?.line || node.position.start.line !== node.position.end.line);

          if (isStandaloneCode) {
            return (
              <SyntaxHighlighter
                style={oneDark}
                language={language}
                PreTag="div"
                className="my-4 rounded-lg"
                {...props}
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            );
          }

          // For inline code or single-line code blocks
          return (
            <code className="px-1.5 py-0.5 rounded bg-gray-800/50 font-mono text-sm inline-block" {...props}>
              {children}
            </code>
          );
        },
        h1: ({ node, children, ...props }) => <h1 className="text-4xl font-bold mt-8 mb-4 text-heading-text" {...props}>{children}</h1>,
        h2: ({ node, children, ...props }) => <h2 className="text-3xl font-bold mt-6 mb-3 text-heading-text" {...props}>{children}</h2>,
        h3: ({ node, children, ...props }) => <h3 className="text-2xl font-bold mt-4 mb-2 text-heading-text" {...props}>{children}</h3>,
        h4: ({ node, children, ...props }) => <h4 className="text-xl font-bold mt-4 mb-2 text-heading-text" {...props}>{children}</h4>,
        p: ({ node, children, ...props }) => {
          // Check if this is an admonition
          if (typeof children === 'string' && children.startsWith('[!')) {
            const match = children.match(/^\[!(.*?)\]/);
            if (match) {
              const type = match[1];
              const content = children.slice(match[0].length).trim();
              
              let bgColor = 'bg-blue-400/10';
              let borderColor = 'border-blue-400/20';
              switch (type.toUpperCase()) {
                case 'WARNING':
                  bgColor = 'bg-yellow-400/10';
                  borderColor = 'border-yellow-400/20';
                  break;
                case 'DANGER':
                  bgColor = 'bg-red-400/10';
                  borderColor = 'border-red-400/20';
                  break;
                case 'NOTE':
                default:
                  bgColor = 'bg-blue-400/10';
                  borderColor = 'border-blue-400/20';
                  break;
              }

              return (
                <div className={`my-4 border rounded-lg ${borderColor} ${bgColor}`}>
                  <div className={`px-4 py-3 border-b ${borderColor} flex items-center gap-2`}>
                    <AdmonitionIcon type={type} />
                    <h4 className="font-bold text-lg text-blue-400">{type}</h4>
                  </div>
                  <div className="p-4 italic">
                    {content}
                  </div>
                </div>
              );
            }
          }
          
          return <p className="text-text mb-4 leading-relaxed" {...props}>{children}</p>;
        },
        ul: ({ node, children, ...props }) => (
          <ul className="list-disc list-inside mb-4 space-y-2" {...props}>
            {React.Children.map(children, child => {
              if (React.isValidElement(child) && child.type === 'li') {
                // Check if this is a section header (ends with a colon)
                const isHeader = typeof child.props.children === 'string' && child.props.children.trim().endsWith(':');
                
                if (isHeader) {
                  return React.cloneElement(child, {
                    className: 'text-heading-text font-bold text-xl mt-6 mb-2 list-none',
                    children: child.props.children
                  });
                }
                
                return React.cloneElement(child, {
                  className: 'text-text',
                  children: child.props.children
                });
              }
              return child;
            })}
          </ul>
        ),
        ol: ({ node, children, ...props }) => (
          <ol className="list-decimal list-inside mb-4 space-y-2" {...props}>
            {React.Children.map(children, child => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  className: 'text-text pl-1'
                });
              }
              return child;
            })}
          </ol>
        ),
        li: ({ node, children, ...props }) => <li {...props}>{children}</li>,
        a: ({ node, children, href, ...props }) => (
          <a
            href={href}
            className="text-accent-purple hover:text-accent-blue transition-colors duration-200"
            {...props}
          >
            {children}
          </a>
        ),
        blockquote: ({ node, children, ...props }) => {
          // Check if this is part of an admonition
          const isAdmonitionContent = node.children?.[0]?.type === 'paragraph' && 
                                    node.children[0].children?.[0]?.value?.startsWith('[!');
          if (isAdmonitionContent) {
            return children;
          }

          return (
            <blockquote
              className="border-l-4 border-accent-purple pl-4 my-4 italic text-text"
              {...props}
            >
              {children}
            </blockquote>
          );
        },
        img: ({ node, src, alt = '', ...props }) => (
          <img
            src={src}
            alt={alt}
            className="max-w-full h-auto rounded-lg my-4"
            {...props}
            loading="lazy"
          />
        ),
        table: ({ node, children, ...props }) => (
          <div className="overflow-x-auto my-8">
            <table className="min-w-full divide-y divide-gray-700" {...props}>
              {children}
            </table>
          </div>
        ),
        thead: ({ node, children, ...props }) => (
          <thead className="bg-component-bg" {...props}>
            {children}
          </thead>
        ),
        tbody: ({ node, children, ...props }) => (
          <tbody className="divide-y divide-gray-700 bg-component-bg" {...props}>
            {children}
          </tbody>
        ),
        tr: ({ node, children, ...props }) => (
          <tr className="hover:bg-gray-800 transition-colors" {...props}>
            {children}
          </tr>
        ),
        th: ({ node, children, ...props }) => (
          <th className="px-6 py-3 text-left text-sm font-semibold text-heading-text uppercase tracking-wider whitespace-nowrap" {...props}>
            {children}
          </th>
        ),
        td: ({ node, children, ...props }) => (
          <td className="px-6 py-4 text-sm text-text whitespace-nowrap" {...props}>
            {children}
          </td>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );
} 