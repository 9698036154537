import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import WebSocketApi from '../components/Docs/WebSocketApi';
import { Helmet } from 'react-helmet';

const WebSocketApiLanding = () => {
  return (
    <div className="min-h-screen bg-background">
      <Helmet>
        <title>HPKV WebSocket API - Real-time Key-Value Store Interface</title>
        <meta name="description" content="Integrate with HPKV's bidirectional WebSocket API for real-time data operations with ultra-low latency. Establish persistent connections for pub-sub, notifications, and high-performance data streaming in your applications." />
        <meta name="keywords" content="HPKV WebSocket API, real-time database, websocket key-value store, persistent connection database, websocket pub-sub, websocket js, websocket latency, low latency websocket, redis alternative websocket, realtime websocket, websocket api" />
        <link rel="canonical" href="https://hpkv.io/websocket-api" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hpkv.io/websocket-api" />
        <meta property="og:title" content="HPKV WebSocket API - Real-time Key-Value Store Interface" />
        <meta property="og:description" content="Integrate with HPKV's bidirectional WebSocket API for real-time data operations with ultra-low latency. Establish persistent connections for pub-sub, notifications, and high-performance data streaming." />
        <meta property="og:image" content="https://hpkv.io/images/hpkv-illustration-no-subtext.png" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hpkvHQ" />
        <meta name="twitter:title" content="HPKV WebSocket API - Real-time Key-Value Store Interface" />
        <meta name="twitter:description" content="Integrate with HPKV's bidirectional WebSocket API for real-time data operations with ultra-low latency. Establish persistent connections for pub-sub, notifications, and high-performance data streaming." />
        <meta name="twitter:image" content="https://hpkv.io/images/hpkv-illustration-no-subtext.png" />
      </Helmet>

      {/* Hero Section */}
      <div className="bg-gradient-to-b from-primary/10 to-background pt-24 pb-12">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-heading-text mb-6">
              HPKV WebSocket API
            </h1>
            <p className="text-xl text-text-secondary mb-8">
              Real-time, bidirectional communication for high-performance data operations
            </p>
            <div className="flex justify-center gap-4">
              <Link 
                to="/signup" 
                className="inline-flex items-center px-6 py-3 rounded-lg bg-primary text-white hover:bg-primary/90 transition-colors"
              >
                Get Started
                <ArrowRightIcon className="w-5 h-5 ml-2" />
              </Link>
              <Link 
                to="/docs/websocket-api" 
                className="inline-flex items-center px-6 py-3 rounded-lg border border-primary/20 hover:bg-primary/10 transition-colors"
              >
                View Documentation
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Key Features */}
      <div className="py-12 bg-component-bg/50">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="p-6 rounded-lg bg-component-bg">
              <h3 className="text-xl font-bold mb-3">Persistent Connection</h3>
              <p>Maintain a single connection for multiple operations, reducing overhead and latency.</p>
            </div>
            <div className="p-6 rounded-lg bg-component-bg">
              <h3 className="text-xl font-bold mb-3">Real-time Operations</h3>
              <p>Instant data operations with bidirectional communication for modern real-time applications.</p>
            </div>
            <div className="p-6 rounded-lg bg-component-bg">
              <h3 className="text-xl font-bold mb-3">Efficient Protocol</h3>
              <p>Optimized message format and handling for maximum performance and minimal bandwidth usage.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Performance Stats */}
      <div className="py-12">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-8 text-center">
            <div>
              <div className="text-3xl font-bold text-primary mb-2">~50ms</div>
              <div className="text-text-secondary">Average Latency</div>
            </div>
            <div>
              <div className="text-3xl font-bold text-primary mb-2">99.99%</div>
              <div className="text-text-secondary">Uptime</div>
            </div>
            <div>
              <div className="text-3xl font-bold text-primary mb-2">10k+</div>
              <div className="text-text-secondary">Concurrent Connections</div>
            </div>
            <div>
              <div className="text-3xl font-bold text-primary mb-2">Global</div>
              <div className="text-text-secondary">Distribution</div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <WebSocketApi />
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-primary/5 py-12">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Build Real-time Applications with HPKV</h2>
          <p className="text-lg mb-8">Experience the power of WebSocket-based real-time data operations.</p>
          <Link 
            to="/signup" 
            className="inline-flex items-center px-8 py-4 rounded-lg bg-primary text-white hover:bg-primary/90 transition-colors text-lg"
          >
            Start Building Now
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WebSocketApiLanding; 