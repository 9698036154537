import React from 'react';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CodeTabs from './CodeTabs';
import CodeBlock from './CodeBlock';

const RestApi = () => {
  // Create a modified theme without background color
  const customStyle = {
    ...vscDarkPlus,
    'pre[class*="language-"]': {
      ...vscDarkPlus['pre[class*="language-"]'],
      background: 'transparent',
      margin: 0
    },
    'code[class*="language-"]': {
      ...vscDarkPlus['code[class*="language-"]'],
      background: 'transparent'
    }
  };

  const insertSnippets = {
    Python: {
      language: 'python',
      code: `import requests

response = requests.post(
    f"{base_url}/record",
    headers={
        "Content-Type": "application/json",
        "x-api-key": "YOUR_API_KEY"
    },
    json={
        "key": "user:123",
        "value": "John Doe"
    }
)`
    },
    JavaScript: {
      language: 'javascript',
      code: `await fetch(\`\${baseUrl}/record\`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': 'YOUR_API_KEY'
  },
  body: JSON.stringify({
    key: 'user:123',
    value: 'John Doe'
  })
})`
    },
    Java: {
      language: 'java',
      code: `HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create(baseUrl + "/record"))
    .header("Content-Type", "application/json")
    .header("x-api-key", "YOUR_API_KEY")
    .POST(HttpRequest.BodyPublishers.ofString(
        '{"key":"user:123","value":"John Doe"}'
    ))
    .build();
HttpResponse<String> response = 
    client.send(request, HttpResponse.BodyHandlers.ofString());`
    },
    'C#': {
      language: 'csharp',
      code: `using var client = new HttpClient();
client.DefaultRequestHeaders.Add("x-api-key", "YOUR_API_KEY");

var response = await client.PostAsJsonAsync(
    $"{baseUrl}/record",
    new {
        key = "user:123",
        value = "John Doe"
    }
);`
    }
  };

  const getSnippets = {
    Python: {
      language: 'python',
      code: `response = requests.get(
    f"{base_url}/record/user:123",
    headers={"x-api-key": "YOUR_API_KEY"}
)`
    },
    JavaScript: {
      language: 'javascript',
      code: `const response = await fetch(
  \`\${baseUrl}/record/user:123\`,
  {
    headers: {
      'x-api-key': 'YOUR_API_KEY'
    }
  }
);`
    },
    Java: {
      language: 'java',
      code: `HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create(baseUrl + "/record/user:123"))
    .header("x-api-key", "YOUR_API_KEY")
    .GET()
    .build();
HttpResponse<String> response = 
    client.send(request, HttpResponse.BodyHandlers.ofString());`
    },
    'C#': {
      language: 'csharp',
      code: `using var client = new HttpClient();
client.DefaultRequestHeaders.Add("x-api-key", "YOUR_API_KEY");

var response = await client.GetAsync(
    $"{baseUrl}/record/user:123"
);`
    }
  };

  const deleteSnippets = {
    Python: {
      language: 'python',
      code: `response = requests.delete(
    f"{base_url}/record/user:123",
    headers={"x-api-key": "YOUR_API_KEY"}
)`
    },
    JavaScript: {
      language: 'javascript',
      code: `const response = await fetch(
  \`\${baseUrl}/record/user:123\`,
  {
    method: 'DELETE',
    headers: {
      'x-api-key': 'YOUR_API_KEY'
    }
  }
);`
    },
    Java: {
      language: 'java',
      code: `HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create(baseUrl + "/record/user:123"))
    .header("x-api-key", "YOUR_API_KEY")
    .DELETE()
    .build();
HttpResponse<String> response = 
    client.send(request, HttpResponse.BodyHandlers.ofString());`
    },
    'C#': {
      language: 'csharp',
      code: `using var client = new HttpClient();
client.DefaultRequestHeaders.Add("x-api-key", "YOUR_API_KEY");

var response = await client.DeleteAsync(
    $"{baseUrl}/record/user:123"
);`
    }
  };

  const rangeQuerySnippets = {
    Python: {
      language: 'python',
      code: `response = requests.get(
    f"{base_url}/records",
    headers={"x-api-key": "YOUR_API_KEY"},
    params={
        "startKey": "user:100",
        "endKey": "user:200",
        "limit": 50
    }
)`
    },
    JavaScript: {
      language: 'javascript',
      code: `const response = await fetch(
  \`\${baseUrl}/records?startKey=user:100&endKey=user:200&limit=50\`,
  {
    headers: {
      'x-api-key': 'YOUR_API_KEY'
    }
  }
);`
    },
    Java: {
      language: 'java',
      code: `HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create(baseUrl + "/records?startKey=user:100&endKey=user:200&limit=50"))
    .header("x-api-key", "YOUR_API_KEY")
    .GET()
    .build();
HttpResponse<String> response = 
    client.send(request, HttpResponse.BodyHandlers.ofString());`
    },
    'C#': {
      language: 'csharp',
      code: `using var client = new HttpClient();
client.DefaultRequestHeaders.Add("x-api-key", "YOUR_API_KEY");

var response = await client.GetAsync(
    $"{baseUrl}/records?startKey=user:100&endKey=user:200&limit=50"
);`
    }
  };

  const atomicIncrementSnippets = {
    Python: {
      language: 'python',
      code: `response = requests.post(
    f"{base_url}/record/atomic",
    headers={
        "Content-Type": "application/json",
        "x-api-key": "YOUR_API_KEY"
    },
    json={
        "key": "counter:123",
        "increment": 1  # Use negative value to decrement
    }
)`
    },
    JavaScript: {
      language: 'javascript',
      code: `await fetch(\`\${baseUrl}/record/atomic\`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': 'YOUR_API_KEY'
  },
  body: JSON.stringify({
    key: 'counter:123',
    increment: 1  // Use negative value to decrement
  })
})`
    },
    Java: {
      language: 'java',
      code: `HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create(baseUrl + "/record/atomic"))
    .header("Content-Type", "application/json")
    .header("x-api-key", "YOUR_API_KEY")
    .POST(HttpRequest.BodyPublishers.ofString(
        '{"key":"counter:123","increment":1}'  // Use negative value to decrement
    ))
    .build();
HttpResponse<String> response = 
    client.send(request, HttpResponse.BodyHandlers.ofString());`
    },
    'C#': {
      language: 'csharp',
      code: `using var client = new HttpClient();
client.DefaultRequestHeaders.Add("x-api-key", "YOUR_API_KEY");

var response = await client.PostAsJsonAsync(
    $"{baseUrl}/record/atomic",
    new {
        key = "counter:123",
        increment = 1  // Use negative value to decrement
    }
);`
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="sr-only">HPKV REST API</h1>
      <h2 className="text-2xl font-bold mb-4">REST API</h2>
      <p>
        The REST API provides a simple HTTP interface for interacting with HPKV. 
        All requests require your API key in the <code className="bg-component-bg text-text p-1 rounded">x-api-key</code> header.
      </p>

      <h3 id="base-url" className="text-xl font-bold mt-6 mb-2">Base URL</h3>
      <p>
        Your base URL is provided when you generate an API key. All endpoints are relative to this URL.
      </p>

      <h3 id="endpoints" className="text-xl font-bold mt-6 mb-2">Endpoints</h3>

      <div className="space-y-8">
        <div>
          <h4 id="insert-update" className="text-lg font-bold mb-2">1. Insert/Update/JSON Patch a Record</h4>
          <p className="text-sm text-text-secondary mb-2">
            This endpoint supports inserting, updating, and JSON patching a record. If the record already exists, the operation will update the existing value. If the partialUpdate parameter is set to true, the operation will apply a JSON patch to the existing value or append to the existing value if it is not valid JSON.
          </p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="http"
              style={customStyle}
              code={`POST /record
Content-Type: application/json
x-api-key: YOUR_API_KEY`}
            />
          </div>
          <p className="mt-2"><strong>Request Body:</strong></p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="json"
              style={customStyle}
              code={`{
  "key": "string",       // Required: Key to store
  "value": "string",     // Required: Value to store
  "partialUpdate": bool  // Optional: Append to existing value or apply JSON atomic patch if both values are valid JSON
}`}
            />
          </div>

          <div className="mt-4">
            <CodeTabs snippets={insertSnippets} />
          </div>
          
          <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
            <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
              <LightBulbIcon className="h-5 w-5 text-indigo-400" />
              <h4 className="font-bold text-indigo-400">JSON Atomic Patching</h4>
            </div>
            <div className="p-4">
              <p className="text-indigo-200/90">
                When using partial updates, if both the existing stored value and the new value are valid JSON objects, HPKV will automatically perform a JSON patch operation instead of simple appending. This merges the JSON objects, updating existing fields and adding new ones.
              </p>
              <p className="text-indigo-200/90 mt-2">
                For example, if the stored value is <code className="bg-component-bg text-text p-1 rounded">{`{"name":"John","age":25}`}</code> and you send a partial update with <code className="bg-component-bg text-text p-1 rounded">{`{"age":30,"city":"New York"}`}</code>, the resulting value will be <code className="bg-component-bg text-text p-1 rounded">{`{"name":"John","age":30,"city":"New York"}`}</code>.
              </p>
              <p className="text-indigo-200/90 mt-2">
                If either value is not valid JSON, the system falls back to the standard append behavior.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h4 id="atomic-increment" className="text-lg font-bold mb-2">2. Atomic Increment/Decrement</h4>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="http"
              style={customStyle}
              code={`POST /record/atomic
Content-Type: application/json
x-api-key: YOUR_API_KEY`}
            />
          </div>
          <p className="mt-2"><strong>Request Body:</strong></p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="json"
              style={customStyle}
              code={`{
  "key": "string",      // Required: Key to increment/decrement
  "increment": number   // Required: Value to add (positive) or subtract (negative)
}`}
            />
          </div>
          <p className="mt-2">
            This endpoint atomically increments or decrements a numeric value stored at the specified key. 
            If the key doesn't exist, the operation will fail with a 404 error. 
            The response includes the new value after the operation.
          </p>

          <div className="mt-4">
            <CodeTabs snippets={atomicIncrementSnippets} />
          </div>

          <p className="mt-2"><strong>Response:</strong></p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="json"
              style={customStyle}
              code={`{
  "success": true,
  "message": "Record value incremented/decremented successfully",
  "result": 42  // The new value after increment/decrement
}`}
            />
          </div>

          <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
            <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
              <LightBulbIcon className="h-5 w-5 text-indigo-400" />
              <h4 className="font-bold text-indigo-400">Note</h4>
            </div>
            <div className="p-4">
              <p className="text-indigo-200/90">
                Atomic operations are useful for counters, rate limiters, and other scenarios where you need to ensure consistency without race conditions. 
                The system will automatically create the key if it doesn't exist in case of an increment.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h4 id="get-record" className="text-lg font-bold mb-2">3. Get Record</h4>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="http"
              style={customStyle}
              code={`GET /record/:key
x-api-key: YOUR_API_KEY`}
            />
          </div>

          <div className="mt-4">
            <CodeTabs snippets={getSnippets} />
          </div>
        </div>

        <div>
          <h4 id="delete-record" className="text-lg font-bold mb-2">4. Delete Record</h4>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="http"
              style={customStyle}
              code={`DELETE /record/:key
x-api-key: YOUR_API_KEY`}
            />
          </div>

          <div className="mt-4">
            <CodeTabs snippets={deleteSnippets} />
          </div>
        </div>

        <div>
          <h4 id="range-query" className="text-lg font-bold mb-2">5. Range Query</h4>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="http"
              style={customStyle}
              code={`GET /records?startKey=<start>&endKey=<end>&limit=<limit>
x-api-key: YOUR_API_KEY`}
            />
          </div>
          <p className="mt-2"><strong>Query Parameters:</strong></p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="text"
              style={customStyle}
              code={`startKey  // Required: Starting key for the range (inclusive)
endKey    // Required: Ending key for the range (inclusive)
limit     // Optional: Maximum number of records to return (default: 100, max: 1000)`}
            />
          </div>

          <div className="mt-4">
            <CodeTabs snippets={rangeQuerySnippets} />
          </div>

          <div className="mt-6 border border-indigo-400/20 rounded-lg bg-indigo-400/5">
            <div className="px-4 py-3 border-b border-indigo-400/20 flex items-center gap-2">
              <LightBulbIcon className="h-5 w-5 text-indigo-400" />
              <h4 className="font-bold text-indigo-400">Note</h4>
            </div>
            <div className="p-4">
              <p className="text-indigo-200/90">
                Range queries are useful for retrieving multiple records with similar keys. For example, you can retrieve all users with IDs between 100 and 200 using a range query with <code className="bg-component-bg text-text p-1 rounded">startKey=user:100</code> and <code className="bg-component-bg text-text p-1 rounded">endKey=user:200</code>.
              </p>
              <p className="text-indigo-200/90">
                The response includes a <code className="bg-component-bg text-text p-1 rounded">truncated</code> flag that indicates whether there are more records available beyond the limit.
              </p>
            </div>
          </div>
        </div>
      </div>

      <h3 id="response-formats" className="text-xl font-bold mt-6 mb-2">Response Formats</h3>
      <div className="space-y-4">
        <div>
          <p className="font-semibold mb-2">Success Response (GET):</p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="json"
              style={customStyle}
              code={`{
  "key": "user:123",
  "value": "John Doe"
}`}
            />
          </div>
        </div>

        <div>
          <p className="font-semibold mb-2">Success Response (Range Query):</p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="json"
              style={customStyle}
              code={`{
  "records": [
    {
      "key": "user:100",
      "value": "Alice"
    },
    {
      "key": "user:101",
      "value": "Bob"
    },
    // ... more records
  ],
  "count": 50,
  "truncated": false
}`}
            />
          </div>
        </div>

        <div>
          <p className="font-semibold mb-2">Success Response (POST/DELETE):</p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="json"
              style={customStyle}
              code={`{
  "success": true,
  "message": "Record inserted/updated/deleted successfully"
}`}
            />
          </div>
        </div>

        <div>
          <p className="font-semibold mb-2">Error Response:</p>
          <div className="bg-component-bg rounded-lg p-4">
            <CodeBlock
              language="json"
              style={customStyle}
              code={`{
  "error": "Error message"
}`}
            />
          </div>
        </div>
      </div>

      <h3 id="error-codes" className="text-xl font-bold mt-6 mb-2">Error Codes</h3>
      <div className="bg-component-bg rounded-lg p-4">
        <CodeBlock
          language="text"
          style={customStyle}
          code={`400 Bad Request      - Invalid parameters or request body
401 Unauthorized     - Missing or invalid API key
403 Forbidden        - Permission denied
404 Not Found        - Record not found
409 Conflict         - Timestamp conflict
429 Too Many Requests - Rate limit exceeded
500 Internal Error   - Server error`}
        />
      </div>
    </div>
  );
};

export default RestApi;
