import React, { useState, useRef, useEffect } from 'react';
import { ClipboardIcon, ExclamationTriangleIcon, XMarkIcon, PencilIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import ReactCountryFlag from 'react-country-flag';

// Helper function to format large numbers
function formatLargeNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k';
  }
  return num.toString();
}

const ApiKeys = ({ 
  regions, 
  apiKeys, 
  subscription, 
  operationsToday,
  onGenerateKey,
  onRevokeKey,
  onUpdateDescription,
  onCloseNewKey,
  error,
  loading,
  showNewKey,
  newApiKey
}) => {
  const [selectedRegion, setSelectedRegion] = useState('');
  const [description, setDescription] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [isRegionDropdownOpen, setIsRegionDropdownOpen] = useState(false);
  const [editingDescription, setEditingDescription] = useState(null);
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [revokeTargetId, setRevokeTargetId] = useState(null);
  const [showError, setShowError] = useState(false);
  const regionDropdownRef = useRef(null);

  useEffect(() => {
    if (error && !error.toLowerCase().includes('capacity')) {
      setShowError(true);
      const timer = setTimeout(() => setShowError(false), 5000); // Hide error after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (regionDropdownRef.current && !regionDropdownRef.current.contains(event.target)) {
        setIsRegionDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (error) {
      console.error('Failed to copy to clipboard');
    }
  };

  const handleGenerateKey = () => {
    onGenerateKey(selectedRegion, description);
    setDescription('');
    setSelectedRegion('');
  };

  const initiateRevoke = (tenantId) => {
    setRevokeTargetId(tenantId);
    setShowRevokeModal(true);
  };

  const handleRevokeKey = async (tenantId) => {
    await onRevokeKey(tenantId);
    setShowRevokeModal(false);
  };

  const handleDescriptionSave = async (tenantId) => {
    await onUpdateDescription(tenantId, description);
    setEditingDescription(null);
    setDescription('');
  };

  const renderErrorBanner = () => {
    if (!showError || !error || error.toLowerCase().includes('capacity')) return null;
    
    return (
      <div className="bg-red-500/10 text-red-500 px-6 py-3 rounded-lg shadow-lg flex items-center mb-4">
        <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
        <span>{error}</span>
        <button 
          onClick={() => setShowError(false)}
          className="ml-4 text-red-500 hover:text-red-400"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
    );
  };

  const renderGenerateKeyForm = () => {
    const hasReachedLimit = subscription && apiKeys.length >= subscription.max_api_keys;
    const hasReachedDailyLimit = subscription && operationsToday >= subscription.max_api_keys + 1;
    const hasCapacityError = error && (
      error.toLowerCase().includes('capacity') || 
      error.toLowerCase().includes('at capacity')
    );
    
    if (hasReachedLimit && !showNewKey) {
      return (
        <div className="flex items-center p-4 bg-red-900/20 rounded-lg mb-4">
          <ExclamationTriangleIcon className="h-5 w-5 text-red-500 mr-2" />
          <p className="text-red-500">
            You've reached your API key limit. Upgrade your plan to create more keys.
          </p>
        </div>
      );
    }

    if (hasReachedDailyLimit && !showNewKey) {
      return (
        <div className="flex items-center p-4 bg-red-900/20 rounded-lg mb-4">
          <ExclamationTriangleIcon className="h-5 w-5 text-red-500 mr-2" />
          <p className="text-red-500">
            You've reached your daily API key generation limit. Please try again tomorrow.
          </p>
        </div>
      );
    }

    return (
      <>
        {hasCapacityError && (
          <div className="flex items-center p-4 bg-red-900/20 rounded-lg mb-4">
            <ExclamationTriangleIcon className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-500">
              The selected region is currently at capacity. Please choose another region.
            </p>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-text mb-2">Select Region</label>
            <div className="relative" ref={regionDropdownRef}>
              <button
                type="button"
                onClick={() => setIsRegionDropdownOpen(!isRegionDropdownOpen)}
                className="w-full pl-12 pr-10 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text text-left"
              >
                {selectedRegion ? (
                  <div className="flex items-center">
                    <ReactCountryFlag 
                      countryCode={regions.find(r => r.region_id === selectedRegion).country_code} 
                      svg 
                      style={{
                        width: '1.2em',
                        height: '1.2em',
                        marginRight: '0.5em'
                      }}
                    />
                    {regions.find(r => r.region_id === selectedRegion)?.location} 
                    ({regions.find(r => r.region_id === selectedRegion)?.server_name})
                  </div>
                ) : (
                  'Choose a region'
                )}
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </span>
              </button>
              {isRegionDropdownOpen && (
                <div className="absolute z-10 mt-1 w-full bg-background border border-gray-700 rounded-lg shadow-lg">
                  <ul className="py-1 max-h-60 overflow-auto">
                    {regions.map((region) => {
                      const isAtCapacity = region.current_tenants >= region.capacity;
                      return (
                        <li key={region.region_id}>
                          <button
                            type="button"
                            disabled={isAtCapacity}
                            className={`w-full px-4 py-2 text-left flex items-center ${
                              selectedRegion === region.region_id 
                                ? 'bg-accent-purple/10 text-accent-purple' 
                                : isAtCapacity 
                                  ? 'text-gray-500 cursor-not-allowed bg-gray-900/10'
                                  : 'text-text hover:bg-accent-purple/10'
                            }`}
                            onClick={() => {
                              if (!isAtCapacity) {
                                setSelectedRegion(region.region_id);
                                setIsRegionDropdownOpen(false);
                              }
                            }}
                          >
                            <ReactCountryFlag 
                              countryCode={region.country_code} 
                              svg 
                              style={{
                                width: '1.2em',
                                height: '1.2em',
                                marginRight: '0.5em',
                                opacity: isAtCapacity ? 0.5 : 1
                              }}
                            />
                            <div className="flex-1">
                              {region.location} ({region.server_name})
                              {isAtCapacity && (
                                <span className="ml-2 text-xs text-red-500">
                                  At Capacity
                                </span>
                              )}
                            </div>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-text mb-2">Description (Optional)</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="e.g., Production API Key"
              className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
            />
          </div>
          <div className="flex items-end">
            <button
              onClick={handleGenerateKey}
              disabled={!selectedRegion || loading}
              className="bg-accent-purple text-white font-semibold py-3 px-6 rounded-lg hover:bg-accent-blue transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Generating...' : 'Generate API Key'}
            </button>
          </div>
        </div>
        {error && !hasCapacityError && (
          <div className="flex items-center p-4 bg-red-900/20 rounded-lg mb-4">
            <ExclamationTriangleIcon className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-500">{error}</p>
          </div>
        )}
        {showNewKey && (
          <div className="border border-accent-purple rounded-lg p-4 bg-accent-purple/10">
            <div className="flex items-center justify-between mb-2">
              <h4 className="text-lg font-medium text-accent-purple">Your New API Key</h4>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => copyToClipboard(newApiKey)}
                  className="flex items-center text-sm text-accent-purple hover:text-accent-blue"
                >
                  {copySuccess ? (
                    <CheckCircleIcon className="h-5 w-5 mr-1" />
                  ) : (
                    <ClipboardIcon className="h-5 w-5 mr-1" />
                  )}
                  {copySuccess ? 'Copied!' : 'Copy to Clipboard'}
                </button>
                <button
                  onClick={onCloseNewKey}
                  className="text-text-secondary hover:text-text"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
            <p className="font-mono text-sm bg-background p-3 rounded border border-gray-700 break-all">
              {newApiKey}
            </p>
            <p className="text-accent-purple text-sm mt-2">
              <InformationCircleIcon className="h-4 w-4 inline mr-1" />
              Save this key now. For security reasons, you won't be able to see it again.
              <br/>
              <ExclamationTriangleIcon className="h-4 w-4 inline mr-1 text-red-500" />
              <span className="text-red-500">Please note that it might take up to a minute for the key to be activated.</span>
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="space-y-6">
      {renderErrorBanner()}
      <div className="bg-component-bg rounded-lg p-6 shadow-sm">
        <h3 className="text-xl font-semibold text-text mb-4">Generate New API Key</h3>
        {renderGenerateKeyForm()}
      </div>

      <div className="bg-component-bg rounded-lg p-6 shadow-sm">
        <h3 className="text-xl font-semibold text-text mb-4">Your API Keys</h3>
        {apiKeys.length === 0 ? (
          <p className="text-text-secondary">You haven't generated any API keys yet.</p>
        ) : (
          <div className="space-y-4">
            {apiKeys.map((key) => (
              <div key={key.tenantId} className="border border-gray-700 rounded-lg p-4">
                <div className="flex flex-col">
                  <div className="flex justify-between items-start mb-4">
                    <div className="flex items-center space-x-2">
                      <ReactCountryFlag 
                        countryCode={key.country_code} 
                        svg 
                        style={{
                          width: '1.5em',
                          height: '1.5em',
                        }}
                      />
                      <div>
                        {editingDescription === key.tenantId ? (
                          <div className="flex items-center space-x-2">
                            <input
                              type="text"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              placeholder="Enter description"
                              className="px-2 py-1 bg-background border border-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text text-sm"
                            />
                            <button
                              onClick={() => handleDescriptionSave(key.tenantId)}
                              className="text-accent-purple hover:text-accent-blue"
                            >
                              <CheckCircleIcon className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => {
                                setEditingDescription(null);
                                setDescription('');
                              }}
                              className="text-text-secondary hover:text-text"
                            >
                              <XMarkIcon className="h-5 w-5" />
                            </button>
                          </div>
                        ) : (
                          <div className="flex items-center space-x-2">
                            <p className="text-sm font-medium text-text">
                              {key.description || 'No description'}
                            </p>
                            <button
                              onClick={() => {
                                setEditingDescription(key.tenantId);
                                setDescription(key.description || '');
                              }}
                              className="text-text-secondary hover:text-text"
                            >
                              <PencilIcon className="h-4 w-4" />
                            </button>
                          </div>
                        )}
                        <p className="text-sm text-text-secondary">
                          {key.location} | Created: {new Date(key.created_at).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() => initiateRevoke(key.tenantId)}
                      className="bg-red-500/10 text-red-500 px-4 py-2 rounded-lg hover:bg-red-500/20 transition-colors duration-200"
                    >
                      Revoke Key
                    </button>
                  </div>
                  
                  <div className="space-y-4">
                    {/* REST, WS, and standard limits box */}
                    <div className="w-full p-4 bg-background rounded-lg border border-gray-700">
                      <div className="space-y-2 mb-3">
                        <p className="text-sm font-mono">
                          REST API: <span className="text-accent-purple">{key.base_url}</span>
                        </p>
                        <p className="text-sm font-mono">
                          WebSocket API: <span className="text-accent-purple">{key.base_url.replace('https://', 'wss://')}/ws</span>
                        </p>
                      </div>
                      <div className="grid grid-cols-4 gap-4">
                        <div>
                          <p className="text-xs text-text-secondary">Max RPS</p>
                          <p className="text-sm font-medium">{formatLargeNumber(key.rateLimitMax / (key.rateLimitWindowMs / 1000))}</p>
                        </div>
                        <div>
                          <p className="text-xs text-text-secondary">Max Records</p>
                          <p className="text-sm font-medium">{formatLargeNumber(key.max_records)}</p>
                        </div>
                        <div>
                          <p className="text-xs text-text-secondary">Max Key Size</p>
                          <p className="text-sm font-medium">{key.maxKeySize} bytes</p>
                        </div>
                        <div>
                          <p className="text-xs text-text-secondary">Max Value Size</p>
                          <p className="text-sm font-medium">{key.maxValueSize} bytes</p>
                        </div>
                      </div>
                    </div>
                    
                    {/* Nexus Search box */}
                    <div className="w-full p-4 bg-background rounded-lg border border-gray-700">
                      <div className="mb-3">
                        <p className="text-sm font-mono">
                          Nexus Search: <span className="text-accent-purple">https://nexus.hpkv.io</span>
                        </p>
                      </div>
                      <div className="grid grid-cols-3 gap-4">
                        <div>
                          <p className="text-xs text-text-secondary">Max Nexus Calls</p>
                          <p className="text-sm font-medium">{formatLargeNumber(key.max_rag_calls || 100)}/month</p>
                        </div>
                        <div>
                          <p className="text-xs text-text-secondary">Max RPM</p>
                          <p className="text-sm font-medium">12</p>
                        </div>
                        <div>
                          <p className="text-xs text-text-secondary">Max Output Tokens</p>
                          <p className="text-sm font-medium">{formatLargeNumber(1000)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {subscription && (
        <div className="text-sm text-text-secondary">
          Using {apiKeys.length} of {subscription.max_api_keys} available API keys
        </div>
      )}

      {/* Revoke Confirmation Modal */}
      {showRevokeModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-component-bg rounded-lg p-6 max-w-md w-full mx-4">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-semibold text-text">Revoke API Key</h3>
              <button
                onClick={() => setShowRevokeModal(false)}
                className="text-text-secondary hover:text-text"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
            <div className="mb-6">
              <ExclamationTriangleIcon className="h-12 w-12 text-red-500 mx-auto mb-4" />
              <p className="text-text text-center">
                Are you sure you want to revoke this API key? This action cannot be undone and will delete all data associated with this key.
              </p>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowRevokeModal(false)}
                className="px-4 py-2 text-text-secondary hover:text-text"
              >
                Cancel
              </button>
              <button
                onClick={() => handleRevokeKey(revokeTargetId)}
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors duration-200"
              >
                Revoke Key
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApiKeys; 