import React from 'react';

const Billing = ({ subscription }) => {
  return (
    <div className="space-y-6">
      {/* Current Plan Section */}
      {subscription && (
        <div className="bg-component-bg rounded-lg p-6 shadow-sm">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold text-text">Current Plan</h3>
            <span className="px-3 py-1 bg-accent-purple/10 text-accent-purple rounded-full text-sm">
              Active
            </span>
          </div>
          
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <h4 className="text-lg font-medium text-text">{subscription.name}</h4>
              <span className="text-lg font-medium text-text">
                {subscription.price_monthly > 0 ? `€${subscription.price_monthly}/month` : 'Free'}
              </span>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="p-3 bg-background rounded-lg border border-gray-700">
                <p className="text-xs text-text-secondary mb-1">API Keys</p>
                <p className="text-xl font-medium">{subscription.max_api_keys || 0}</p>
              </div>
              <div className="p-3 bg-background rounded-lg border border-gray-700">
                <p className="text-xs text-text-secondary mb-1">Max RPS</p>
                <p className="text-xl font-medium">{subscription.rateLimitMax ? (subscription.rateLimitMax / (subscription.rateLimitWindowMs / 1000)) : 0}</p>
              </div>
              <div className="p-3 bg-background rounded-lg border border-gray-700">
                <p className="text-xs text-text-secondary mb-1">Max Records</p>
                <p className="text-xl font-medium">{subscription.max_records ? subscription.max_records.toLocaleString() : 0}</p>
              </div>
              <div className="p-3 bg-background rounded-lg border border-gray-700">
                <p className="text-xs text-text-secondary mb-1">Max Key Size</p>
                <p className="text-xl font-medium">{subscription.maxKeySize ? `${subscription.maxKeySize} bytes` : '0 bytes'}</p>
              </div>
              <div className="p-3 bg-background rounded-lg border border-gray-700">
                <p className="text-xs text-text-secondary mb-1">Max Value Size</p>
                <p className="text-xl font-medium">{subscription.maxValueSize ? `${subscription.maxValueSize} bytes` : '0 bytes'}</p>
              </div>
              <div className="flex items-center justify-end">
                <button 
                  disabled
                  className="py-2 px-4 bg-accent-purple/50 text-white rounded-lg cursor-not-allowed opacity-60 hover:bg-accent-purple/50"
                >
                  Manage Subscription
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Billing History Section */}
      <div className="bg-component-bg rounded-lg p-6 shadow-sm">
        <h3 className="text-xl font-semibold text-text mb-6">Billing History</h3>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="text-left py-3 px-4 text-text-secondary font-medium">Date</th>
                <th className="text-left py-3 px-4 text-text-secondary font-medium">Description</th>
                <th className="text-left py-3 px-4 text-text-secondary font-medium">Amount</th>
                <th className="text-left py-3 px-4 text-text-secondary font-medium">Status</th>
                <th className="text-right py-3 px-4 text-text-secondary font-medium">Invoice</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-8 text-center text-text-secondary" colSpan="5">
                  No billing history available
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Billing; 