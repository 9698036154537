import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useTurnstile from '../hooks/useTurnstile';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  // Use the Turnstile hook
  const { turnstileRef, token: turnstileToken, reset: resetTurnstile } = useTurnstile({
    onError: () => setError('CAPTCHA verification failed')
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!turnstileToken) {
      setError('Please complete the CAPTCHA challenge');
      return;
    }

    setError('');
    setMessage('');
    setLoading(true);

    try {
      const response = await fetch('/api/auth/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, turnstileToken }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to process request');
      }

      setMessage(data.message);
      setEmail(''); // Clear the email field after successful submission
    } catch (error) {
      setError(error.message);
      // Reset Turnstile on error
      resetTurnstile();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-background">
      <div className="max-w-md w-full space-y-8 p-8 bg-component-bg rounded-lg shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-text">
            Reset your password
          </h2>
          <p className="mt-2 text-center text-sm text-text-secondary">
            Enter your email address and we'll send you a link to reset your password.
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {error && (
            <div className="text-red-500 text-sm text-center">
              {error}
            </div>
          )}
          {message && (
            <div className="text-green-500 text-sm text-center">
              {message}
            </div>
          )}
          <div>
            <label htmlFor="email" className="sr-only">Email address</label>
            <input
              id="email"
              name="email"
              type="email"
              required
              className="appearance-none relative block w-full px-3 py-2 border border-gray-700 placeholder-gray-500 text-text rounded-md focus:outline-none focus:ring-accent-purple focus:border-accent-purple focus:z-10 sm:text-sm bg-background"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="flex justify-center">
            <div ref={turnstileRef}></div>
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                loading 
                  ? 'bg-accent-purple/50 cursor-not-allowed' 
                  : 'bg-accent-purple hover:bg-accent-purple/90'
              }`}
            >
              {loading ? 'Sending...' : 'Reset Password'}
            </button>
          </div>

          <div className="text-center">
            <Link to="/login" className="text-accent-purple hover:text-accent-blue text-sm">
              Back to login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword; 