import React from 'react';

const StatusBadge = () => {
  return (
    <iframe 
      src="https://status.hpkv.io/badge?theme=dark" 
      width="250" 
      height="30" 
      frameBorder="0" 
      scrolling="no" 
      style={{ colorScheme: 'normal' }}
      title="HPKV System Status"
    />
  );
};

export default StatusBadge; 