import React, { useEffect, useRef, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';

// Lazy load non-critical components
const Features = lazy(() => import('../components/Features'));
const Pricing = lazy(() => import('../components/Pricing'));
const Roadmap = lazy(() => import('../components/Roadmap'));
const Demo = lazy(() => import('../components/Demo'));

// Preload Hero component
const preloadHero = () => {
  // This ensures Hero component is loaded as early as possible
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'script';
  link.href = '/static/js/main.chunk.js'; // This contains the Hero component
  document.head.appendChild(link);
};

// Loading fallback component
const LoadingFallback = () => (
  <div className="py-12 flex justify-center">
    <div className="w-8 h-8 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
  </div>
);

const Home = () => {
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const roadmapRef = useRef(null);

  // Preload Hero component on mount
  useEffect(() => {
    preloadHero();
  }, []);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash === '#features' && featuresRef.current) {
        scrollToSection(featuresRef);
      } else if (hash === '#pricing' && pricingRef.current) {
        scrollToSection(pricingRef);
      } else if (hash === '#roadmap' && roadmapRef.current) {
        scrollToSection(roadmapRef);
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange(); // Handle initial hash

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return (
    <div className="bg-background">
      <Helmet>
        <title>HPKV - High Performance Key-Value Store with AI-Powered Search</title>
        <meta name="description" content="A superior Redis alternative with integrated AI. HPKV combines nanosecond-level performance with AI-powered Nexus Search. Featuring RAG capabilities, semantic search, natural language querying, WebSocket API, REST API, real-time pub-sub notifications, and ultra-low latency." />
        <meta name="keywords" content="high performance key-value store, AI search, RAG, retrieval augmented generation, semantic search, vector search, natural language querying, AI database, websocket, redis alternative, real-time data, pub-sub, websocket notifications, nanosecond latency, REST API, WebSocket API, RIOC, low latency, cache, key value store api, fastest key-value store, real-time tracking, ultra low latency, websocket api, rpc api, realtime websocket" />
        <link rel="canonical" href="https://hpkv.io" />
        
        {/* Resource Hints */}
        <link rel="preload" href="/images/hpkv-hero.webp" as="image" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hpkv.io" />
        <meta property="og:title" content="HPKV - High Performance Key-Value Store with AI-Powered Search" />
        <meta property="og:description" content="A superior Redis alternative with integrated AI. The fastest key-value store with nanosecond-level performance and AI search capabilities. Featuring semantic search, RAG, natural language querying, WebSocket API for real-time pub-sub, REST API, and more." />
        <meta property="og:image" content="https://hpkv.io/images/hpkv-hero.webp" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hpkvHQ" />
        <meta name="twitter:title" content="HPKV - High Performance Key-Value Store with AI-Powered Search" />
        <meta name="twitter:description" content="A superior Redis alternative with added AI functionality. Ultra-fast key-value store with built-in AI capabilities. Featuring semantic search, RAG, WebSocket for real-time pub-sub, ultra-low latency, and intelligent data retrieval." />
        <meta name="twitter:image" content="https://hpkv.io/images/hpkv-hero.webp" />

        {/* Schema.org markup for Google */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "HPKV",
            "applicationCategory": "DatabaseApplication",
            "operatingSystem": "Any",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "description": "A superior Redis alternative. High Performance Key-Value Store with AI-powered search, semantic querying, and RAG capabilities. Ultra-fast performance with nanosecond-level latency.",
            "keywords": "key-value store, database, AI search, RAG, semantic search, high performance, low latency"
          })}
        </script>
      </Helmet>
      <Hero onScrollToFeatures={() => scrollToSection(featuresRef)} />
      <div ref={featuresRef}>
        <Suspense fallback={<LoadingFallback />}>
          <Features />
        </Suspense>
      </div>
      <Suspense fallback={<LoadingFallback />}>
        <Demo />
      </Suspense>
      <div ref={roadmapRef}>
        <Suspense fallback={<LoadingFallback />}>
          <Roadmap />
        </Suspense>
      </div>
      <div ref={pricingRef}>
        <Suspense fallback={<LoadingFallback />}>
          <Pricing />
        </Suspense>
      </div>
    </div>
  );
};

export default Home;
