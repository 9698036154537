import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  BoltIcon, 
  ClockIcon, 
  CircleStackIcon, 
  BellAlertIcon, 
  CurrencyDollarIcon,
  CubeTransparentIcon
} from '@heroicons/react/24/outline';


// Simple fade transition variants
const fadeVariants = {
  hidden: { 
    opacity: 0,
  },
  visible: { 
    opacity: 1,
    transition: {
      duration: 0.8
    }
  },
  exit: { 
    opacity: 0,
    transition: {
      duration: 0.6
    }
  }
};

// Continuous glow animation component that's independent of carousel
const ChromaticGlow = ({ activeColor }) => {
  const [glowState, setGlowState] = useState({
    x: 0,
    y: 0,
    angle: 25,
    direction: 0, // 0-3 for different directions
    prevX: 0,
    prevY: 0,
    prevAngle: 45,
    blurAmount: 'lg'
  });
  
  // Track the current and previous colors to enable smooth transitions
  const [colorState, setColorState] = useState({
    current: activeColor,
    previous: activeColor
  });
  
  // Add a state to track if the component is mounted and ready
  const [isReady, setIsReady] = useState(false);
  
  // Reference to the container element
  const containerRef = useRef(null);
  
  // Set component as ready after initial render
  useEffect(() => {
    // Use requestAnimationFrame to ensure the DOM has been painted
    requestAnimationFrame(() => {
      setIsReady(true);
    });
  }, []);
  
  // When activeColor changes, update colorState to enable transition
  useEffect(() => {
    if (activeColor !== colorState.current) {
      setColorState({
        current: activeColor,
        previous: colorState.current
      });
    }
  }, [activeColor, colorState]);
  
  // Animation controls to update animations when state changes
  const redChannelControls = useAnimation();
  const greenChannelControls = useAnimation();
  const blueChannelControls = useAnimation();
  const mainColorControls = useAnimation();
  const colorTransitionControls = useAnimation();
  
  // Use ref to avoid animation restarts on parent re-renders
  const animationRef = useRef(null);
  
  // Update animations when glowState changes
  useEffect(() => {
    // Update all animation controls with new values from glowState
    const updateAnimations = () => {
      // Create a common completion callback to ensure perfect timing
      const onComplete = () => {
        // Animation has completed - we could trigger the next cycle here if needed
      };

      // Red channel animation - moderate skew values for balanced stretch
      redChannelControls.start({
        skewX: [glowState.prevAngle * 0.5, glowState.angle * 0.8, glowState.angle * 0.5],
        skewY: [glowState.prevAngle * 0.4, glowState.angle * 0.6, glowState.angle * 0.4],
        x: [glowState.prevX * 0.07, glowState.x * 0.08, glowState.x * 0.07],
        y: [glowState.prevY * 0.07, glowState.y * 0.08, glowState.y * 0.07],
        translateX: [1.2, 2, 1.2],
        translateY: [-1.2, -2, -1.2],
        scale: [1.05, 1.2, 1.05], // Moderate minimum scale
        transition: {
          duration: 6,
          ease: "easeInOut",
          times: [0, 0.5, 1]
        },
        onComplete
      });
      
      // Green channel animation - moderate skew values for balanced stretch
      greenChannelControls.start({
        skewX: [glowState.prevAngle * 0.4, glowState.angle * 0.6, glowState.angle * 0.4],
        skewY: [glowState.prevAngle * 0.3, glowState.angle * 0.4, glowState.angle * 0.3],
        scale: [1.04, 0.97, 1.04], // Moderate scale animation
        transition: {
          duration: 6,
          ease: "easeInOut",
          times: [0, 0.5, 1]
        }
      });
      
      // Blue channel animation - moderate skew values for balanced stretch
      blueChannelControls.start({
        skewX: [glowState.prevAngle * 0.5, glowState.angle * 0.7, glowState.angle * 0.5],
        skewY: [glowState.prevAngle * 0.5, glowState.angle * 0.7, glowState.angle * 0.5],
        x: [glowState.prevX * -0.07, glowState.x * -0.08, glowState.x * -0.07],
        y: [glowState.prevY * -0.07, glowState.y * -0.08, glowState.y * -0.07],
        translateX: [-1.2, -2, -1.2],
        translateY: [1.2, 2, 1.2],
        scale: [1.03, 1.15, 1.03], // Moderate minimum scale
        transition: {
          duration: 6,
          ease: "easeInOut",
          times: [0, 0.5, 1]
        }
      });
      
      // Main color animation - moderate skew values for balanced stretch
      mainColorControls.start({
        skewX: [glowState.prevAngle * 0.4, glowState.angle * 0.5, glowState.angle * 0.4],
        skewY: [glowState.prevAngle * 0.3, glowState.angle * 0.3, glowState.angle * 0.3],
        scale: [1.0, 1.1, 1.0], // Moderate scale animation
        transition: {
          duration: 6,
          ease: "easeInOut",
          times: [0, 0.5, 1]
        }
      });
    };
    
    // Update animations whenever glowState changes
    updateAnimations();
  }, [glowState, redChannelControls, greenChannelControls, blueChannelControls, mainColorControls]);
  
  // Effect to handle color transitions when activeColor changes
  useEffect(() => {
    // Trigger color transition animation when activeColor changes
    if (colorState.current !== colorState.previous) {
      colorTransitionControls.start({
        opacity: [1, 0.7, 1],
        transition: {
          duration: 1.5,
          ease: "easeInOut"
        }
      });
    }
  }, [colorState, colorTransitionControls]);
  
  // Store the final animation state to ensure continuity
  const finalStateRef = useRef({
    x: 0,
    y: 0,
    angle: 35, // Moderate initial angle for balanced stretch
    direction: 0
  });
  
  // Create a ref to track if an animation is in progress
  const isAnimatingRef = useRef(false);
  
  useEffect(() => {
    // Create a smooth animation loop that runs continuously
    const animateGlow = () => {
      // Mark that we're starting a new animation
      isAnimatingRef.current = true;
      
      // Use the finalStateRef to ensure perfect continuity between cycles
      setGlowState(prev => {
        // Calculate new target values as offsets from current position
        // This ensures each animation starts where the previous one ended
        const offsetX = (Math.random() * 60 - 30); // -30 to 30 offset
        const offsetY = (Math.random() * 60 - 30); // -30 to 30 offset
        const offsetAngle = (Math.random() * 40 - 15); // -15 to 25 degrees offset (slightly biased toward increasing)
        
        // Use the finalStateRef values as the starting point for the next animation
        const currentX = finalStateRef.current.x;
        const currentY = finalStateRef.current.y;
        const currentAngle = finalStateRef.current.angle;
        
        // Calculate new target values based on current position
        const targetX = Math.max(-60, Math.min(60, currentX + offsetX)); // Clamp between -60 and 60
        const targetY = Math.max(-60, Math.min(60, currentY + offsetY)); // Clamp between -60 and 60
        const targetAngle = Math.max(35, Math.min(90, currentAngle + offsetAngle)); // Moderate min angle to 35
        
        // Random direction: 0=top-left to bottom-right, 1=top-right to bottom-left, 
        // 2=bottom-left to top-right, 3=bottom-right to top-left
        // Only change direction occasionally for smoother transitions
        const newDirection = Math.random() < 0.3 ? Math.floor(Math.random() * 4) : finalStateRef.current.direction;
        
        // Update the finalStateRef with the new target values for the next cycle
        finalStateRef.current = {
          x: targetX,
          y: targetY,
          angle: targetAngle,
          direction: newDirection
        };
        
        // Ensure the previous values exactly match the final values from the last animation
        return {
          x: targetX,
          y: targetY,
          angle: targetAngle,
          direction: newDirection,
          blurAmount: prev.blurAmount,
          prevX: currentX,
          prevY: currentY,
          prevAngle: currentAngle
        };
      });
    };
    
    // Instead of using setInterval which causes jumps, use a recursive setTimeout
    // with a small random variation to make transitions more natural
    const scheduleNextAnimation = () => {
      const nextDelay = 6000 + 100;
      
      // Only schedule the next animation if we're not already animating
      if (!isAnimatingRef.current) {
        animationRef.current = setTimeout(() => {
          animateGlow();
          // Mark that this animation cycle is complete
          isAnimatingRef.current = false;
          // Schedule the next animation
          scheduleNextAnimation();
        }, nextDelay);
      } else {
        // If we're still animating, wait a bit and try again
        setTimeout(scheduleNextAnimation, 100);
      }
    };
    
    // Initial animation
    animateGlow();
    // Mark that the initial animation is complete after its duration
    setTimeout(() => {
      isAnimatingRef.current = false;
    }, 6000);
    
    // Start the animation cycle
    scheduleNextAnimation();
    
    return () => {
      if (animationRef.current) {
        clearTimeout(animationRef.current);
      }
    };
  }, []);
  
  // Determine gradient direction based on the random direction value
  const getGradientDirection = () => {
    switch(glowState.direction) {
      case 0: return 'bg-gradient-to-br'; // top-left to bottom-right
      case 1: return 'bg-gradient-to-bl'; // top-right to bottom-left
      case 2: return 'bg-gradient-to-tr'; // bottom-left to top-right
      case 3: return 'bg-gradient-to-tl'; // bottom-right to top-left
      default: return 'bg-gradient-to-br';
    }
  };
  
  // Get blur class based on current blur amount
  const getBlurClass = () => {
    return `blur-${glowState.blurAmount}`;
  };
  
  return (
    <motion.div 
      ref={containerRef}
      className="absolute inset-0 scale-[1.5] md:scale-[2]"
      initial={{ opacity: 0 }}
      animate={{ 
        opacity: isReady ? [0.6, 0.9, 0.6] : 0
      }}
      transition={{ 
        opacity: {
          repeat: Infinity,
          duration: 6,
          ease: "easeInOut"
        }
      }}
      style={{
        visibility: isReady ? 'visible' : 'hidden',
        position: 'absolute',
        width: '100%',
        height: '100%'
      }}
    >
      {/* Red channel */}
      <motion.div 
        className={`absolute inset-0 ${getGradientDirection()} from-red-500/70 to-transparent rounded-full ${getBlurClass()} mix-blend-screen`}
        animate={redChannelControls}
      />
      
      {/* Green channel */}
      <motion.div 
        className={`absolute inset-0 ${getGradientDirection()} from-green-500/70 to-transparent rounded-full ${getBlurClass()} mix-blend-screen`}
        animate={greenChannelControls}
      />
      
      {/* Blue channel */}
      <motion.div 
        className={`absolute inset-0 ${getGradientDirection()} from-blue-500/70 to-transparent rounded-full ${getBlurClass()} mix-blend-screen`}
        animate={blueChannelControls}
      />
      
      {/* Previous color gradient (for smooth transition) */}
      <motion.div 
        className={`absolute inset-0 ${getGradientDirection()} ${colorState.previous.replace('/50', '/70')} rounded-full ${getBlurClass()} opacity-70 mix-blend-screen`}
        animate={{ opacity: [0.7, 0] }}
        transition={{ 
          opacity: {
            duration: 1.5,
            ease: "easeInOut"
          }
        }}
      />
      
      {/* Current color gradient */}
      <motion.div 
        className={`absolute inset-0 ${getGradientDirection()} ${colorState.current.replace('/50', '/70')} rounded-full ${getBlurClass()} mix-blend-screen`}
        initial={{ opacity: 0 }}
        animate={{ 
          opacity: 0.7,
          skewX: [glowState.prevAngle * 0.4, glowState.angle * 0.5, glowState.angle * 0.4],
          skewY: [glowState.prevAngle * 0.3, glowState.angle * 0.3, glowState.angle * 0.3],
          scale: [1.0, 1.1, 1.0]
        }}
        transition={{ 
          opacity: {
            duration: 1.5,
            ease: "easeInOut"
          },
          skewX: {
            duration: 6,
            ease: "easeInOut",
            times: [0, 0.5, 1]
          },
          skewY: {
            duration: 6,
            ease: "easeInOut",
            times: [0, 0.5, 1]
          },
          scale: {
            duration: 6,
            ease: "easeInOut",
            times: [0, 0.5, 1]
          }
        }}
      />
    </motion.div>
  );
};

const Hero = ({ onScrollToFeatures }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  
  // Animation controls for button hover effects
  const primaryBtnControls = useAnimation();
  const secondaryBtnControls = useAnimation();
  
  // Unique value propositions that differentiate HPKV
  const valueProps = [
    {
      icon: CubeTransparentIcon,
      title: "AI-Powered Nexus Search",
      description: "Semantic search and RAG capabilities to query your data using natural language",
      color: "from-indigo-500 to-purple-500"
    },
    {
      icon: BoltIcon,
      title: "Nanosecond-Level Speed",
      description: "~600ns p50 for writes, ~300ns p50 for reads",
      color: "from-purple-500 to-blue-500"
    },
    {
      icon: BellAlertIcon,
      title: "Built-in Reactivity",
      description: "Native WebSocket pub/sub for real-time applications",
      color: "from-cyan-500 to-emerald-500"
    },
    {
      icon: CircleStackIcon,
      title: "Speed + Persistence",
      description: "2-6x faster than Redis while maintaining data durability",
      color: "from-blue-500 to-cyan-500"
    },
    {
      icon: CurrencyDollarIcon,
      title: "Cost Efficiency",
      description: "4-5x lower operational costs than competitors",
      color: "from-yellow-500 to-orange-500"
    },
    {
      icon: ClockIcon,
      title: "Consistent Under Load",
      description: "P99 latencies of just 2µs even at high throughput",
      color: "from-orange-500 to-purple-500"
    }
  ];

  // Auto-rotate value props
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % valueProps.length);
    }, 5000);
    
    return () => clearInterval(interval);
  }, [valueProps.length]);

  return (
    <div className="relative min-h-screen bg-hero-gradient overflow-hidden">
      <div className="absolute inset-0 bg-background/80 mix-blend-overlay" />
      
      {/* Content */}
      <div className="relative container mx-auto px-4 pt-24 pb-20 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-3xl mx-auto"
        >
          {/* Beta badge */}
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="inline-block"
          >
            <Link 
              to="/signup"
              className="inline-flex items-center px-4 py-1 mb-6 rounded-full bg-accent-purple/10 border border-accent-purple/20 hover:bg-accent-purple/20 transition-colors duration-150 cursor-pointer"
            >
              <span className="text-sm text-accent-purple">HPKV free tier now available</span>
              <span className="ml-1 text-accent-purple">→</span>
            </Link>
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className="text-4xl md:text-6xl font-bold text-heading-text mb-4"
          >
            Speed Meets Intelligence.
          </motion.h1>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="text-xl md:text-2xl text-text/80 mb-8"
          >
            <span className="text-accent-purple">2x faster</span> than leading key-value stores with <span className="text-accent-purple">AI-powered</span> capabilities.
          </motion.p>
        </motion.div>

        {/* Value Proposition Showcase */}
        <div className="max-w-4xl mx-auto relative my-12 md:my-24">
          
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[60%] h-[40%] md:h-[40%] z-0">
            <ChromaticGlow activeColor={valueProps[activeIndex].color} />
          </div>
          
          {/* Animated cards */}
          <div className="relative h-56">
            <AnimatePresence mode="wait" custom={activeIndex}>
              <motion.div
                key={activeIndex}
                custom={activeIndex}
                variants={fadeVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="absolute inset-0 flex flex-col items-center justify-center"
              >
                <div className="relative">
                  <div className="relative p-4 mb-4 rounded-full bg-component-bg border border-gray-700 shadow-xl z-10">
                    {React.createElement(valueProps[activeIndex].icon, { className: "h-12 w-12 text-accent-purple" })}
                  </div>
                </div>
                
                {/* Text content - kept minimal */}
                <motion.h2 
                  className="text-3xl font-bold text-white mb-2"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2, duration: 0.3 }}
                >
                  {valueProps[activeIndex].title}
                </motion.h2>
                <motion.p 
                  className="text-lg text-gray-300 max-w-xl text-center"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3, duration: 0.3 }}
                >
                  {valueProps[activeIndex].description}
                </motion.p>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>

        {/* CTA Buttons */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7, duration: 0.5 }}
          className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mt-16 md:mt-24"
        >
          {/* Primary Button with contained glow */}
          <div className="inline-block relative">
            <Link
              to="/signup"
              className="group relative inline-flex items-center px-8 py-3 text-lg font-semibold text-white bg-gradient-to-b from-accent-purple/25 to-accent-purple/10 backdrop-blur-lg shadow-[inset_0_1px_1px_rgba(255,255,255,0.2),_0_2px_4px_rgba(0,0,0,0.3)] hover:shadow-[inset_0_1px_2px_rgba(255,255,255,0.3),_0_3px_8px_rgba(0,0,0,0.4)] transition-all duration-300 rounded-lg overflow-hidden z-10"
              onMouseEnter={() => {
                primaryBtnControls.start({
                  opacity: 1,
                  transition: { duration: 0.3 }
                });
              }}
              onMouseLeave={() => {
                primaryBtnControls.start({
                  opacity: 0,
                  transition: { duration: 0.5 }
                });
              }}
            >
              {/* Glow effect contained within button */}
              <motion.div 
                className="absolute inset-0 rounded-lg overflow-hidden"
                initial={{ opacity: 0 }}
                animate={primaryBtnControls}
              >
                {/* Red channel */}
                <motion.div 
                  className="absolute inset-0 bg-gradient-to-r from-red-500/60 to-transparent rounded-lg blur-lg mix-blend-screen"
                  animate={{
                    rotate: [0, 2, 0],
                  }}
                  transition={{
                    duration: 8,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />
                
                {/* Blue channel */}
                <motion.div 
                  className="absolute inset-0 bg-gradient-to-r from-blue-500/60 to-transparent rounded-lg blur-lg mix-blend-screen"
                  animate={{
                    rotate: [0, -2, 0],
                  }}
                  transition={{
                    duration: 8,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />
                
                {/* Purple gradient */}
                <motion.div 
                  className="absolute inset-0 bg-gradient-to-r from-purple-500/50 to-blue-500/50 rounded-lg blur-lg mix-blend-screen"
                  animate={{
                    scale: [1, 1.05, 1],
                  }}
                  transition={{
                    duration: 10,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />
              </motion.div>
              
              <span className="relative z-10">
                Get started
                <span className="ml-2">→</span>
              </span>
            </Link>
          </div>
          
          {/* Secondary Button with contained glow */}
          <div className="inline-block relative">
            <Link
              to="/docs"
              className="group relative inline-flex items-center px-8 py-3 text-lg font-semibold text-heading-text bg-gradient-to-b from-white/10 to-white/5 backdrop-blur-lg shadow-[inset_0_1px_1px_rgba(255,255,255,0.1),_0_2px_4px_rgba(0,0,0,0.2)] hover:shadow-[inset_0_1px_2px_rgba(255,255,255,0.2),_0_3px_8px_rgba(0,0,0,0.3)] transition-all duration-300 rounded-lg overflow-hidden z-10"
              onMouseEnter={() => {
                secondaryBtnControls.start({
                  opacity: 1,
                  transition: { duration: 0.3 }
                });
              }}
              onMouseLeave={() => {
                secondaryBtnControls.start({
                  opacity: 0,
                  transition: { duration: 0.5 }
                });
              }}
            >
              {/* Glow effect contained within button */}
              <motion.div 
                className="absolute inset-0 rounded-lg overflow-hidden"
                initial={{ opacity: 0 }}
                animate={secondaryBtnControls}
              >
                {/* Cyan channel */}
                <motion.div 
                  className="absolute inset-0 bg-gradient-to-r from-cyan-500/50 to-transparent rounded-lg blur-lg mix-blend-screen"
                  animate={{
                    rotate: [0, 2, 0],
                  }}
                  transition={{
                    duration: 8,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />
                
                {/* Blue channel */}
                <motion.div 
                  className="absolute inset-0 bg-gradient-to-r from-blue-500/50 to-transparent rounded-lg blur-lg mix-blend-screen"
                  animate={{
                    rotate: [0, -2, 0],
                  }}
                  transition={{
                    duration: 8,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />
                
                {/* Gradient */}
                <motion.div 
                  className="absolute inset-0 bg-gradient-to-r from-cyan-500/40 to-blue-500/40 rounded-lg blur-lg mix-blend-screen"
                  animate={{
                    scale: [1, 1.05, 1],
                  }}
                  transition={{
                    duration: 10,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />
              </motion.div>
              
              <span className="relative z-10">Read the docs</span>
            </Link>
          </div>
        </motion.div>
      </div>

      {/* Scroll indicator */}
      <motion.button
        onClick={onScrollToFeatures}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer text-text/30 hover:text-text/50 transition-colors"
      >
        <svg 
          className="w-8 h-8" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M19 14l-7 7m0 0l-7-7m7 7V3" 
          />
        </svg>
      </motion.button>
    </div>
  );
};

export default Hero;
