import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import RestApi from '../components/Docs/RestApi';
import { Helmet } from 'react-helmet';

const RestApiLanding = () => {
  return (
    <div className="min-h-screen bg-background">
      <Helmet>
        <title>HPKV REST API - High Performance Key-Value Store REST Interface</title>
        <meta name="description" content="Access HPKV's high-performance key-value store through our low latency REST API. Simple HTTP interface with comprehensive documentation, code examples, and Redis-compatible operations." />
        <meta name="keywords" content="HPKV REST API, key-value store API, database REST interface, high performance database API, low latency API, redis alternative API, cache API, atomic operations, atomic increment, atomic decrement, range query, real-time data API" />
        <link rel="canonical" href="https://hpkv.io/rest-api" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hpkv.io/rest-api" />
        <meta property="og:title" content="HPKV REST API - High Performance Key-Value Store REST Interface" />
        <meta property="og:description" content="Access HPKV's high-performance key-value store through our low latency REST API. Simple HTTP interface with comprehensive documentation and Redis-compatible operations." />
        <meta property="og:image" content="https://hpkv.io/images/hpkv-illustration-no-subtext.png" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hpkvHQ" />
        <meta name="twitter:title" content="HPKV REST API - High Performance Key-Value Store REST Interface" />
        <meta name="twitter:description" content="Access HPKV's high-performance key-value store through our low latency REST API. Simple HTTP interface with comprehensive documentation and Redis-compatible operations." />
        <meta name="twitter:image" content="https://hpkv.io/images/hpkv-illustration-no-subtext.png" />
      </Helmet>

      {/* Hero Section */}
      <div className="bg-gradient-to-b from-primary/10 to-background pt-24 pb-12">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-heading-text mb-6">
              HPKV REST API
            </h1>
            <p className="text-xl text-text-secondary mb-8">
              Simple, powerful, and secure HTTP interface for your high-performance key-value storage needs
            </p>
            <div className="flex justify-center gap-4">
              <Link 
                to="/signup" 
                className="inline-flex items-center px-6 py-3 rounded-lg bg-primary text-white hover:bg-primary/90 transition-colors"
              >
                Get Started
                <ArrowRightIcon className="w-5 h-5 ml-2" />
              </Link>
              <Link 
                to="/docs/rest-api" 
                className="inline-flex items-center px-6 py-3 rounded-lg border border-primary/20 hover:bg-primary/10 transition-colors"
              >
                View Documentation
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Key Features */}
      <div className="py-12 bg-component-bg/50">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="p-6 rounded-lg bg-component-bg">
              <h3 className="text-xl font-bold mb-3">Simple Integration</h3>
              <p>Standard REST endpoints with JSON payloads make integration straightforward in any programming language.</p>
            </div>
            <div className="p-6 rounded-lg bg-component-bg">
              <h3 className="text-xl font-bold mb-3">Secure by Design</h3>
              <p>API key authentication, HTTPS encryption, and comprehensive security measures protect your data.</p>
            </div>
            <div className="p-6 rounded-lg bg-component-bg">
              <h3 className="text-xl font-bold mb-3">High Performance</h3>
              <p>Optimized for speed with consistent low-latency responses and efficient data handling.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <RestApi />
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-primary/5 py-12">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Get Started?</h2>
          <p className="text-lg mb-8">Create your account now and start using HPKV's REST API in minutes.</p>
          <Link 
            to="/signup" 
            className="inline-flex items-center px-8 py-4 rounded-lg bg-primary text-white hover:bg-primary/90 transition-colors text-lg"
          >
            Sign Up for Free
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RestApiLanding; 