import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useTurnstile from '../hooks/useTurnstile';

const Contact = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    topic: location.state?.topic || '',
    message: location.state?.message || ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTopicDropdownOpen, setIsTopicDropdownOpen] = useState(false);
  const [activeTopicIndex, setActiveTopicIndex] = useState(-1);
  const topicDropdownRef = useRef(null);
  const topicButtonsRef = useRef([]);
  
  // Use the Turnstile hook
  const { turnstileRef, token: turnstileToken, reset: resetTurnstile } = useTurnstile({
    onVerify: () => setStatus({ type: '', message: '' }),
    onError: () => setStatus({ type: 'error', message: 'CAPTCHA verification failed' }),
    onExpire: () => setStatus({ type: 'warning', message: 'CAPTCHA expired, please verify again' })
  });

  const topics = [
    { value: 'sales', label: 'Sales Inquiry' },
    { value: 'support', label: 'Technical Support' },
    { value: 'billing', label: 'Billing Question' },
    { value: 'partnership', label: 'Partnership Opportunity' },
    { value: 'other', label: 'Other' }
  ];

  useEffect(() => {
    function handleClickOutside(event) {
      if (topicDropdownRef.current && !topicDropdownRef.current.contains(event.target)) {
        setIsTopicDropdownOpen(false);
        setActiveTopicIndex(-1);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Update form data if location state changes
    if (location.state) {
      setFormData(prev => ({
        ...prev,
        topic: location.state.topic || prev.topic,
        message: location.state.message || prev.message
      }));
    }
  }, [location.state]);

  const handleTopicKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setIsTopicDropdownOpen(!isTopicDropdownOpen);
      if (!isTopicDropdownOpen) {
        setActiveTopicIndex(0);
      }
    } else if (e.key === 'Escape') {
      setIsTopicDropdownOpen(false);
      setActiveTopicIndex(-1);
    } else if (isTopicDropdownOpen) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActiveTopicIndex(prev => (prev < topics.length - 1 ? prev + 1 : 0));
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActiveTopicIndex(prev => (prev > 0 ? prev - 1 : topics.length - 1));
      } else if (e.key === 'Tab') {
        e.preventDefault();
        if (e.shiftKey) {
          setIsTopicDropdownOpen(false);
          setActiveTopicIndex(-1);
        } else {
          if (activeTopicIndex >= 0) {
            const selectedTopic = topics[activeTopicIndex];
            setFormData(prev => ({ ...prev, topic: selectedTopic.value }));
          }
          setIsTopicDropdownOpen(false);
          setActiveTopicIndex(-1);
        }
      }
    }
  };

  const handleOptionKeyDown = (e, topic) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setFormData(prev => ({ ...prev, topic: topic.value }));
      setIsTopicDropdownOpen(false);
      setActiveTopicIndex(-1);
    } else if (e.key === 'Escape') {
      setIsTopicDropdownOpen(false);
      setActiveTopicIndex(-1);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveTopicIndex(prev => (prev < topics.length - 1 ? prev + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveTopicIndex(prev => (prev > 0 ? prev - 1 : topics.length - 1));
    } else if (e.key === 'Tab') {
      e.preventDefault();
      if (e.shiftKey) {
        setIsTopicDropdownOpen(false);
        setActiveTopicIndex(-1);
      } else {
        setFormData(prev => ({ ...prev, topic: topic.value }));
        setIsTopicDropdownOpen(false);
        setActiveTopicIndex(-1);
      }
    }
  };

  useEffect(() => {
    if (isTopicDropdownOpen && activeTopicIndex >= 0) {
      topicButtonsRef.current[activeTopicIndex]?.focus();
    }
  }, [activeTopicIndex, isTopicDropdownOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ type: '', message: '' });

    if (!turnstileToken) {
      setStatus({ type: 'error', message: 'Please complete the CAPTCHA challenge' });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          turnstileToken
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setStatus({ type: 'success', message: 'Thank you for your message. We will get back to you soon!' });
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          topic: '',
          message: ''
        });
        // Reset Turnstile after successful submission
        resetTurnstile();
      } else {
        throw new Error(data.error || 'Something went wrong');
      }
    } catch (error) {
      setStatus({ type: 'error', message: error.message });
      // Reset Turnstile on error
      resetTurnstile();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-background py-16 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>Contact HPKV | Support & Sales Inquiries</title>
        <meta name="description" content="Get in touch with the HPKV team for sales inquiries, technical support, or partnership opportunities. We're here to help with all your key-value store needs." />
        <meta name="keywords" content="contact HPKV, HPKV support, key-value store support, database sales, HPKV help, technical support" />
        <link rel="canonical" href="https://hpkv.io/contact" />
      </Helmet>
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-2xl mx-auto"
      >
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-heading-text mb-4">Contact Us</h1>
          <p className="text-lg text-text">
            Have a question or need assistance? We're here to help!
          </p>
        </div>

        <div className="bg-component-bg rounded-lg p-6 shadow-sm">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="fullName" className="block text-sm font-medium text-text mb-2">
                Full Name *
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                required
                value={formData.fullName}
                onChange={handleChange}
                className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                placeholder="Enter your full name"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-text mb-2">
                Email Address *
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                placeholder="you@example.com"
              />
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-text mb-2">
                Phone Number (Optional)
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                placeholder="+1 (555) 000-0000"
              />
            </div>

            <div>
              <label htmlFor="topic" className="block text-sm font-medium text-text mb-2">
                Topic *
              </label>
              <div className="relative" ref={topicDropdownRef}>
                <button
                  type="button"
                  id="topic"
                  tabIndex={0}
                  aria-haspopup="listbox"
                  aria-expanded={isTopicDropdownOpen}
                  aria-labelledby="topic-label topic"
                  onClick={() => setIsTopicDropdownOpen(!isTopicDropdownOpen)}
                  onKeyDown={handleTopicKeyDown}
                  className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text text-left flex justify-between items-center"
                >
                  {formData.topic ? topics.find(t => t.value === formData.topic)?.label : 'Select a topic'}
                  <svg className={`h-5 w-5 text-gray-400 transition-transform ${isTopicDropdownOpen ? 'transform rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
                {isTopicDropdownOpen && (
                  <div 
                    className="absolute z-10 mt-1 w-full bg-background border border-gray-700 rounded-lg shadow-lg"
                    role="listbox"
                    aria-labelledby="topic-label"
                  >
                    <ul className="py-1 max-h-60 overflow-auto">
                      {topics.map((topic, index) => (
                        <li key={topic.value} role="option" aria-selected={formData.topic === topic.value}>
                          <button
                            type="button"
                            tabIndex={-1}
                            ref={el => topicButtonsRef.current[index] = el}
                            className={`w-full px-4 py-2 text-left ${
                              formData.topic === topic.value 
                                ? 'bg-accent-purple/10 text-accent-purple' 
                                : 'text-text hover:bg-accent-purple/10'
                            } ${activeTopicIndex === index ? 'bg-accent-purple/10' : ''}`}
                            onClick={() => {
                              setFormData(prev => ({ ...prev, topic: topic.value }));
                              setIsTopicDropdownOpen(false);
                              setActiveTopicIndex(-1);
                            }}
                            onKeyDown={(e) => handleOptionKeyDown(e, topic)}
                          >
                            {topic.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-text mb-2">
                Message *
              </label>
              <textarea
                id="message"
                name="message"
                required
                rows={4}
                value={formData.message}
                onChange={handleChange}
                className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                placeholder="How can we help you?"
              />
            </div>

            <div className="flex justify-center">
              <div ref={turnstileRef}></div>
            </div>

            {status.message && (
              <div 
                className={`p-4 rounded-lg ${
                  status.type === 'success' 
                    ? 'bg-accent-purple/10 text-accent-purple border border-accent-purple/20' 
                    : 'bg-red-500/10 text-red-500 border border-red-500/20'
                }`}
              >
                {status.message}
              </div>
            )}

            <div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full flex justify-center py-3 px-6 rounded-lg text-white bg-accent-purple hover:bg-accent-blue transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed font-semibold`}
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default Contact; 