import React, { useState, useEffect } from 'react';
import { PencilIcon, XMarkIcon, CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import ReactCountryFlag from 'react-country-flag';

const Overview = ({
  user,
  subscription,
  onUpdateProfile,
  apiKeys = []
}) => {
  const [editingField, setEditingField] = useState(null);
  const [editedName, setEditedName] = useState(user.name);
  const [editedCompanyName, setEditedCompanyName] = useState(user.company_name || '');
  const [error] = useState('');
  const [showError, setShowError] = useState(false);
  const [latestStats, setLatestStats] = useState(null);
  const [ragUsage, setRagUsage] = useState(null);

  useEffect(() => {
    const fetchLatestStats = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/tenant-stats/latest`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch latest stats');
        const data = await response.json();
        setLatestStats(data);
      } catch (error) {
        console.error('Error fetching latest stats:', error);
      }
    };

    const fetchRagUsage = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/tenant-stats/rag-usage`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch RAG usage data');
        const data = await response.json();
        setRagUsage(data.usage);
      } catch (error) {
        console.error('Error fetching RAG usage:', error);
      }
    };

    if (apiKeys.length > 0) {
      fetchLatestStats();
      fetchRagUsage();
    }
  }, [apiKeys]);

  useEffect(() => {
    if (error) {
      setShowError(true);
      const timer = setTimeout(() => setShowError(false), 5000); // Hide error after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleSave = async (field) => {
    const updates = {
      name: field === 'name' ? editedName : user.name,
      companyName: field === 'company_name' ? editedCompanyName : (user.company_name || '')
    };
    
    await onUpdateProfile(updates);
    setEditingField(null);
  };

  const handleCancel = (field) => {
    if (field === 'name') {
      setEditedName(user.name);
    } else if (field === 'company_name') {
      setEditedCompanyName(user.company_name || '');
    }
    setEditingField(null);
  };

  const renderErrorBanner = () => {
    if (!showError || !error) return null;
    
    return (
      <div className="bg-red-500/10 text-red-500 px-6 py-3 rounded-lg shadow-lg flex items-center mb-4">
        <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
        <span>{error}</span>
        <button 
          onClick={() => setShowError(false)}
          className="ml-4 text-red-500 hover:text-red-400"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {renderErrorBanner()}
      <div className="block space-y-6 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-6">
        <div className="bg-component-bg rounded-lg p-6 shadow-sm">
          <h3 className="text-xl font-semibold text-text mb-6">User Profile</h3>
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-text mb-2">Full name</label>
              <div className="flex items-center space-x-2">
                {editingField === 'name' ? (
                  <div className="flex items-center space-x-2 flex-1">
                    <input
                      type="text"
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      className="flex-1 px-2 py-1 bg-background border border-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                    />
                    <button
                      onClick={() => handleSave('name')}
                      className="text-accent-purple hover:text-accent-blue"
                    >
                      <CheckCircleIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleCancel('name')}
                      className="text-text-secondary hover:text-text"
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                ) : (
                  <>
                    <p className="text-text flex-1">{user.name}</p>
                    <button
                      onClick={() => {
                        setEditedName(user.name);
                        setEditingField('name');
                      }}
                      className="text-text-secondary hover:text-text"
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>
                  </>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-text mb-2">Email address</label>
              <p className="text-text">{user.email}</p>
            </div>

            <div>
              <label className="block text-sm font-medium text-text mb-2">Company name</label>
              <div className="flex items-center space-x-2">
                {editingField === 'company_name' ? (
                  <div className="flex items-center space-x-2 flex-1">
                    <input
                      type="text"
                      value={editedCompanyName}
                      onChange={(e) => setEditedCompanyName(e.target.value)}
                      className="flex-1 px-2 py-1 bg-background border border-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                    />
                    <button
                      onClick={() => handleSave('company_name')}
                      className="text-accent-purple hover:text-accent-blue"
                    >
                      <CheckCircleIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleCancel('company_name')}
                      className="text-text-secondary hover:text-text"
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                ) : (
                  <>
                    <p className="text-text flex-1">{user.company_name || '-'}</p>
                    <button
                      onClick={() => {
                        setEditedCompanyName(user.company_name || '');
                        setEditingField('company_name');
                      }}
                      className="text-text-secondary hover:text-text"
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {subscription && (
          <div className="bg-component-bg rounded-lg p-6 shadow-sm">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold text-text">Current Plan</h3>
              <span className="px-3 py-1 bg-accent-purple/10 text-accent-purple rounded-full text-sm">
                Active
              </span>
            </div>
            
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <h4 className="text-lg font-medium text-text">{subscription.name}</h4>
                <span className="text-lg font-medium text-text">
                  {subscription.price_monthly > 0 ? `€${subscription.price_monthly}/month` : 'Free'}
                </span>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-3 bg-background rounded-lg border border-gray-700">
                  <p className="text-xs text-text-secondary mb-1">API Keys</p>
                  <p className="text-xl font-medium">{subscription.max_api_keys || 0}</p>
                </div>
                <div className="p-3 bg-background rounded-lg border border-gray-700">
                  <p className="text-xs text-text-secondary mb-1">Max RPS</p>
                  <p className="text-xl font-medium">{subscription.rateLimitMax ? (subscription.rateLimitMax / (subscription.rateLimitWindowMs / 1000)) : 0}</p>
                </div>
                <div className="p-3 bg-background rounded-lg border border-gray-700">
                  <p className="text-xs text-text-secondary mb-1">Max Records</p>
                  <p className="text-xl font-medium">{subscription.max_records ? subscription.max_records.toLocaleString() : 0}</p>
                </div>
                <div className="p-3 bg-background rounded-lg border border-gray-700">
                  <p className="text-xs text-text-secondary mb-1">Max Key Size</p>
                  <p className="text-xl font-medium">{subscription.maxKeySize ? `${subscription.maxKeySize} bytes` : '0 bytes'}</p>
                </div>
                <div className="p-3 bg-background rounded-lg border border-gray-700">
                  <p className="text-xs text-text-secondary mb-1">Max Value Size</p>
                  <p className="text-xl font-medium">{subscription.maxValueSize ? `${subscription.maxValueSize} bytes` : '0 bytes'}</p>
                </div>
                <div className="flex items-center justify-end">
                  <button 
                    disabled
                    className="py-2 px-4 bg-accent-purple/50 text-white rounded-lg cursor-not-allowed opacity-60 hover:bg-accent-purple/50"
                  >
                    Upgrade Plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* API Keys Overview Section */}
        <div className="mt-6 bg-component-bg rounded-lg p-6 shadow-sm col-span-2">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold text-text">API Keys</h3>
            <span className="text-sm text-text-secondary">
              {apiKeys.length} of {subscription?.max_api_keys || 0} keys used
            </span>
          </div>

          <div className="space-y-4">
            {/* Chunk API keys into groups of 4 */}
            {Array.from({ length: Math.ceil(apiKeys.length / 4) }).map((_, rowIndex) => (
              <div key={rowIndex} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {apiKeys.slice(rowIndex * 4, (rowIndex + 1) * 4).map((key) => {
                  // Get latest record count for this key
                  const keyStats = latestStats?.stats?.find(s => s.tenant_id === key.tenantId);
                  const recordCount = keyStats?.metrics?.record_count || 0;
                  const maxRecords = keyStats?.maxRecords || subscription?.max_records || 1000;
                  
                  // Get RAG usage data for this key
                  const keyRagUsage = ragUsage?.find(usage => usage.tenant_id === key.tenantId);
                  const ragCallCount = keyRagUsage?.current_usage || 0;
                  const maxRagCalls = keyRagUsage?.max_rag_calls || 100;
                  
                  return (
                    <div key={key.tenantId} className="bg-background rounded-lg border border-gray-700 p-4">
                      <div className="flex items-start justify-between mb-2">
                        <div>
                          <h4 className="font-medium text-text truncate" title={key.description || 'No description'}>
                            {key.description || 'No description'}
                          </h4>
                          <div className="flex items-center space-x-2 mt-1">
                            <ReactCountryFlag 
                              countryCode={key.country_code} 
                              svg 
                              style={{
                                width: '1.2em',
                                height: '1.2em',
                              }}
                            />
                            <p className="text-xs text-text-secondary">
                              {key.location || 'Unknown location'} 
                            </p>
                          </div>
                        </div>
                      </div>
                      
                      <div className="mt-4">
                        <div className="flex justify-between text-xs text-text-secondary mb-1">
                          <span>Records</span>
                          <span>{recordCount.toLocaleString()} / {maxRecords.toLocaleString()}</span>
                        </div>
                        <div className="h-1.5 bg-gray-700 rounded-full overflow-hidden">
                          <div 
                            className="h-full bg-accent-purple rounded-full transition-all duration-300"
                            style={{ 
                              width: `${Math.min((recordCount / maxRecords) * 100, 100)}%`
                            }}
                          />
                        </div>
                      </div>
                      
                      {/* Monthly Nexus Search Calls */}
                      <div className="mt-3">
                        <div className="flex justify-between text-xs text-text-secondary mb-1">
                          <span>Monthly Nexus Search Calls</span>
                          <span>{ragCallCount.toLocaleString()} / {maxRagCalls.toLocaleString()}</span>
                        </div>
                        <div className="h-1.5 bg-gray-700 rounded-full overflow-hidden">
                          <div 
                            className={`h-full rounded-full transition-all duration-300 ${
                              ragCallCount > maxRagCalls * 0.9 ? 'bg-red-500' : 'bg-accent-purple'
                            }`}
                            style={{ 
                              width: `${Math.min((ragCallCount / maxRagCalls) * 100, 100)}%`
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}

            {apiKeys.length === 0 && (
              <div className="text-center py-8 text-text-secondary">
                No API keys generated yet
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview; 