import React from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';

const BusinessEnterpriseBanner = () => {
  return (
    <div className="mb-6 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 border border-indigo-500/20 rounded-lg">
      <div className="px-4 py-3 flex items-center gap-2">
        <SparklesIcon className="h-5 w-5 text-indigo-400" />
        <span className="font-semibold text-indigo-400">Business+ Feature</span>
      </div>
      <div className="px-4 py-2 border-t border-indigo-500/20">
        <p className="text-indigo-200/90 text-sm">
          This feature is available exclusively to Business and Enterprise plan subscribers. 
          Please visit our <a href="/pricing" className="text-indigo-400 hover:text-indigo-300 underline">pricing page</a> for more information.
        </p>
      </div>
    </div>
  );
};

export default BusinessEnterpriseBanner; 