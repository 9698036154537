import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { MagnifyingGlassIcon, BookOpenIcon, PlayIcon, CloudIcon, BoltIcon, CodeBracketIcon, LightBulbIcon, CpuChipIcon, SparklesIcon, ChevronDownIcon, ChevronRightIcon, CubeTransparentIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';

// Import documentation components
import Overview from '../components/Docs/Overview';
import GettingStarted from '../components/Docs/GettingStarted';
import RestApi from '../components/Docs/RestApi';
import WebSocketApi from '../components/Docs/WebSocketApi';
import RiocApi from '../components/Docs/RiocApi';
import SdkGuides from '../components/Docs/SdkGuides';
import BestPractices from '../components/Docs/BestPractices';
import CodeExamples from '../components/Docs/CodeExamples';
import NexusSearch from '../components/Docs/NexusSearch';

const Docs = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [expandedTab, setExpandedTab] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const mainContentRef = useRef(null);
  const sectionRefs = useRef({});
  
  // Define header height constant for consistent use throughout the component
  const HEADER_HEIGHT = 80; // Approximate header height in pixels

  const tabs = useMemo(() => [
    { 
      id: 'overview', 
      label: 'Overview', 
      component: Overview, 
      icon: BookOpenIcon,
      description: 'HPKV Documentation Hub - Comprehensive guides for our nanosecond-level key-value store. Find API references, code examples, and best practices for optimal performance.',
      keywords: 'HPKV documentation, key-value store docs, database reference, HPKV API docs, Redis alternative documentation',
      dateModified: '2025-02-28',
      sections: [] // No sub-sections for Overview
    },
    { 
      id: 'getting-started', 
      label: 'Getting Started', 
      component: GettingStarted, 
      icon: PlayIcon,
      description: 'Get started with HPKV. Learn how to install, configure, and make your first operations with our high-performance key-value store.',
      keywords: 'HPKV getting started, key-value store setup, HPKV installation, HPKV configuration',
      dateModified: '2025-02-28',
      sections: [] // No sub-sections for Getting Started
    },
    { 
      id: 'rest-api', 
      label: 'REST API', 
      component: RestApi, 
      icon: CloudIcon,
      description: 'Complete reference for HPKV REST API. Learn about endpoints, authentication, range queries, JSON patch, and best practices for HTTP-based operations.',
      keywords: 'HPKV REST API, database REST interface, HTTP API documentation, key-value store REST, range query API, retrieve multiple records, atomic operations, atomic increment, atomic decrement, JSON patch',
      dateModified: '2025-03-07',
      sections: [
        { id: 'base-url', label: 'Base URL' },
        { id: 'endpoints', label: 'Endpoints' },
        { id: 'insert-update', label: 'Insert/Update/JSON Patch a Record' },
        { id: 'atomic-increment', label: 'Atomic Increment/Decrement' },
        { id: 'get-record', label: 'Get Record' },
        { id: 'delete-record', label: 'Delete Record' },
        { id: 'range-query', label: 'Range Query' },
        { id: 'response-formats', label: 'Response Formats' },
        { id: 'error-codes', label: 'Error Codes' }
      ]
    },
    { 
      id: 'websocket-api', 
      label: 'WebSocket API', 
      component: WebSocketApi, 
      icon: BoltIcon,
      description: 'HPKV WebSocket API Documentation - Learn to implement bidirectional, real-time data operations with persistent connections. Detailed guide for pub-sub patterns, monitoring, and efficient data streaming.',
      keywords: 'HPKV WebSocket API, real-time database API, WebSocket documentation, key-value store WebSocket, range query WebSocket, retrieve multiple records, atomic operations, atomic increment, atomic decrement, key monitoring, pub-sub, real-time notifications, JSON patch',
      dateModified: '2025-03-07',
      sections: [
        { id: 'connection', label: 'Connection' },
        { id: 'message-format', label: 'Message Format' },
        { id: 'operations', label: 'Operations' },
        { id: 'insert-record', label: 'Insert a Record' },
        { id: 'get-record', label: 'Get a Record' },
        { id: 'append-record', label: 'JSON Patch/Append to a Record' },
        { id: 'delete-record', label: 'Delete a Record' },
        { id: 'range-query', label: 'Range Query' },
        { id: 'atomic-increment', label: 'Atomic Increment/Decrement' },
        { id: 'key-monitoring', label: 'Key Monitoring (Pub-Sub)' },
        { id: 'response-format', label: 'Response Format' },
        { id: 'error-handling', label: 'Error Handling' },
        { id: 'live-demo', label: 'Live Demo' },
        { id: 'best-practices', label: 'Best Practices' }
      ]
    },
    { 
      id: 'nexus-search', 
      label: 'Nexus Search', 
      component: NexusSearch, 
      icon: CubeTransparentIcon,
      isBusinessFeature: false,
      description: 'HPKV Nexus Search Documentation - Learn how to use AI-powered semantic search and RAG capabilities with your HPKV data. Implement intelligent question answering and content discovery over your stored data.',
      keywords: 'HPKV semantic search, RAG, retrieval augmented generation, AI search, question answering, NexusSearch, vector search, embeddings, natural language processing',
      dateModified: '2025-03-15',
      sections: [
        { id: 'overview', label: 'Overview' },
        { id: 'search-endpoint', label: 'Search Endpoint' },
        { id: 'query-endpoint', label: 'Query Endpoint' },
        { id: 'code-examples', label: 'Code Examples' },
        { id: 'log-analysis', label: 'Log Analysis Example' },
        { id: 'limitations', label: 'Limitations' },
        { id: 'best-practices', label: 'Best Practices' }
      ]
    },
    { 
      id: 'rioc-api', 
      label: 'RIOC API', 
      component: RiocApi, 
      icon: CpuChipIcon,
      isBusinessFeature: true,
      description: 'Ultra-low latency RIOC protocol documentation. Learn about our native protocol for microsecond-level performance with HPKV.',
      keywords: 'HPKV RIOC API, low-latency database protocol, RIOC documentation, key-value store protocol, atomic operations, atomic increment, atomic decrement',
      dateModified: '2025-02-28',
      sections: [
        { id: 'key-benefits', label: 'Key Benefits' },
        { id: 'open-source', label: 'Open Source Client' },
        { id: 'connecting', label: 'Connecting with RIOC' },
        { id: 'basic-operations', label: 'Basic Operations' },
        { id: 'enterprise-setup', label: 'Enterprise Setup' },
        { id: 'best-practices', label: 'Best Practices' }
      ]
    },
    { 
      id: 'sdk-guides', 
      label: 'SDK Guides', 
      component: SdkGuides, 
      icon: CodeBracketIcon,
      isBusinessFeature: true,
      description: 'HPKV Client SDK Documentation - Integration guides for Node.js, Python, and .NET developers. Learn to leverage the full power of HPKV using our officially supported client libraries.',
      keywords: 'HPKV SDK, Node.js SDK, Python SDK, .NET SDK, key-value store client libraries, range query SDK, batch operations, atomic operations, atomic increment, atomic decrement',
      dateModified: '2025-02-28',
      sections: [
        { id: 'open-source', label: 'Open Source Client' },
        { id: 'installation', label: 'Installation' },
        { id: 'usage-examples', label: 'Usage Examples' },
        { id: 'sdk-reference', label: 'SDK Reference' },
        { id: 'best-practices', label: 'Best Practices' }
      ]
    },
    {
      id: 'code-examples',
      label: 'Code Examples',
      component: CodeExamples,
      icon: CodeBracketIcon,
      description: 'Practical examples of using HPKV in different programming languages. Learn how to use HPKV in your projects.',
      keywords: 'HPKV code examples, key-value store examples, HPKV programming examples',
      dateModified: '2025-02-28',
      sections: [
        { id: 'basic-crud', label: 'Basic CRUD Operations' },
        { id: 'atomic-increment', label: 'Atomic Increment/Decrement' },
        { id: 'range-queries', label: 'Range Queries' },
        { id: 'websockets', label: 'Using WebSockets' }
      ]
    },
    { 
      id: 'best-practices', 
      label: 'Best Practices', 
      component: BestPractices, 
      icon: LightBulbIcon,
      description: 'Best practices for using HPKV effectively. Learn optimization techniques, patterns, and anti-patterns.',
      keywords: 'HPKV best practices, key-value store optimization, database performance tips',
      dateModified: '2025-02-28',
      sections: [] // No sub-sections for Best Practices
    }
  ], []);

  useEffect(() => {
    const currentPath = location.pathname.split('/').pop();
    if (tabs.some(tab => tab.id === currentPath)) {
      setActiveTab(currentPath);
      // If the tab has sections, expand it
      const tab = tabs.find(t => t.id === currentPath);
      if (tab && tab.sections.length > 0) {
        setExpandedTab(currentPath);
      }
    } else {
      setActiveTab('overview');
    }
  }, [location, tabs]);

  // Initialize section refs
  useEffect(() => {
    sectionRefs.current = {};
    
    // Add a small delay to ensure the DOM is updated
    const timer = setTimeout(() => {
      // Find all section elements with IDs and add them to sectionRefs
      const sections = document.querySelectorAll('h3[id], h4[id]');
      sections.forEach(section => {
        if (section.id) {
          sectionRefs.current[section.id] = section;
        }
      });
    }, 200);
    
    return () => clearTimeout(timer);
  }, [activeTab]);

  const handleTabClick = useCallback((tabId) => {
    setActiveTab(tabId);
    navigate(`/docs/${tabId}`);
    
    // Scroll to top when changing tabs, accounting for header height
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    // Toggle expanded state for the tab
    const tab = tabs.find(t => t.id === tabId);
    if (tab && tab.sections.length > 0) {
      setExpandedTab(expandedTab === tabId ? null : tabId);
    } else {
      setExpandedTab(null);
    }
    
    setActiveSection(null);
  }, [navigate, expandedTab, tabs]);

  const handleSectionClick = useCallback((tabId, sectionId) => {
    setActiveTab(tabId);
    setActiveSection(sectionId);
    
    // Scroll to the section
    if (sectionRefs.current[sectionId]) {
      const sectionElement = sectionRefs.current[sectionId];
      const yOffset = -HEADER_HEIGHT - 20; // Add header height plus some additional offset for better visibility
      const y = sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    } else {
      // Fallback: try to find the element by ID directly
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          const yOffset = -HEADER_HEIGHT - 20;
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100); // Small delay to ensure the DOM is updated
    }
  }, []);

  const findMatches = useCallback((content, term) => {
    if (!content) return [];

    // Convert the content to a string if it's an element
    let textContent = '';
    let sectionMatches = [];
    
    // Track sections that have already been matched to avoid duplicates
    const matchedSectionIds = new Set();
    
    try {
      // Extract section information and content
      const extractSections = (node, currentSection = null) => {
        if (!node) return;
        
        // Helper function to extract code from any component
        const extractCodeFromComponent = (component) => {
          if (!component) return '';
          
          // Direct code properties
          if (component.props) {
            if (component.props.code) return component.props.code;
            if (component.props.content) return component.props.content;
            if (component.props.value) return component.props.value;
            
            // Code snippets
            if (component.props.snippets) {
              return Object.values(component.props.snippets).map(s => s.code || '').join(' ');
            }
            
            // Tabs
            if (component.props.tabs) {
              return component.props.tabs.map(tab => tab.content || '').join(' ');
            }
            
            // Children as string
            if (typeof component.props.children === 'string') {
              return component.props.children;
            }
          }
          
          return '';
        };
        
        // Check if this is a section heading
        let newSection = currentSection;
        if (React.isValidElement(node)) {
          // Check for h3 elements (main sections)
          if (node.type === 'h3' || (node.props?.className && node.props?.className.includes('text-xl font-bold'))) {
            const id = node.props?.id;
            const text = typeof node.props?.children === 'string' ? node.props.children : '';
            if (id || text) {
              newSection = {
                id: id || text.toLowerCase().replace(/\s+/g, '-'),
                title: text,
                type: 'section'
              };
            }
          }
          // Check for h4 elements (sub-sections)
          else if (node.type === 'h4' || (node.props?.className && node.props?.className.includes('text-lg font-bold'))) {
            const id = node.props?.id;
            const text = typeof node.props?.children === 'string' 
              ? node.props.children 
              : (node.props?.children?.[0] && typeof node.props.children[0] === 'string' 
                ? node.props.children[0] 
                : '');
            
            if (id || text) {
              newSection = {
                id: id || text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, ''),
                title: text,
                type: 'subsection',
                parentSection: currentSection
              };
            }
          }
          
          // Process content for search
          if (node.props) {
            // Check for code content in various formats
            
            // Direct code prop (used in some code components)
            if (node.props.code) {
              const content = node.props.code;
              const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
              const matches = content.match(regex);
              
              if (matches && newSection) {
                addMatchesToSection(newSection, matches);
              }
            }
            
            // Code snippets (used in code tabs)
            if (node.props.snippets) {
              const content = Object.values(node.props.snippets).map(s => s.code).join(' ');
              const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
              const matches = content.match(regex);
              
              if (matches && newSection) {
                addMatchesToSection(newSection, matches);
              }
            }
            
            // Check for CodeTabs component
            if (node.type && (
                node.type.name === 'CodeTabs' || 
                node.type.displayName === 'CodeTabs' ||
                (typeof node.type === 'string' && node.type.toLowerCase().includes('codetabs'))
            )) {
              // Extract tabs from CodeTabs component
              if (node.props.tabs && Array.isArray(node.props.tabs)) {
                node.props.tabs.forEach(tab => {
                  if (tab.content) {
                    const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
                    const matches = tab.content.match(regex);
                    
                    if (matches && newSection) {
                      addMatchesToSection(newSection, matches);
                    }
                  }
                });
              }
              
              // Also check children for code blocks
              if (node.props.children) {
                // Handle array of children
                if (Array.isArray(node.props.children)) {
                  node.props.children.forEach(child => {
                    if (React.isValidElement(child)) {
                      const codeContent = extractCodeFromComponent(child);
                      if (codeContent) {
                        const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
                        const matches = codeContent.match(regex);
                        
                        if (matches && newSection) {
                          addMatchesToSection(newSection, matches);
                        }
                      }
                    }
                  });
                } 
                // Handle single child
                else if (React.isValidElement(node.props.children)) {
                  const codeContent = extractCodeFromComponent(node.props.children);
                  if (codeContent) {
                    const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
                    const matches = codeContent.match(regex);
                    
                    if (matches && newSection) {
                      addMatchesToSection(newSection, matches);
                    }
                  }
                }
              }
            }
            
            // Check for CodeBlock component
            if (node.type && (
                node.type.name === 'CodeBlock' || 
                node.type.displayName === 'CodeBlock' ||
                (typeof node.type === 'string' && node.type.toLowerCase().includes('code'))
            )) {
              let codeContent = extractCodeFromComponent(node);
              
              if (codeContent) {
                const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
                const matches = codeContent.match(regex);
                
                if (matches && newSection) {
                  addMatchesToSection(newSection, matches);
                }
              }
            }
            
            // Check for pre and code elements
            if ((node.type === 'pre' || node.type === 'code') && node.props.children) {
              let codeContent = '';
              if (typeof node.props.children === 'string') {
                codeContent = node.props.children;
              } else if (React.isValidElement(node.props.children) && node.props.children.props?.children) {
                if (typeof node.props.children.props.children === 'string') {
                  codeContent = node.props.children.props.children;
                }
              }
              
              if (codeContent) {
                const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
                const matches = codeContent.match(regex);
                
                if (matches && newSection) {
                  addMatchesToSection(newSection, matches);
                }
              }
            }
            
            // Process text content
            if (typeof node.props.children === 'string') {
              const content = node.props.children;
              const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
              const matches = content.match(regex);
              
              if (matches && newSection) {
                addMatchesToSection(newSection, matches);
              }
            }
            
            // Recursively process children
            if (node.props.children) {
              if (Array.isArray(node.props.children)) {
                node.props.children.forEach(child => extractSections(child, newSection));
              } else {
                extractSections(node.props.children, newSection);
              }
            }
          }
        } else if (typeof node === 'string') {
          // Process text nodes
          const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
          const matches = node.match(regex);
          
          if (matches && currentSection) {
            addMatchesToSection(currentSection, matches);
          }
        } else if (Array.isArray(node)) {
          // Process arrays of nodes
          node.forEach(child => extractSections(child, currentSection));
        }
      };
      
      // Helper function to add matches to a section, avoiding duplicates
      const addMatchesToSection = (section, matches) => {
        if (!section) return;
        
        // Create a unique ID for the section
        const sectionId = section.id || 'unknown';
        
        // Find existing section match or create a new one
        let sectionMatch = sectionMatches.find(sm => 
          sm.section && sm.section.id === sectionId && sm.section.type === section.type
        );
        
        if (!sectionMatch) {
          sectionMatch = {
            section: section,
            matches: []
          };
          sectionMatches.push(sectionMatch);
          matchedSectionIds.add(sectionId);
        }
        
        // Add unique matches
        const existingMatchTexts = new Set(sectionMatch.matches.map(m => m.text));
        matches.forEach(match => {
          const trimmedMatch = match.trim();
          if (!existingMatchTexts.has(trimmedMatch)) {
            sectionMatch.matches.push({ text: trimmedMatch });
            existingMatchTexts.add(trimmedMatch);
          }
        });
      };
      
      // Start extraction from the root
      extractSections(content);
      
      // Handle the case where we don't have section information
      if (sectionMatches.length === 0) {
        // Fall back to the old method for compatibility
        if (typeof content === 'string') {
          textContent = content;
        } else if (Array.isArray(content)) {
          textContent = content.map(item => {
            if (typeof item === 'string') return item;
            if (typeof item === 'object' && item !== null) {
              const codeContent = 
                (item.props?.code) || 
                (item.props?.snippets && Object.values(item.props.snippets).map(s => s.code).join(' ')) || 
                (item.props?.children?.props?.children?.props?.code) || 
                item.props?.children || '';
              return codeContent;
            }
            return '';
          }).join(' ');
        } else if (typeof content === 'object' && content !== null) {
          const traverse = (node) => {
            if (!node) return '';
            if (typeof node === 'string') return node;
            if (Array.isArray(node)) return node.map(traverse).join(' ');
            
            if (node.props) {
              // Check for code content in various formats
              if (node.props.code) return node.props.code;
              if (node.props.content) return node.props.content;
              if (node.props.value) return node.props.value;
              
              // Check for code snippets
              if (node.props.snippets) {
                return Object.values(node.props.snippets).map(s => s.code || '').join(' ');
              }
              
              // Check for tabs
              if (node.props.tabs) {
                return node.props.tabs.map(tab => tab.content || '').join(' ');
              }
              
              // Special handling for CodeTabs and CodeBlock components
              if (node.type && (
                  node.type.name === 'CodeTabs' || 
                  node.type.displayName === 'CodeTabs' ||
                  node.type.name === 'CodeBlock' || 
                  node.type.displayName === 'CodeBlock' ||
                  (typeof node.type === 'string' && 
                   (node.type.toLowerCase().includes('code') || 
                    node.type.toLowerCase().includes('pre')))
              )) {
                // Try to extract code from various properties
                let codeContent = '';
                
                if (node.props.code) codeContent += ' ' + node.props.code;
                if (node.props.content) codeContent += ' ' + node.props.content;
                if (node.props.value) codeContent += ' ' + node.props.value;
                
                if (node.props.snippets) {
                  codeContent += ' ' + Object.values(node.props.snippets).map(s => s.code || '').join(' ');
                }
                
                if (node.props.tabs) {
                  codeContent += ' ' + node.props.tabs.map(tab => tab.content || '').join(' ');
                }
                
                if (codeContent.trim()) return codeContent;
              }
              
              if (node.props.children) {
                if (typeof node.props.children === 'string') return node.props.children;
                if (Array.isArray(node.props.children)) return node.props.children.map(traverse).join(' ');
                return traverse(node.props.children);
              }
            }
            return '';
          };
          textContent = traverse(content);
        }
        
        // Clean up the text content
        textContent = textContent.replace(/\s+/g, ' ').trim();

        // Find matches
        const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
        const matches = textContent.match(regex);
        if (matches) {
          // Deduplicate matches
          const uniqueMatches = [...new Set(matches.map(m => m.trim()))];
          sectionMatches.push({
            section: null,
            matches: uniqueMatches.map(match => ({ text: match }))
          });
        }
      }
      
      return sectionMatches;
    } catch (error) {
      console.error('Error extracting text content:', error);
      return [];
    }
  }, []);

  const searchDocs = useCallback((term) => {
    return tabs.map(tab => {
      try {
        const component = tab.component();
        const sectionMatches = findMatches(component, term);
        
        if (sectionMatches.length === 0) return null;
        
        return {
          id: tab.id,
          label: tab.label,
          sections: tab.sections || [],
          sectionMatches: sectionMatches
        };
      } catch (error) {
        console.error(`Error searching in ${tab.label}:`, error);
        return null;
      }
    }).filter(Boolean);
  }, [tabs, findMatches]);

  const handleSearch = useCallback((e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term.length > 2) {
      const results = searchDocs(term);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchDocs]);

  const handleSearchResultClick = useCallback((result, sectionMatch) => {
    setIsSearchOpen(false);
    
    // Navigate to the tab
    setActiveTab(result.id);
    navigate(`/docs/${result.id}`);
    
    // If there's a section, scroll to it
    if (sectionMatch.section) {
      // Small delay to ensure the component is mounted
      setTimeout(() => {
        setActiveSection(sectionMatch.section.id);
        
        // Scroll to the section
        if (sectionRefs.current[sectionMatch.section.id]) {
          const sectionElement = sectionRefs.current[sectionMatch.section.id];
          const yOffset = -HEADER_HEIGHT - 20;
          const y = sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        } else {
          // Fallback: try to find the element by ID directly
          setTimeout(() => {
            const element = document.getElementById(sectionMatch.section.id);
            if (element) {
              const yOffset = -HEADER_HEIGHT - 20;
              const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
              window.scrollTo({ top: y, behavior: 'smooth' });
            }
          }, 100);
        }
      }, 300);
    } else {
      // If no section, just scroll to top
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [navigate, HEADER_HEIGHT]);

  const activeTabData = tabs.find(tab => tab.id === activeTab) || tabs[0];
  const currentUrl = `https://hpkv.io${location.pathname}`;

  // Wrap the component with section refs
  const SectionWrapper = ({ children, sectionIds = [] }) => {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) return child;

      // Process h3 elements to add refs for section navigation
      if (child.type === 'h3' || (child.props && child.props.className && child.props.className.includes('text-xl font-bold'))) {
        // Use the existing id if available, otherwise generate one from text
        const existingId = child.props.id;
        const text = child.props.children;
        const sectionId = existingId || (typeof text === 'string' ? text.toLowerCase().replace(/\s+/g, '-') : '');
        
        if (sectionId) {
          // Always add the ref, but prioritize sections defined in the tabs array
          return React.cloneElement(child, {
            ref: (el) => {
              if (el) sectionRefs.current[sectionId] = el;
            },
            id: sectionId
          });
        }
      }

      // Process h4 elements for sub-sections
      if (child.type === 'h4' || (child.props && child.props.className && child.props.className.includes('text-lg font-bold'))) {
        // Use the existing id if available, otherwise generate one from text
        const existingId = child.props.id;
        const text = typeof child.props.children === 'string' 
          ? child.props.children 
          : (child.props.children[0] && typeof child.props.children[0] === 'string' 
            ? child.props.children[0] 
            : '');
            
        const sectionId = existingId || (text ? text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '') : '');
        
        if (sectionId) {
          // Always add the ref, but prioritize sections defined in the tabs array
          return React.cloneElement(child, {
            ref: (el) => {
              if (el) sectionRefs.current[sectionId] = el;
            },
            id: sectionId
          });
        }
      }

      // Recursively process children
      if (child.props && child.props.children) {
        return React.cloneElement(child, {
          children: <SectionWrapper sectionIds={sectionIds}>{child.props.children}</SectionWrapper>
        });
      }

      return child;
    });
  };

  // Wrap the component to add section refs
  const WrappedComponent = ({ Component }) => {
    const component = Component();
    
    // Get the current tab's section IDs
    const currentTab = tabs.find(tab => tab.id === activeTab);
    const sectionIds = currentTab?.sections?.map(section => section.id) || [];
    
    return <SectionWrapper sectionIds={sectionIds}>{component}</SectionWrapper>;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="min-h-screen bg-background"
    >
      <Helmet>
        {/* Basic Meta Tags */}
        <html lang="en" />
        <title>{activeTabData.label} | HPKV Documentation</title>
        <meta name="description" content={activeTabData.description} />
        <meta name="keywords" content={activeTabData.keywords} />
        <meta name="author" content="HPKV Team" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="HPKV Documentation" />
        <meta property="og:title" content={`${activeTabData.label} | HPKV Documentation`} />
        <meta property="og:description" content={activeTabData.description} />
        <meta property="og:image" content="https://hpkv.io/images/hpkv-illustration.png" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content="en_US" />
        <meta property="article:modified_time" content={activeTabData.dateModified} />
        <meta property="article:section" content="Documentation" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hpkvHQ" />
        <meta name="twitter:title" content={`${activeTabData.label} | HPKV Documentation`} />
        <meta name="twitter:description" content={activeTabData.description} />
        <meta name="twitter:image" content="https://hpkv.io/images/hpkv-illustration.png" />
        
        {/* Canonical URL */}
        <link rel="canonical" href={currentUrl} />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TechArticle",
            "headline": `${activeTabData.label} | HPKV Documentation`,
            "description": activeTabData.description,
            "keywords": activeTabData.keywords,
            "dateModified": activeTabData.dateModified,
            "image": "https://hpkv.io/images/hpkv-illustration.png",
            "author": {
              "@type": "Organization",
              "name": "HPKV Team",
              "url": "https://hpkv.io"
            },
            "publisher": {
              "@type": "Organization",
              "name": "HPKV",
              "logo": {
                "@type": "ImageObject",
                "url": "https://hpkv.io/images/hpkv-illustration.png"
              }
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": currentUrl
            },
            "articleSection": "Documentation",
            "isAccessibleForFree": true,
            "inLanguage": "en-US"
          })}
        </script>
      </Helmet>
      <div className="container mx-auto px-4 pt-20 pb-8">
        <div className="flex items-center mb-8">
          <button
            onClick={() => setIsSearchOpen(true)}
            className="flex items-center text-text hover:text-heading-text transition-colors"
          >
            <MagnifyingGlassIcon className="h-6 w-6 mr-2" />
            <span>Search...</span>
          </button>
        </div>
        <div className="flex flex-col md:flex-row">
          <nav className="w-full md:w-1/4 mb-8 md:mb-0 md:sticky md:top-24 md:max-h-[calc(100vh-120px)] md:overflow-y-auto">
            <ul>
              {tabs.map((tab, index) => (
                <motion.li
                  key={tab.id}
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="mb-2"
                >
                  <div className="flex flex-col">
                    <button
                      onClick={() => handleTabClick(tab.id)}
                      className={`w-full text-left px-4 py-2 rounded transition-colors duration-200 flex items-center justify-between ${
                        activeTab === tab.id
                          ? 'bg-primary/20 text-primary font-medium'
                          : 'bg-component-bg text-text hover:bg-secondary/50'
                      }`}
                    >
                      <div className="flex items-center">
                        <tab.icon className="h-5 w-5 mr-2" />
                        {tab.label}
                      </div>
                      <div className="flex items-center">
                        {tab.isBusinessFeature && (
                          <div className="flex items-center mr-2 px-2 py-0.5 rounded bg-indigo-500/10 border border-indigo-500/20">
                            <SparklesIcon className="h-3 w-3 text-indigo-400 mr-1" />
                            <span className="text-xs text-indigo-400">Business+</span>
                          </div>
                        )}
                        {tab.sections.length > 0 && (
                          expandedTab === tab.id 
                            ? <ChevronDownIcon className="h-4 w-4" /> 
                            : <ChevronRightIcon className="h-4 w-4" />
                        )}
                      </div>
                    </button>
                    
                    {/* Sub-sections */}
                    {tab.sections.length > 0 && (
                      <AnimatePresence mode="wait">
                        {expandedTab === tab.id && (
                          <motion.ul
                            key={`${tab.id}-sections`}
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                            className="ml-7 mt-1 border-l border-secondary/30"
                          >
                            {tab.sections.map((section) => (
                              <motion.li key={section.id} className="pl-4 py-1">
                                <button
                                  onClick={() => handleSectionClick(tab.id, section.id)}
                                  className={`text-sm transition-colors duration-200 ${
                                    activeSection === section.id
                                      ? 'text-primary font-medium'
                                      : 'text-text hover:text-primary'
                                  }`}
                                >
                                  {section.label}
                                </button>
                              </motion.li>
                            ))}
                          </motion.ul>
                        )}
                      </AnimatePresence>
                    )}
                  </div>
                </motion.li>
              ))}
            </ul>
          </nav>
          <main ref={mainContentRef} className="w-full md:w-3/4 md:pl-8">
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.pathname}>
                {tabs.map((tab) => (
                  <Route
                    key={tab.id}
                    path={`/${tab.id}`}
                    element={
                      <motion.div
                        initial={{ x: 20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -20, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <WrappedComponent Component={tab.component} />
                      </motion.div>
                    }
                  />
                ))}
                <Route path="/" element={<WrappedComponent Component={Overview} />} />
              </Routes>
            </AnimatePresence>
          </main>
        </div>
      </div>
      <AnimatePresence>
        {isSearchOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-[100]"
            onClick={() => setIsSearchOpen(false)}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="bg-component-bg rounded-lg shadow-xl w-full max-w-2xl border border-secondary"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="p-4">
                <input
                  type="text"
                  placeholder="Search documentation..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="w-full p-2 bg-background text-text border border-secondary rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder-text/50"
                  autoFocus
                />
              </div>
              {searchResults.length > 0 && (
                <ul className="max-h-96 overflow-y-auto border-t border-secondary">
                  {searchResults.map((result, index) => (
                    <li key={`${result.id}-${index}`} className="border-b border-secondary last:border-b-0">
                      <div className="p-4">
                        <h3 className="font-semibold text-primary">{result.label}</h3>
                        <ul className="mt-2 space-y-3">
                          {result.sectionMatches.map((sectionMatch, sectionIndex) => (
                            <li key={sectionIndex} className="pl-2 border-l-2 border-secondary/50">
                              <button
                                onClick={() => handleSearchResultClick(result, sectionMatch)}
                                className="w-full text-left hover:bg-secondary/30 transition-colors rounded p-2"
                              >
                                {sectionMatch.section ? (
                                  <div className="flex items-center mb-1">
                                    <span className={`font-medium ${sectionMatch.section.type === 'subsection' ? 'text-sm text-text/80 ml-2' : 'text-text'}`}>
                                      {sectionMatch.section.title || sectionMatch.section.id}
                                    </span>
                                  </div>
                                ) : null}
                                {sectionMatch.matches.slice(0, 2).map((match, matchIndex) => (
                                  <p key={matchIndex} className="text-sm text-text/70 mt-1">
                                    ...{match.text}...
                                  </p>
                                ))}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Docs;
