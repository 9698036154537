import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { CheckIcon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { useNavigate, useLocation } from 'react-router-dom';
import NotifyDialog from './NotifyDialog';
import { Helmet } from 'react-helmet';

const tiers = [
  {
    id: 1,
    name: 'Free',
    price: { monthly: 0, yearly: 0 },
    description: 'Whether it\'s for fun or a side project, you can get started with us for free.',
    features: {
      limits: ['600 requests/minute', '1000 records', 'Unlimited Operations', '128 bytes Key', '3 KB Value', 'Nexus Search: 100 calls/month', 'Nexus Search: 12 req/min max'],
      server_type: ['Shared Server', 'Daily Backups'],
      high_availability: ['No High Availability'],
      features: ['REST API', 'gRPC API', 'Latency as low as 5 ms ¹', 'No RIOC (Remote IO Control)', 'No Nanosecond latency', 'Nexus Search: 24K context tokens, 1K output tokens', 'No Nexus Search agent mode'],
      support: ['Basic Support']
    }
  },
  {
    id: 2,
    name: 'Pro',
    price: { monthly: 19, yearly: 15 },
    description: 'For small businesses and startups.',
    features: {
      limits: ['6000 requests/minute', '100,000 records', 'Unlimited Operations', '256 bytes Key', '10 KB Value', 'Nexus Search: 500 calls/month', 'Nexus Search: 24 req/min max'],
      server_type: ['Shared Server', 'Daily Backups'],
      high_availability: ['No High Availability'],
      features: ['REST API', 'gRPC API', 'Latency as low as 1 ms ¹', 'No RIOC (Remote IO Control)', 'No Nanosecond latency', 'Nexus Search: 24K context tokens, 5K output tokens', 'No Nexus Search agent mode'],
      support: ['Premium Support']
    }
  },
  {
    id: 3,
    name: 'Business',
    price: { monthly: 120, yearly: 96 },
    description: 'For medium-sized businesses and startups.',
    features: {
      limits: ['60,000 requests/minute', '10M records or 100 GB of data ⁴', 'Unlimited Operations', '500 bytes Key ⁴', '100 KB Value ⁴', 'Nexus Search: 5000 calls/month', 'Nexus Search: 60 req/min max'],
      server_type: ['Dedicated Server', 'Data Recovery Guarantee'],
      high_availability: ['Multi-region replication & High Availability (99.99% uptime)'],
      features: ['REST API', 'gRPC API', 'Latency as low as 150 µs ¹', 'RIOC (Remote IO Control) ³', 'No Nanosecond latency', 'Nexus Search: 80K context tokens, 10K output tokens', 'Nexus Search: Agent mode with tool calling'],
      support: ['Premium Support']
    }
  },
  {
    id: 4,
    name: 'Enterprise',
    price: { monthly: 0, yearly: 0 },
    description: 'For large enterprises.',
    features: {
      limits: ['Up to 3,000,000 requests/second (single node - sequential)', '1B records or 10TB of data ⁴','Unlimited Operations', '1000 bytes Key ⁴', '10 MB Value ⁴', 'Nexus Search: Unlimited calls', 'Nexus Search: 120 req/min max'],
      server_type: ['Dedicated Server', 'Data Recovery Guarantee'],
      high_availability: ['Multi-region replication & High Availability (99.99% uptime) or Nanosecond Latency'],
      features: ['REST API', 'gRPC API', 'Latency as low as 250 ns ²', 'RIOC (Remote IO Control) ³', 'Nanosecond latency', 'Nexus Search: 110K context tokens, 50K output tokens', 'Nexus Search: Agent mode with tool calling'],
      support: ['24/7 Premium Support']
    }
  },
];

const FeatureSection = ({ title, features, isCollapsed, onToggleCollapse, planId }) => {
  return (
    <div className="mb-6">
      <button 
        onClick={() => onToggleCollapse(title, planId)}
        className="w-full flex justify-between items-center text-sm font-semibold text-heading-text mb-2 hover:text-accent-purple transition-colors"
      >
        <span>{title}</span>
        <ChevronDownIcon 
          className={`h-4 w-4 transition-transform duration-200 ${isCollapsed ? '' : 'transform rotate-180'}`} 
        />
      </button>
      <div className={`transition-all duration-200 ${isCollapsed ? 'h-0 opacity-0 overflow-hidden' : 'h-auto opacity-100'}`}>
        <ul className="space-y-2">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center text-text">
              {feature.includes('No') ? (
                <XMarkIcon className="h-4 w-4 text-red-500 mr-2 flex-shrink-0" />
              ) : (
                <CheckIcon className="h-4 w-4 text-accent-purple mr-2 flex-shrink-0" />
              )}
              <span className="text-sm">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Pricing = () => {
  const [isYearly, setIsYearly] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState(null);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({
    'Server Type': true,
    'High Availability': true,
    'Features': true,
    'Support': true,
    'Limitations': false
  });
  const [mobileCollapsedSections, setMobileCollapsedSections] = useState(
    tiers.reduce((acc, tier) => ({
      ...acc,
      [tier.id]: {
        'Server Type': true,
        'High Availability': true,
        'Features': true,
        'Support': true,
        'Limitations': false
      }
    }), {})
  );
  const navigate = useNavigate();
  const location = useLocation();
  const isStandalonePage = location.pathname === '/pricing';

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // md breakpoint in Tailwind
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsLoggedIn(true);
        try {
          const response = await fetch('/api/subscriptions', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setCurrentPlanId(data.id);
          }
        } catch (error) {
          console.error('Failed to fetch subscription:', error);
        }
      } else {
        setIsLoggedIn(false);
        setCurrentPlanId(null);
      }
    };

    checkLoginStatus();
  }, []);

  const handleToggleCollapse = (sectionTitle, planId) => {
    if (isMobile) {
      setMobileCollapsedSections(prev => ({
        ...prev,
        [planId]: {
          ...prev[planId],
          [sectionTitle]: !prev[planId][sectionTitle]
        }
      }));
    } else {
      setCollapsedSections(prev => ({
        ...prev,
        [sectionTitle]: !prev[sectionTitle]
      }));
    }
  };

  const handleGetStarted = (tier) => {
    if (tier.name === 'Free' && !isLoggedIn) {
      navigate('/signup');
    } else if (tier.name === 'Enterprise') {
      navigate('/contact', {
        state: {
          topic: 'sales',
          message: `I'm interested in learning more about HPKV Enterprise. Here are some details about our needs:

Company/Organization: [Please provide your company name]

Current Setup:
- Current database/storage solution: 
- Approximate data size:
- Current request rate:

Requirements:
- Expected request rate:
- Expected data size:
- Geographic regions needed:
- High availability requirements:
- Any specific feature requirements:

Preferred Contact Time:
- Timezone:
- Best days/times for a call:
- Alternative meeting times:

Additional Information:
[Please add any other relevant information or specific questions you'd like to discuss]

Thank you for your time. I look forward to discussing how HPKV Enterprise can help meet our needs.`
        }
      });
    } else if (tier.name === 'Pro' || tier.name === 'Business') {
      setSelectedPlan(tier.name);
      setIsNotifyDialogOpen(true);
    }
  };

  return (
    <section className="py-20 bg-background">
      {isStandalonePage && (
        <Helmet>
          <title>HPKV Pricing | Plans and Features Comparison</title>
          <meta name="description" content="Explore HPKV's pricing plans - from free to enterprise. Compare features, limits, and benefits to find the perfect key-value store solution for your needs." />
          <meta name="keywords" content="HPKV pricing, key-value store pricing, database pricing, high performance database plans, Redis alternative cost" />
          <link rel="canonical" href="https://hpkv.io/pricing" />
        </Helmet>
      )}
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <p className="text-sm font-semibold text-accent-purple mb-4">Pricing plans</p>
          <h2 className="text-3xl md:text-4xl font-bold text-heading-text mb-6">
            Flexible plans and features
          </h2>
          <p className="text-xl text-text mb-8">
            Choose the perfect plan for your needs. Always know what you'll pay.
          </p>

          {/* Billing toggle */}
          <div className="flex items-center justify-center space-x-3">
            <span className={`text-sm ${!isYearly ? 'text-heading-text' : 'text-text'}`}>Monthly</span>
            <button
              onClick={() => setIsYearly(!isYearly)}
              className="relative inline-flex h-6 w-11 items-center rounded-full bg-accent-purple/20"
            >
              <span className="sr-only">Enable yearly billing</span>
              <span
                className={`inline-block h-4 w-4 transform rounded-full bg-accent-purple transition ${
                  isYearly ? 'translate-x-6' : 'translate-x-1'
                }`}
              />
            </button>
            <span className={`text-sm ${isYearly ? 'text-heading-text' : 'text-text'}`}>
              Yearly <span className="text-accent-purple">(-20%)</span>
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
          {tiers.map((tier, index) => (
            <motion.div
              key={tier.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative bg-component-bg rounded-2xl p-8 border border-gray-800 flex flex-col"
            >
              {currentPlanId === tier.id && (
                <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                  <span className="px-3 py-1 bg-accent-purple text-white text-sm rounded-full">
                    Your Current Plan
                  </span>
                </div>
              )}
              <div className="mb-6">
                <h3 className="text-xl font-semibold text-heading-text mb-2">{tier.name}</h3>
                <div className="flex items-baseline mb-2">
                  {tier.name === 'Enterprise' ? (
                    <span className="text-2xl font-medium text-text-secondary">Contact Us</span>
                  ) : (
                    <>
                      <span className="text-4xl font-bold text-heading-text">
                        €{isYearly ? tier.price.yearly : tier.price.monthly}
                      </span>
                      <span className="text-text ml-1">/mo</span>
                    </>
                  )}
                </div>
                <p className="text-text">{tier.description}</p>
              </div>

              <div className="flex-grow">
                <FeatureSection 
                  title="Limitations" 
                  features={tier.features.limits}
                  isCollapsed={isMobile ? mobileCollapsedSections[tier.id]['Limitations'] : collapsedSections['Limitations']}
                  onToggleCollapse={handleToggleCollapse}
                  planId={tier.id}
                />
                <FeatureSection 
                  title="Server Type" 
                  features={tier.features.server_type}
                  isCollapsed={isMobile ? mobileCollapsedSections[tier.id]['Server Type'] : collapsedSections['Server Type']}
                  onToggleCollapse={handleToggleCollapse}
                  planId={tier.id}
                />
                <FeatureSection 
                  title="High Availability" 
                  features={tier.features.high_availability}
                  isCollapsed={isMobile ? mobileCollapsedSections[tier.id]['High Availability'] : collapsedSections['High Availability']}
                  onToggleCollapse={handleToggleCollapse}
                  planId={tier.id}
                />
                <FeatureSection 
                  title="Features" 
                  features={tier.features.features}
                  isCollapsed={isMobile ? mobileCollapsedSections[tier.id]['Features'] : collapsedSections['Features']}
                  onToggleCollapse={handleToggleCollapse}
                  planId={tier.id}
                />
                <FeatureSection 
                  title="Support" 
                  features={tier.features.support}
                  isCollapsed={isMobile ? mobileCollapsedSections[tier.id]['Support'] : collapsedSections['Support']}
                  onToggleCollapse={handleToggleCollapse}
                  planId={tier.id}
                />
              </div>

              {currentPlanId === tier.id ? (
                <button 
                  disabled
                  className="w-full py-2 px-4 rounded-lg bg-gray-700 text-gray-400 cursor-not-allowed font-semibold"
                >
                  Current Plan
                </button>
              ) : tier.name === 'Free' ? (
                <button 
                  onClick={() => handleGetStarted(tier)}
                  className="w-full py-2 px-4 rounded-lg bg-accent-purple hover:bg-accent-blue transition-colors duration-200 text-white font-semibold"
                >
                  Get Started
                </button>
              ) : tier.name === 'Enterprise' ? (
                <button 
                  onClick={() => handleGetStarted(tier)}
                  className="w-full py-2 px-4 rounded-lg bg-accent-purple hover:bg-accent-blue transition-colors duration-200 text-white font-semibold"
                >
                  Contact Us
                </button>
              ) : (
                <button 
                  onClick={() => handleGetStarted(tier)}
                  className="w-full py-2 px-4 rounded-lg bg-accent-purple hover:bg-accent-blue transition-colors duration-200 text-white font-semibold"
                >
                  {isLoggedIn ? 'Upgrade' : 'Get Started'}
                </button>
              )}
            </motion.div>
          ))}
        </div>
        
        <div className="mt-12 text-xs text-text-secondary text-left max-w-7xl mx-auto px-4">
          ¹ The stated latency represents server-side processing time. Actual end-to-end response times may vary depending on network conditions and geographical distance to our servers.
          <br />
          ² Nanosecond latency is achievable when the client is running on the same machine as the HPKV Enterprise.
          <br />
          ³ RIOC (Remote IO Control) is a fast and secure (TLS 1.3) RPC protocol that allows you to call HPKV from your applications via our SDKs available for most languages and platforms.
          <br />
          ⁴ This value can be increased by contacting us.
        </div>
      </div>

      <NotifyDialog
        isOpen={isNotifyDialogOpen}
        onClose={() => {
          setIsNotifyDialogOpen(false);
          setSelectedPlan(null);
        }}
        planName={selectedPlan}
      />
    </section>
  );
};

export default Pricing;
