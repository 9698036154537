import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';

const MermaidDiagram = ({ chart }) => {
  const ref = useRef(null);

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      securityLevel: 'loose',
      theme: 'dark',
      fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      themeVariables: {
        primaryColor: '#6366f1',
        primaryTextColor: '#f3f4f6',
        primaryBorderColor: '#4f46e5',
        lineColor: '#9ca3af',
        secondaryColor: '#4f46e5',
        tertiaryColor: '#312e81',
        textColor: '#f3f4f6',
        mainBkg: '#1f2937',
        nodeBorder: '#4f46e5',
        clusterBkg: '#1f2937',
        titleColor: '#f3f4f6',
        edgeLabelBackground: '#1f2937',
        nodeTextColor: '#f3f4f6',
      },
      flowchart: {
        htmlLabels: true,
        useMaxWidth: true,
        curve: 'basis'
      }
    });

    if (ref.current) {
      ref.current.innerHTML = '';
      mermaid.render('mermaid-diagram', chart).then(({ svg }) => {
        ref.current.innerHTML = svg;
        
        const svgElement = ref.current.querySelector('svg');
        if (svgElement) {
          // Set SVG dimensions and viewBox
          svgElement.style.width = '100%';
          svgElement.style.height = '100%';
          svgElement.setAttribute('width', '100%');
          svgElement.setAttribute('height', '100%');
          
          // Convert text elements to foreignObject with HTML
          const textElements = svgElement.querySelectorAll('text');
          textElements.forEach(text => {
            const content = text.textContent;
            const x = text.getAttribute('x') || 0;
            const y = text.getAttribute('y') || 0;
            const transform = text.getAttribute('transform') || '';
            const textAnchor = text.style.textAnchor || 'start';
            
            // Create foreignObject
            const fo = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            fo.setAttribute('x', x);
            fo.setAttribute('y', parseInt(y) - 10); // Adjust y position for better alignment
            fo.setAttribute('width', '200');
            fo.setAttribute('height', '50');
            fo.setAttribute('transform', transform);
            
            // Create HTML div
            const div = document.createElement('div');
            div.textContent = content;
            div.style.color = '#f3f4f6';
            div.style.fontFamily = 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
            div.style.fontSize = '14px';
            div.style.textAlign = textAnchor;
            div.style.whiteSpace = 'nowrap';
            div.style.padding = '2px';
            
            fo.appendChild(div);
            text.parentNode.replaceChild(fo, text);
          });

          // Fix edge labels
          const edgeLabels = svgElement.querySelectorAll('.edgeLabel');
          edgeLabels.forEach(label => {
            const fo = label.querySelector('foreignObject');
            if (fo) {
              fo.style.overflow = 'visible';
              const div = fo.querySelector('div');
              if (div) {
                div.style.color = '#f3f4f6';
                div.style.fontFamily = 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
                div.style.fontSize = '14px';
                div.style.whiteSpace = 'nowrap';
                div.style.textAlign = 'center';
                div.style.padding = '2px';
              }
            }
          });

          // Fix cluster labels
          const clusterLabels = svgElement.querySelectorAll('.cluster-label');
          clusterLabels.forEach(label => {
            const fo = label.querySelector('foreignObject');
            if (fo) {
              fo.style.overflow = 'visible';
              const div = fo.querySelector('div');
              if (div) {
                div.style.color = '#f3f4f6';
                div.style.fontFamily = 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
                div.style.fontSize = '14px';
                div.style.whiteSpace = 'nowrap';
                div.style.textAlign = 'center';
                div.style.padding = '2px';
              }
            }
          });
        }
      });
    }
  }, [chart]);

  return (
    <div 
      ref={ref} 
      className="mermaid-diagram" 
      style={{ 
        width: '100%', 
        height: '100%',
        color: '#f3f4f6',
        backgroundColor: '#1f2937',
        borderRadius: '0.5rem',
        padding: '1rem'
      }} 
    />
  );
};

export default MermaidDiagram; 