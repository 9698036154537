import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { format, differenceInDays, addMonths, startOfMonth, endOfMonth, eachMonthOfInterval, subMonths } from 'date-fns';
import roadmapData from '../data/roadmap.json';
import { CalendarIcon } from '@heroicons/react/24/outline';

const Roadmap = ({ className = '' }) => {
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [containerWidth, setContainerWidth] = useState(1000);
  const containerRef = useRef(null);
  const today = new Date();

  // Update container width when component mounts and when category changes
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(Math.max(containerRef.current.offsetWidth || 1000, 1000));
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [selectedCategory]);

  // Calculate timeline bounds
  const milestones = roadmapData.milestones.filter(
    milestone => !selectedCategory || milestone.category === selectedCategory
  );
  const dates = milestones.map(m => new Date(m.date));
  
  // Always start timeline 2 months before today
  const timelineStart = startOfMonth(subMonths(today, 2));
  // End timeline at the latest milestone or 3 months after the last milestone
  const lastMilestoneDate = dates.length ? new Date(Math.max(...dates.map(d => d.getTime()))) : addMonths(today, 6);
  const timelineEnd = endOfMonth(addMonths(lastMilestoneDate, 3));
  
  // Calculate months for ticks
  const months = eachMonthOfInterval({ start: timelineStart, end: timelineEnd });
  
  // Calculate positions based on container width
  const totalDays = differenceInDays(timelineEnd, timelineStart);
  const usableWidth = containerWidth - 100; // Reduce padding to spread items more
  const dayWidth = usableWidth / totalDays;

  const getPositionFromDate = (date) => {
    const days = differenceInDays(date, timelineStart);
    return (days * dayWidth) + 50; // Adjust left padding to 50px
  };

  const getCategoryColor = (categoryId) => {
    const category = roadmapData.categories.find(c => c.id === categoryId);
    return {
      purple: 'bg-accent-purple',
      blue: 'bg-blue-500',
      green: 'bg-green-500'
    }[category?.color || 'purple'];
  };

  // Calculate if milestone text should be above or below
  const getTextPosition = (index, date) => {
    // Always alternate regardless of proximity
    return index % 2 === 0 ? 'bottom' : 'top';
  };

  return (
    <div className={`bg-background ${className}`} ref={containerRef}>
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <p className="text-sm font-semibold text-accent-purple mb-4">Roadmap</p>
          <h2 className="text-3xl md:text-4xl font-bold text-heading-text mb-6">
            What's Coming Next
          </h2>
          <p className="text-xl text-text">
            Our journey to build the fastest and most reliable key-value store
          </p>
        </div>

        {/* Category Legend */}
        <div className="flex flex-wrap justify-center items-center gap-4 mb-12">
          <button
            onClick={() => setSelectedCategory(null)}
            className={`flex items-center px-4 py-2 rounded-full border transition-colors duration-300
              ${!selectedCategory ? 'border-accent-purple bg-accent-purple/10' : 'border-gray-700 hover:border-accent-purple/50'}`}
          >
            <CalendarIcon className="w-5 h-5 mr-2 text-accent-purple" />
            <span className="text-text">All</span>
          </button>
          {roadmapData.categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`flex items-center px-4 py-2 rounded-full border transition-colors duration-300
                ${selectedCategory === category.id ? `border-${category.color}-500 bg-${category.color}-500/10` : 'border-gray-700 hover:border-accent-purple/50'}`}
            >
              <span className={`w-3 h-3 rounded-full ${getCategoryColor(category.id)} mr-2`} />
              <span className="text-text">{category.name}</span>
            </button>
          ))}
        </div>

        {/* Timeline Container */}
        <div className="relative mx-4">
          {/* Desktop Timeline */}
          <div className="hidden md:block relative overflow-hidden pb-16" style={{ height: '300px' }}>
            {/* Timeline Content */}
            <div className="relative h-full">
              <div className={`absolute inset-0 transition-all duration-300 ${selectedMilestone ? 'blur-sm' : ''}`}>
                {/* Timeline Base Line */}
                <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-800/50" />

                {/* Month Ticks */}
                {months.map((month, index) => (
                  <motion.div
                    key={month.getTime()}
                    className="absolute top-1/2 -translate-y-1/2"
                    style={{ left: getPositionFromDate(month) }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: index * 0.05 }}
                  >
                    <div className="h-3 w-0.5 bg-gray-700/50" />
                    <div className="absolute top-[calc(100%+16px)] -translate-x-1/2 whitespace-nowrap text-sm text-gray-500">
                      {format(month, 'MMM yyyy')}
                    </div>
                  </motion.div>
                ))}

                {/* Today Marker */}
                <motion.div
                  className="absolute top-1/2 -translate-y-1/2"
                  style={{ left: getPositionFromDate(today) }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <div className="absolute -translate-x-1/2 w-0.5 h-16 bg-red-500/70" />
                  <div className="absolute -translate-x-1/2 -translate-y-1/2 w-4 h-4 rounded-full bg-red-500 shadow-lg ring-4 ring-red-500/20" />
                  <div className="absolute -translate-x-1/2 whitespace-nowrap text-sm font-medium text-red-500"
                    style={{ 
                      top: '64px',
                      marginTop: '4px'
                    }}
                  >
                    Today
                  </div>
                </motion.div>

                {/* Milestones */}
                <AnimatePresence mode="sync">
                  {milestones.map((milestone, index) => {
                    const date = new Date(milestone.date);
                    const position = getTextPosition(index, date);
                    
                    return (
                      <motion.div
                        key={milestone.id}
                        className="absolute top-1/2 -translate-y-1/2"
                        style={{ left: getPositionFromDate(date) }}
                        initial={{ opacity: 0, y: position === 'top' ? -20 : 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: position === 'top' ? -20 : 20 }}
                        transition={{ delay: index * 0.1 }}
                      >
                        {/* Vertical Line */}
                        <div 
                          className={`absolute -translate-x-1/2 w-0.5
                            ${getCategoryColor(milestone.category).replace('bg-', 'bg-opacity-70 bg-')}`}
                          style={{ 
                            height: '64px',
                            top: position === 'top' ? '-64px' : '0px'
                          }}
                        />
                        
                        {/* Pin */}
                        <div className="absolute -translate-x-1/2 -translate-y-1/2">
                          {/* Ripple rings */}
                          <div 
                            className={`absolute w-6 h-6 rounded-full -translate-x-[16.67%] -translate-y-[16.67%]
                              ${getCategoryColor(milestone.category).replace('bg-', 'bg-opacity-40 bg-')}
                              animate-ripple-1`}
                          />
                          <div 
                            className={`absolute w-6 h-6 rounded-full -translate-x-[16.67%] -translate-y-[16.67%]
                              ${getCategoryColor(milestone.category).replace('bg-', 'bg-opacity-40 bg-')}
                              animate-ripple-2`}
                          />
                          <div 
                            className={`absolute w-6 h-6 rounded-full -translate-x-[16.67%] -translate-y-[16.67%]
                              ${getCategoryColor(milestone.category).replace('bg-', 'bg-opacity-40 bg-')}
                              animate-ripple-3`}
                          />
                          {/* Main circle */}
                          <div 
                            className={`relative w-4 h-4 rounded-full cursor-pointer
                              ${getCategoryColor(milestone.category)} shadow-lg
                              animate-soft-pulse`}
                            onClick={() => setSelectedMilestone(milestone)}
                          />
                        </div>

                        {/* Text Content */}
                        <motion.div
                          className={`absolute whitespace-nowrap`}
                          style={{ 
                            top: position === 'top' ? '-84px' : '64px',
                            left: '50%',
                            transform: 'translateX(-50%)'
                          }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: index * 0.1 + 0.2 }}
                        >
                          <div className="text-sm text-text text-center mx-auto">
                            {milestone.title}
                          </div>
                        </motion.div>
                      </motion.div>
                    );
                  })}
                </AnimatePresence>
              </div>
            </div>
          </div>

          {/* Mobile Timeline */}
          <div className="md:hidden relative py-8">
            <div className={`relative transition-all duration-300 ${selectedMilestone ? 'blur-sm' : ''}`}>
              {/* Vertical Timeline Line */}
              <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-gray-800/50" />

              {/* Mobile Milestones */}
              <div className="space-y-12">
                {milestones.map((milestone, index) => (
                  <div key={milestone.id} className="relative pl-12">
                    {/* Pin and Line */}
                    <div className="absolute left-4 -translate-x-1/2">
                      {/* Vertical connector line */}
                      <div 
                        className={`absolute left-1/2 -translate-x-1/2 w-0.5 h-12
                          ${getCategoryColor(milestone.category).replace('bg-', 'bg-opacity-70 bg-')}`}
                      />
                      
                      {/* Pin */}
                      <div className="relative">
                        {/* Ripple rings */}
                        <div 
                          className={`absolute w-6 h-6 rounded-full -translate-x-[16.67%] -translate-y-[16.67%]
                            ${getCategoryColor(milestone.category).replace('bg-', 'bg-opacity-40 bg-')}
                            animate-ripple-1`}
                        />
                        <div 
                          className={`absolute w-6 h-6 rounded-full -translate-x-[16.67%] -translate-y-[16.67%]
                            ${getCategoryColor(milestone.category).replace('bg-', 'bg-opacity-40 bg-')}
                            animate-ripple-2`}
                        />
                        <div 
                          className={`absolute w-6 h-6 rounded-full -translate-x-[16.67%] -translate-y-[16.67%]
                            ${getCategoryColor(milestone.category).replace('bg-', 'bg-opacity-40 bg-')}
                            animate-ripple-3`}
                        />
                        {/* Main circle */}
                        <div 
                          className={`relative w-4 h-4 rounded-full cursor-pointer
                            ${getCategoryColor(milestone.category)} shadow-lg
                            animate-soft-pulse`}
                          onClick={() => setSelectedMilestone(milestone)}
                        />
                      </div>
                    </div>

                    {/* Content */}
                    <div className="pt-1">
                      <div className="flex items-center gap-2 mb-1">
                        <span className={`w-2 h-2 rounded-full ${getCategoryColor(milestone.category)}`} />
                        <span className="text-xs text-text">
                          {roadmapData.categories.find(c => c.id === milestone.category)?.name}
                        </span>
                      </div>
                      <h3 className="text-base font-semibold text-heading-text mb-1">
                        {milestone.title}
                      </h3>
                      <div className="flex items-center text-xs text-text mb-2">
                        <CalendarIcon className="w-4 h-4 mr-1" />
                        {format(new Date(milestone.date), 'MMMM yyyy')}
                      </div>
                      <p className="text-sm text-text/80">
                        {milestone.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Selected Milestone Details Dialog (shared between mobile and desktop) */}
          <AnimatePresence mode="wait">
            {selectedMilestone && (
              <>
                {/* Overlay */}
                <div 
                  className="absolute inset-0 bg-background/50 backdrop-blur-sm z-10"
                  onClick={() => setSelectedMilestone(null)}
                />
                <motion.div
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  className="absolute top-[15%] left-1/3 -translate-x-1/2 -translate-y-1/2 z-20
                    w-[calc(100%-4rem)] max-w-lg bg-component-bg rounded-2xl p-6 
                    border border-gray-800 shadow-xl"
                  style={{
                    maxHeight: '250px',
                    overflow: 'auto'
                  }}
                >
                  <div className="flex items-start justify-between">
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center gap-2 mb-2">
                        <span className={`w-3 h-3 rounded-full ${getCategoryColor(selectedMilestone.category)}`} />
                        <span className="text-sm text-text">
                          {roadmapData.categories.find(c => c.id === selectedMilestone.category)?.name}
                        </span>
                      </div>
                      <h3 className="text-xl font-semibold text-heading-text mb-2 pr-6">
                        {selectedMilestone.title}
                      </h3>
                      <div className="flex items-center gap-4 text-sm text-text mb-4">
                        <div className="flex items-center gap-1">
                          <CalendarIcon className="w-4 h-4" />
                          {format(new Date(selectedMilestone.date), 'MMMM yyyy')}
                        </div>
                      </div>
                      <p className="text-text">
                        {selectedMilestone.description}
                      </p>
                    </div>
                    <button
                      onClick={() => setSelectedMilestone(null)}
                      className="text-text hover:text-white p-1 rounded-full hover:bg-gray-800/50 transition-colors flex-shrink-0"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Roadmap; 