import React from 'react';
import { Link } from 'react-router-dom';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import CodeBlock from './CodeBlock';
import { SparklesIcon } from '@heroicons/react/24/outline';

const GettingStarted = () => {
  // Create a modified theme without background color
  const customStyle = {
    ...vscDarkPlus,
    'pre[class*="language-"]': {
      ...vscDarkPlus['pre[class*="language-"]'],
      background: 'transparent',
      margin: 0
    },
    'code[class*="language-"]': {
      ...vscDarkPlus['code[class*="language-"]'],
      background: 'transparent'
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="sr-only">Getting Started with HPKV</h1>
      <h2 className="text-2xl font-bold mb-4">Getting Started with HPKV</h2>

      <h3 className="text-xl font-bold mt-6 mb-2">1. Generate an API Key</h3>
      <p>
        To start using HPKV, you'll need an API key. You can generate one in the API Keys section of the <Link to="/dashboard" className="text-primary hover:underline">Dashboard</Link>:
      </p>
      <ol className="list-decimal pl-6 space-y-2">
        <li>Go to the API Keys section</li>
        <li>Click "Generate API Key"</li>
        <li>Select your preferred region</li>
        <li>Add an optional description to help you identify this key later</li>
        <li>Save your API key securely - you won't be able to see it again</li>
      </ol>

      <h3 className="text-xl font-bold mt-6 mb-2">2. Choose Your API</h3>
      <p>
        HPKV offers three APIs for interacting with your data:
      </p>
      <ul className="list-disc pl-6 space-y-2">
        <li><strong>REST API:</strong> Simple HTTP-based interface, great for getting started and typical use cases</li>
        <li><strong>WebSocket API:</strong> High-performance interface, ideal for frequent operations</li>
        <li>
          <span className="inline-flex items-center mr-2 px-2 py-0.5 rounded bg-indigo-500/10 border border-indigo-500/20">
            <SparklesIcon className="h-3 w-3 text-indigo-400 mr-1" />
            <span className="text-xs text-indigo-400">Business+</span>
          </span>
          <strong>RIOC API:</strong> Ultra-low latency RPC protocol, perfect for performance-critical applications{' '}
        </li>
      </ul>

      <h3 className="text-xl font-bold mt-6 mb-2">3. Make Your First Request</h3>
      <p>Here's a simple example using the REST API to store and retrieve data:</p>

      <div className="mt-4">
        <p className="font-semibold mb-2">Store a value:</p>
        <div className="bg-component-bg rounded-lg p-4">
          <CodeBlock
            language="bash"
            style={customStyle}
            code={`curl -X POST https://YOUR_BASE_URL/record \\
  -H "Content-Type: application/json" \\
  -H "x-api-key: YOUR_API_KEY" \\
  -d '{"key": "greeting", "value": "Hello, HPKV!"}'`}
          />
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold mb-2">Retrieve the value:</p>
        <div className="bg-component-bg rounded-lg p-4">
          <CodeBlock
            language="bash"
            style={customStyle}
            code={`curl https://YOUR_BASE_URL/record/greeting \\
  -H "x-api-key: YOUR_API_KEY"`}
          />
        </div>
      </div>

      <h3 className="text-xl font-bold mt-6 mb-2">4. Using WebSocket API</h3>
      <p>
        For high-performance applications, you can use the WebSocket API. Here's a simple example using JavaScript:
      </p>
      <div className="bg-component-bg rounded-lg p-4">
        <CodeBlock
          language="javascript"
          style={customStyle}
          code={`const ws = new WebSocket('wss://YOUR_BASE_URL/ws?apiKey=YOUR_API_KEY');

ws.onopen = () => {
  // Store a value
  ws.send(JSON.stringify({
    op: 2,  // Insert operation
    key: 'greeting',
    value: 'Hello from WebSocket!',
    messageId: 1  // Optional
  }));

  // Retrieve a value
  ws.send(JSON.stringify({
    op: 1,  // Get operation
    key: 'greeting',
    messageId: 2  // Optional
  }));
};

ws.onmessage = (event) => {
  const response = JSON.parse(event.data);
  console.log('Received:', response);
};`}
        />
      </div>

      <h3 className="text-xl font-bold mt-6 mb-2">5. Next Steps</h3>
      <p>
        Now that you've made your first requests, you can explore the full capabilities of HPKV:
      </p>
      <ul className="list-disc pl-6">
        <li>Learn about all available endpoints in the <Link to="/docs/rest-api" className="text-primary hover:underline">REST API documentation</Link></li>
        <li>Explore the high-performance options in the <Link to="/docs/websocket-api" className="text-primary hover:underline">WebSocket API documentation</Link></li>
        <li>Check out our <Link to="/docs/sdk-guides" className="text-primary hover:underline">SDK Guides</Link> for language-specific integration examples</li>
        <li>Review our <Link to="/docs/best-practices" className="text-primary hover:underline">Best Practices</Link> to ensure you're using HPKV optimally</li>
      </ul>

      <div className="mt-6 border border-yellow-500/20 rounded-lg bg-yellow-500/5">
        <div className="px-4 py-3 border-b border-yellow-500/20 flex items-center gap-2">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500" />
          <h4 className="font-bold text-yellow-500">Important Notes</h4>
        </div>
        <ul className="list-disc pl-10 pr-4 py-3 text-yellow-200/90">
          <li>Keep your API key secure and never expose it in client-side code</li>
          <li>Each API key has its own rate limits and storage quotas</li>
          <li>Use HTTPS/WSS for all API calls to ensure data security</li>
          <li>Monitor your usage to stay within your plan's limits</li>
        </ul>
      </div>
    </div>
  );
};

export default GettingStarted;