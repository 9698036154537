import React from 'react';

const BestPractices = () => (
  <div className="space-y-8">
    <h1 className="sr-only">HPKV Best Practices</h1>
    <h2 className="text-2xl font-bold mb-4">Best Practices for Using HPKV</h2>
    
    <p>
      This guide provides recommendations to help you get the most out of HPKV.
    </p>
    
    <section>
      <h3 className="text-xl font-bold mt-6 mb-2">API Selection</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Choose the right API for your use case:</strong>
          <ul className="list-disc pl-6 mt-2">
            <li><strong>REST API:</strong> Best for simple, stateless operations and broad framework compatibility</li>
            <li><strong>WebSocket API:</strong> Ideal for high-throughput applications and minimizing latency</li>
            <li><strong>RIOC:</strong> Optimal for ultra-low latency requirements and when using official SDKs</li>
          </ul>
        </li>
        <li>
          <strong>Consider your performance requirements:</strong> If you need the lowest possible latency, use RIOC with our official SDKs. For standard web applications, REST API might be sufficient.
        </li>
      </ul>
    </section>

    <section>
      <h3 className="text-xl font-bold mt-6 mb-2">RIOC Best Practices</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Timestamp handling:</strong> While SDK timestamp methods provide accurate operation timing, local system time is acceptable for most use cases. Choose based on your consistency requirements.
        </li>
        <li>
          <strong>Connection management:</strong> Implement proper connection pooling and automatic reconnection logic with exponential backoff.
        </li>
        <li>
          <strong>Batch operations:</strong> Use batch operations when performing multiple related operations to reduce network overhead.
        </li>
        <li>
          <strong>TLS configuration:</strong> Keep TLS enabled unless you have a specific reason not to (e.g., Enterprise setups with alternative security measures).
        </li>
      </ul>
    </section>
    
    <section>
      <h3 className="text-xl font-bold mt-6 mb-2">Key Design</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Use meaningful and consistent key naming conventions:</strong> This helps in organizing and retrieving data efficiently. For example, use prefixes to group related keys: <code className="bg-component-bg text-text px-1 rounded">"user:1234:profile"</code>, <code className="bg-component-bg text-text px-1 rounded">"user:1234:preferences"</code>.
        </li>
        <li>
          <strong>Keep keys as short as possible while maintaining readability:</strong> Shorter keys consume less storage and are faster to process. However, don't sacrifice clarity for brevity.
        </li>
        <li>
          <strong>Consider using prefixes for different types of data:</strong> This creates a logical separation and can be useful for range queries if implemented in the future. For example: <code className="bg-component-bg text-text px-1 rounded">"product:"</code>, <code className="bg-component-bg text-text px-1 rounded">"order:"</code>, <code className="bg-component-bg text-text px-1 rounded">"user:"</code>.
        </li>
      </ul>
    </section>
    
    <section>
      <h3 className="text-xl font-bold mt-6 mb-2">Data Modeling</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Structure your data to minimize operations:</strong> Design your data model so that related data can be retrieved or updated in a single operation when possible.
        </li>
        <li>
          <strong>Use JSON or other structured formats for complex values:</strong> This allows you to store multiple fields in a single key-value pair, reducing the number of keys needed.
        </li>
        <li>
          <strong>Consider denormalization:</strong> Duplicate some data if it means you can retrieve all necessary information in a single operation. Balance this with storage costs.
        </li>
      </ul>
    </section>
    
    <section>
      <h3 className="text-xl font-bold mt-6 mb-2">Error Handling and Reliability</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Implement comprehensive error handling:</strong> Use the specific error types provided by each SDK (<code className="bg-component-bg text-text px-1 rounded">RiocError</code>, <code className="bg-component-bg text-text px-1 rounded">RiocTimeoutError</code>, etc.) for better error management.
        </li>
        <li>
          <strong>Use exponential backoff for retries:</strong> Start with a short delay and increase it exponentially with each retry attempt.
        </li>
        <li>
          <strong>Monitor operation latencies:</strong> Set appropriate timeout values based on your application's requirements and network conditions.
        </li>
      </ul>
    </section>
    
    <section>
      <h3 className="text-xl font-bold mt-6 mb-2">Security Best Practices</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Certificate management:</strong> Store TLS certificates securely and implement proper certificate rotation procedures.
        </li>
        <li>
          <strong>Use secure communication:</strong> Always use HTTPS for REST API, WSS for WebSocket API, and keep TLS enabled for RIOC unless specifically not required.
        </li>
        <li>
          <strong>API key security:</strong> Rotate API keys regularly and ensure they're stored securely. Never expose them in client-side code.
        </li>
      </ul>
    </section>
    
    <section>
      <h3 className="text-xl font-bold mt-6 mb-2">SDK Usage</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Use string methods when appropriate:</strong> For text data, use the provided string methods (e.g., <code className="bg-component-bg text-text px-1 rounded">insert_string</code>, <code className="bg-component-bg text-text px-1 rounded">get_string</code>) to handle encoding automatically.
        </li>
        <li>
          <strong>Keep SDKs updated:</strong> Regularly update to the latest versions to benefit from performance improvements and security updates.
        </li>
        <li>
          <strong>Utilize SDK-specific features:</strong> Take advantage of features like batch operations and connection pooling provided by the SDKs.
        </li>
      </ul>
    </section>
    
    <section>
      <h3 className="text-xl font-bold mt-6 mb-2">Performance Optimization</h3>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <strong>Choose appropriate operation modes:</strong> Use batch operations for multiple related operations, and consider using RIOC for ultra-low latency requirements.
        </li>
        <li>
          <strong>Monitor and optimize access patterns:</strong> Regularly analyze your usage patterns and adjust your data model or access strategies accordingly.
        </li>
      </ul>
    </section>
  </div>
);

export default BestPractices;