import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon, BoltIcon } from '@heroicons/react/24/outline';
import RiocApi from '../components/Docs/RiocApi';
import { Helmet } from 'react-helmet';

const RiocApiLanding = () => {
  return (
    <div className="min-h-screen bg-background">
      <Helmet>
        <title>HPKV RIOC API - Ultra-Low Latency Key-Value Store Interface</title>
        <meta name="description" content="Experience nanosecond-level latency with HPKV's RIOC (Remote IO Control) API. Purpose-built RPC protocol for maximum performance in business applications. Faster than Redis with ultra-low latency connections." />
        <meta name="keywords" content="HPKV RIOC API, ultra-low latency database, high performance key-value store, rpc api, low latency connection, nanosecond performance, redis alternative rpc, fastest key-value store, low latency network, rpc protocol" />
        <link rel="canonical" href="https://hpkv.io/rioc-api" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hpkv.io/rioc-api" />
        <meta property="og:title" content="HPKV RIOC API - Ultra-Low Latency Key-Value Store Interface" />
        <meta property="og:description" content="Experience nanosecond-level latency with HPKV's RIOC (Remote IO Control) API. Purpose-built RPC protocol for maximum performance in business applications." />
        <meta property="og:image" content="https://hpkv.io/images/hpkv-illustration-no-subtext.png" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hpkvHQ" />
        <meta name="twitter:title" content="HPKV RIOC API - Ultra-Low Latency Key-Value Store Interface" />
        <meta name="twitter:description" content="Experience nanosecond-level latency with HPKV's RIOC API. Purpose-built RPC protocol for maximum performance in business applications." />
        <meta name="twitter:image" content="https://hpkv.io/images/hpkv-illustration-no-subtext.png" />
      </Helmet>

      {/* Hero Section */}
      <div className="bg-gradient-to-b from-primary/10 to-background pt-24 pb-12">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-heading-text mb-6">
              HPKV RIOC API
            </h1>
            <p className="text-xl text-text-secondary mb-8">
              Ultra-low latency RPC protocol delivering nanosecond-level performance
            </p>
            <div className="flex justify-center gap-4">
              <Link 
                to="/signup" 
                className="inline-flex items-center px-6 py-3 rounded-lg bg-primary text-white hover:bg-primary/90 transition-colors"
              >
                Get Started
                <ArrowRightIcon className="w-5 h-5 ml-2" />
              </Link>
              <Link 
                to="/docs/rioc-api" 
                className="inline-flex items-center px-6 py-3 rounded-lg border border-primary/20 hover:bg-primary/10 transition-colors"
              >
                View Documentation
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Performance Stats */}
      <div className="py-12">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8 text-center">
            <div>
              <div className="text-3xl font-bold text-primary mb-2">~15µs</div>
              <div className="text-text-secondary">Network Latency</div>
            </div>
            <div>
              <div className="text-3xl font-bold text-primary mb-2">~300ns</div>
              <div className="text-text-secondary">Local Vectored Call</div>
            </div>
            <div>
              <div className="text-3xl font-bold text-primary mb-2">TLS 1.3</div>
              <div className="text-text-secondary">Security Protocol</div>
            </div>
          </div>
        </div>
      </div>

      {/* Key Features */}
      <div className="py-12 bg-component-bg/50">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="p-6 rounded-lg bg-component-bg">
              <h3 className="text-xl font-bold mb-3">Ultra-Low Latency</h3>
              <p>Purpose-built RPC protocol optimized for minimal latency and maximum throughput.</p>
            </div>
            <div className="p-6 rounded-lg bg-component-bg">
              <h3 className="text-xl font-bold mb-3">Enterprise Ready</h3>
              <p>Advanced features including connection pooling, batch operations, and automatic reconnection.</p>
            </div>
            <div className="p-6 rounded-lg bg-component-bg">
              <h3 className="text-xl font-bold mb-3">Native SDKs</h3>
              <p>Official SDK support for Node.js, Python, and .NET with type-safe interfaces.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Enterprise Feature Highlight */}
      <div className="py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto bg-indigo-400/5 rounded-lg p-8 border border-indigo-400/20">
            <div className="flex items-center gap-3 mb-4">
              <BoltIcon className="h-8 w-8 text-indigo-400" />
              <h2 className="text-2xl font-bold text-indigo-400">Enterprise Local Vectored Call</h2>
            </div>
            <p className="text-lg mb-6">
              Enterprise customers can achieve unprecedented ~300ns latency with our local vectored call interface, 
              bypassing network communication entirely for applications running on the same machine as HPKV.
            </p>
            <Link 
              to="/pricing" 
              className="inline-flex items-center px-6 py-3 rounded-lg bg-indigo-500 text-white hover:bg-indigo-600 transition-colors"
            >
              Learn About Enterprise Features
              <ArrowRightIcon className="w-5 h-5 ml-2" />
            </Link>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <RiocApi />
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-primary/5 py-12">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Experience Nanosecond-Level Performance</h2>
          <p className="text-lg mb-8">Upgrade to Business or Enterprise plan to access RIOC API capabilities.</p>
          <Link 
            to="/signup" 
            className="inline-flex items-center px-8 py-4 rounded-lg bg-primary text-white hover:bg-primary/90 transition-colors text-lg"
          >
            Upgrade Now
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RiocApiLanding; 